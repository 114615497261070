import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ErrorHandler, Injectable, NgModule } from '@angular/core';

import { PubNubAngular } from 'pubnub-angular2';

import { AppComponent } from './app.component';

import { AdvertisementService } from './services/advertisement.service';
import { ApiReservationService } from './services/api.reservation.service';
import { ApiService } from './services/api.service';
import { GroupService } from './services/group.service';
import { MerchantService } from './services/merchant.service';
import { OrderService } from './services/order.service';
import { PrintService } from './services/print.service';
import { ProductService } from './services/product.service';
import { PubnubService } from './services/pubnub.service';
import { ReservationService } from './services/reservation.service';
import { TaxonService } from './services/taxon.service';
import { TaxTariffService } from './services/tax_tariff.service';
import { UserService } from './services/user.service';
import { UtilityService } from './services/utility.service';

import { AuthComponent } from './auth/auth.component';
import { GroupEditComponent } from './profile/group-edit/group-edit.component';
import { GroupComponent } from './profile/group/group.component';
import { PizzaPartyComponent, ProfileComponent } from './profile/profile.component';
import { RegistrationComponent } from './registration/registration.component';

import { SharedModule } from './shared/shared.module';

import { AppRoutingModule } from './app-routing.module';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { WebpackTranslateLoader } from './webpack-translate-loader';


import { NgxSpinnerModule } from 'ngx-spinner';

import { MenuComponent } from './menu/menu.component';
import { MembersComponent } from './profile/members/members.component';
import { ReservationComponent } from './reservation/reservation.component';
import { VenueHeaderComponent } from './venue-header/venue-header.component';

import { AboutUsComponent } from './about-us/about-us.component';
import { ApplyComponent } from './apply/apply.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { ContactComponent } from './contact/contact.component';
import { GroupInvitationsComponent } from './group-invitations/group-invitations.component';
import { GroupOrderComponent } from './group-order/group-order.component';
import { MainComponent } from './main/main.component';
import { MyOrdersComponent } from './my-orders/my-orders.component';
import { NavigationComponent } from './navigation/navigation.component';
import { ConfirmationComponent } from './order/confirmation/confirmation.component';
import { ConfirmationDialogComponent } from './shared/confirmation-dialog/confirmation-dialog.component';

import { CurrencyPipe } from '@angular/common';
import { EnabledFilterPipe } from './pipes/enabled-filter.pipe';
import { SafeHtml } from './pipes/safe-html.pipe';
import { TagFilterPipe } from './pipes/tag-filter.pipe';

import { MerchantPromotionComponent } from './merchant-promotion/merchant-promotion.component';
import { OrderStatusComponent } from './order-status/order-status.component';
import { VenuesComponent } from './venues/venues.component';

import { AuthGuestComponent } from './auth-guest/auth-guest.component';
import { BasketComponent } from './basket/basket.component';
import { AuthCovid19Component } from './covid19/covid19.component';
import { PaymentMethodSheetComponent } from './payment-method/payment-method-sheet/payment-method-sheet.component';
import { QrScanComponent } from './qr-scan/qr-scan.component';
import { ScenarioSheetComponent } from './sheets/scenario-sheet/scenario-sheet.component';
import { DateSheetComponent } from './timing/date-sheet/date-sheet.component';
import { TimeSheetComponent } from './timing/time-sheet/time-sheet.component';
import { TimingSheetComponent } from './timing/timing-sheet/timing-sheet.component';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { SuggestionsComponent } from './suggestions/suggestions.component';
import { VoucherComponent } from './voucher/voucher.component';

import { AppHelper } from './helpers/app-helper';
import { FeaturesHelper } from './helpers/features-helper';

import { CategoriesComponent } from './categories/categories.component';
import { AdvisoriesComponent } from './components/advisories/advisories.component';
import { AuthenticationComponent } from './components/authentication/authentication.component';
import { AutocompleteComponent } from './components/autocomplete-component/autocomplete-component.component';
import { ClosedComponent } from './components/closed/closed.component';
import { DataRequestComponent } from './components/data-request/data-request.component';
import { FindMerchantComponent } from './find-merchant/find-merchant.component';
import { MyGroupsComponent } from './my-groups/my-groups.component';
import { OrderTypeComponent } from './order-type/order-type.component';
import { PaymentComponent } from './payment/payment.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { GroupSheetComponent } from './sheets/group-sheet/group-sheet.component';
import { OrderTypeSheetComponent } from './sheets/order-type-sheet/order-type-sheet.component';

import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';

import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CateringLocationSheetComponent } from './sheets/catering-location-sheet/catering-location-sheet.component';
import { LocalLocationSheetComponent } from './sheets/local-location-sheet/local-location-sheet.component';

import * as Sentry from '@sentry/browser';
import { LevelOfActivityComponent } from './components/level-of-activity/level-of-activity.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';

import { BancontactComponent } from './bancontact/bancontact.component';
import { CCVComponent } from './ccv/ccv.component';
import { PayconiqComponent } from './payconiq/payconiq.component';
import { WorldlineComponent } from './worldline/worldline.component';

import { CheckoutKioskComponent } from './checkout-kiosk/checkout-kiosk.component';
import { SettingsComponent } from './settings/settings.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppearDirective } from './directives/appear.directive';
import { DisableControlDirective } from './directives/disable-control.directive';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';

import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import localeNl from '@angular/common/locales/nl';
import { NotificationComponent } from './notification/notification.component';

import { ServiceWorkerModule } from '@angular/service-worker';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { environment } from '../environments/environment';
import { AdvertisementsComponent } from './components/advertisements/advertisements.component';
import { InputLoyaltyCardComponent } from './input-loyalty-card/input-loyalty-card.component';
import { KioskTimeoutDialogComponent } from './kiosk-timeout-dialog/kiosk-timeout-dialog.component';
import { CategoryDialogComponent } from './category-dialog/category-dialog.component';
import { LoyaltyCardDetailsComponent } from './loyalty-card-details/loyalty-card-details.component';
import { MyLoyaltyPointsComponent } from './my-loyalty-points/my-loyalty-points.component';
import { NavigatorComponent } from './navigator/navigator.component';
import { VenueFiltersComponent } from './venue-filters/venue-filters.component';

import { LanguageSelectionComponent } from './components/language-selection/language-selection.component';
import { AboutFolComponent } from './about-fol/about-fol.component';
import { ContactUsDemoComponent } from './contact-us/contact-us-demo.component';
import { ContactUsSupportComponent } from './contact-us/contact-us-support.component';
import { FolFooterComponent } from './components/fol-footer/fol-footer.component';

registerLocaleData(localeFr);
registerLocaleData(localeNl);
registerLocaleData(localeEs);
registerLocaleData(localeDe);
registerLocaleData(localeIt);

Sentry.init({
  dsn: 'https://0d8c11924ef34d02995d06a6df7f1362@sentry.io/1796184'
});

const cookieConfig:NgcCookieConsentConfig = {
  "cookie": {
    "domain": "one2three.app"
  },
  "position": "bottom-left",
  "theme": "classic",
  "palette": {
    "popup": {
      "background": "#000000",
      "text": "#ffffff"
    },
    "button": {
      "background": "#ad151c",
      "text": "#ffffff"
    }
  },
  "type": "info",
  "content": {
    "message": "This website uses cookies to ensure you get the best experience on our website.",
    "dismiss": "Got it!",
    "deny": "Refuse cookies",
    "link": "Learn more",
    "href": "https://cookiesandyou.com",
    "policy": "Cookie Policy"
  }
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@Injectable()
export class SentryAndChunkErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error.message)) { 
        window.location.reload(); 
        Sentry.captureException(new Error('App needed to reload as chunk failed.'));
    } else {
       const eventId = Sentry.captureException(error.originalError || error);
       console.log(error);
    }
    //Sentry.showReportDialog({ eventId });
  }
}

@NgModule({
  declarations: [
    AppComponent,
    ProfileComponent,
    PizzaPartyComponent,
    GroupComponent,
    AuthComponent,
    RegistrationComponent,
    MenuComponent,
    ReservationComponent,
    VenueHeaderComponent,
    MembersComponent,
    MainComponent,
    AboutUsComponent,
    MyOrdersComponent,
    ApplyComponent,
    CheckoutComponent,
    GroupOrderComponent,
    ContactComponent,
    NavigationComponent,
    GroupInvitationsComponent,
    ConfirmationDialogComponent,
    ConfirmationComponent,
    EnabledFilterPipe,
    SafeHtml,
    TagFilterPipe,
    VenuesComponent,
    MerchantPromotionComponent,
    OrderStatusComponent,
    QrScanComponent,
    DateSheetComponent,
    PaymentMethodSheetComponent,
    ScenarioSheetComponent,
    TimingSheetComponent,
    TimeSheetComponent,
    AuthGuestComponent,
    AuthCovid19Component,
    BasketComponent,
    VoucherComponent,
    SuggestionsComponent,
    OrderDetailsComponent,
    AutocompleteComponent,
    AuthenticationComponent,
    DataRequestComponent,
    CategoriesComponent,
    MyGroupsComponent,
    PrivacyPolicyComponent,
    OrderTypeComponent,
    OrderTypeSheetComponent,
    GroupSheetComponent,
    AdvisoriesComponent,
    ClosedComponent,
    PaymentComponent,
    FindMerchantComponent,
    CateringLocationSheetComponent,
    LocalLocationSheetComponent,
    TermsAndConditionsComponent,
    LevelOfActivityComponent,
    BancontactComponent,
    PayconiqComponent,
    WorldlineComponent,
    CCVComponent,
    CheckoutKioskComponent,
    SettingsComponent,
    GroupEditComponent,
    DisableControlDirective,
    AppearDirective,
    ForgotPasswordComponent,
    NotificationComponent,
    MyLoyaltyPointsComponent,
    AdvertisementsComponent,
    InputLoyaltyCardComponent,
    VenueFiltersComponent,
    LoyaltyCardDetailsComponent,
    NavigatorComponent,
    KioskTimeoutDialogComponent,
    CategoryDialogComponent,
    AboutFolComponent,
    ContactUsDemoComponent,
    ContactUsSupportComponent,
    FolFooterComponent
  ],
  imports: [
    NgbModule,
    SharedModule,
    ScrollingModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgxSpinnerModule,
    NgxIntlTelInputModule,
    TranslateModule.forRoot({
        loader: {
                provide: TranslateLoader,
                useClass: WebpackTranslateLoader
                //useFactory: HttpLoaderFactory,
                //deps: [HttpClient]
        }
    }),
    HttpClientModule,
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
    CarouselModule.forRoot(),
    FontAwesomeModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    NgcCookieConsentModule.forRoot(cookieConfig)
  ],
  providers: [
    PubNubAngular,
 	  ApiService,
    ApiReservationService,
    MerchantService,
    AdvertisementService,
    PubnubService,
    OrderService,
    PrintService,
    ProductService,
    ReservationService, 
    UserService,
    UtilityService,
    GroupService,
    TaxonService,
    TaxTariffService,
    AppHelper,
    FeaturesHelper,
    CurrencyPipe,
    { provide: ErrorHandler, useClass: SentryAndChunkErrorHandler }
],
  bootstrap: [AppComponent]
})
export class AppModule { }
