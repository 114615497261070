import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ApiService } from '../services/api.service';
import { UserService } from '../services/user.service';
import { TranslateService } from '@ngx-translate/core';

import { Merchant } from '../models/merchant';

import { environment } from '../../environments/environment';

declare var google;

@Injectable()
export class MerchantService {

	public merchant: Merchant;
  public singleMerchantWhitelabel = false;
  
  public kiosk = null;
  public pos = false;
  public kitchen = false;

	private merchants: { [key: number]: Merchant } = {};

  public menuFiltered = new EventEmitter();

  public kioskTimeLeft = 0;

	constructor(
		private apiService: ApiService,
		private userService: UserService,
    private translate: TranslateService,
    private http: HttpClient
	) {}

  getAllMerchants() {

    const access_token = null;
    const url = 'merchants/all';

    console.log('url=>');
    console.log(url);

    return this.apiService.get('api/v2/' + url, this.userService.access_token);

  }

  getEnabledScenarios() {
    let all_scenarios = ['all', 'takeaway', 'delivery', 'eatin', 'catering', 'roomservice', 'table', 'terminal', 'waiter'];
    let scenarios = [];

    for (let scenario of all_scenarios) {
      if (this.hasFeature(this.merchant.id, 'f_scenario_' + scenario) || scenario == 'all') {
        scenarios.push(scenario);
      }
    }
    return scenarios
  }

  getSignageDevice(device_id) {
    const access_token = null;
    const url = 'signage_devices/' + device_id;
    return this.apiService.get('api/v1/' + url, this.userService.access_token);

  }

  getHolidayPeriods(merchant_id) {
    let query = 'merchant_id=' + merchant_id;
    return this.apiService.get('api/v1/order_constraints/holiday_periods?' + query, this.userService.access_token);
  }

  getKiosks(merchant_id: string) {
    let url = 'kiosks?&q[merchant_id_eq]=' + merchant_id;
    return this.apiService.get('api/v1/' + url, this.userService.access_token);
  }

  getKiosksByDomain(merchant_slug: string) {
    let url = 'kiosks?&q[merchant_domain_start]=' + merchant_slug;
    return this.apiService.get('api/v1/' + url, this.userService.access_token);
  }

  createKiosk(kiosk) {
    let params = { kiosk: kiosk }
    return this.apiService.post('api/v1/kiosks', params, this.userService.access_token);
  }

  updateKiosk(kiosk) {
    let params = { kiosk: kiosk }
    return this.apiService.put('api/v1/kiosks/' + kiosk.id + ".json", params, this.userService.access_token);
  }

  deleteKiosk(kiosk) {
    return this.apiService.delete('api/v1/kiosks/' + kiosk.id, this.userService.access_token);
  }

	getMerchants(page: number, community?: string, search?: string, tags?: Array<string>, filters?: Array<string>) {
      //dist_timing=timed&dist_timing_target=2019-08-20T15:00:00.000Z
  		let url = 'merchants?&page=' + page;
      if (!community) { url += '&tags[]=' + environment.tag; }
  		if (community) { url+= '&tags[]=' + community; }
      if (tags) { tags.forEach(tag => { url += '&tags[]=' + tag; }); }
      if (this.userService.scenario && this.userService.scenario != 'all') { url += '&scenario=' + this.userService.scenario; }
      if (search) { url += '&search=' + search; }
      if (filters) { filters.forEach(filter => { url += '&filters[]=' + filter; }); }
  		
      if (this.userService.address && this.userService.address.latitude && this.userService.address.longitude) {
        url += '&latitude=' + this.userService.address.latitude;
        url += '&longitude=' + this.userService.address.longitude;
        url += '&distance=250000000000000';
      }
      else if (this.userService.geoAddress && this.userService.geoAddress.latitude && this.userService.geoAddress.longitude) {
  			url += '&latitude=' + this.userService.geoAddress.latitude;
  			url += '&longitude=' + this.userService.geoAddress.longitude;
        url += '&distance=250000000000000';
  		}
      
    	return this.apiService.get('api/v2/' + url, this.userService.access_token);
  	}

  getLocalLocations(): Observable<any> {
    return this.apiService.get('api/v1/local_locations?all_page=true&q[merchant_id_eq]=' + this.merchant.id, this.userService.access_token);
  }

  closeDay(local_location_id): Observable<any> {
    return this.apiService.get(`api/v1/local_locations/${local_location_id}/close_day`, this.userService.access_token);
  }

  closeReport(report_id): Observable<any> {
    return this.apiService.get(`api/v1/reports/${report_id}/close`, this.userService.access_token);
  }

  getLocalLocationsByIds(ids): Observable<any> {
    let query = ''
    ids.forEach((id, index) => {
      query += 'q[id_in][]=' + id;
      if (index < ids.length - 1) { query += '&'; }
    });

    console.log('the query')
    console.log(query)
      
    return this.apiService.get('api/v1/local_locations?page=1&' + query, this.userService.access_token);
  }

  reloadMerchant(): Observable<any> {
    return this.apiService.get('api/v1/merchants/by_domain/' + this.merchant.domain, this.userService.access_token);
  }

  valid_delivery_address(merchant_id, address): Observable<any> {
    return this.apiService.post('api/v2/merchants/' + merchant_id + '/valid_delivery_address', address, this.userService.access_token);
  }


  getMerchantById(merchant_id: string): Observable<any> {
    return this.apiService.get('api/v1/merchants/' + merchant_id,  this.userService.access_token);
  }

  getPrinters(merchant_id: number): Observable<any> {
    return this.apiService.get('api/v1/merchants/' + merchant_id + '/printers',  this.userService.access_token);
  }

  getDistributors(merchant_id: number): Observable<any> {
    return this.apiService.get('api/v1/merchants/' + merchant_id + '/distributors',  this.userService.access_token);
  }

  getCustomers(page, sort_by, filters) {
    return this.apiService.get('api/v1/users?&q[orders_merchant_id_eq]=' + this.merchant.id + '&category=customers&q[s]=' + sort_by + '&page=' + page + filters, this.userService.access_token)
  }

  getCustomersCSV() {
    let headers: HttpHeaders = new HttpHeaders()
    .set('Content-Type', 'text/csv');
    if (this.userService.access_token) { headers = headers.append('Authorization', 'Bearer ' + this.userService.access_token); }
    let path = environment.base_api_url + `api/v1/users/customers.csv?&q[orders_merchant_id_eq]=${this.merchant.id}&q[s]=username+asc`

    return this.http.get(path, {responseType: 'text', headers: headers})
  }

  getRegistryProducts(merchant_id: number): Observable<any> {
    return this.apiService.get('api/v1/merchants/' + merchant_id + '/registry_products',  this.userService.access_token);
  }

  getRegistryPaymentMethods(merchant_id: number): Observable<any> {
    return this.apiService.get('api/v1/merchants/' + merchant_id + '/registry_payment_methods',  this.userService.access_token);
  }

  getRegistryTables(merchant_id: number): Observable<any> {
    return this.apiService.get('api/v1/merchants/' + merchant_id + '/registry_tables',  this.userService.access_token);
  }

  getMerchantByDomain(domain: string): Observable<any> {
    return this.apiService.get('api/v1/merchants/by_domain/' + domain.replace('/', '%2F'), this.userService.access_token);
  }

  getLocalLocationByQRCode(qr_token) {
     return this.apiService.get('api/v1/local_locations/by_qr_token/' + qr_token, this.userService.access_token); 
  }

  getMerchantDomain(qr_code) {
    //merchants/merchant_domain?qr_token
    console.log('we are requesting');
    console.log('api/v1/merchants/merchant_domain?qr_token=' + qr_code);
    return this.apiService.get('api/v1/merchants/merchant_domain?qr_token=' + qr_code, this.userService.access_token);
  }

  favorite(status, merchant_id) {
    let action = 'favorite';
    if (!status) { action = 'unfavorite'; }
    return this.apiService.put('api/v1/merchants/' + merchant_id + '/' + action, '{}', this.userService.access_token);
  }

	getPrimaryMerchantColor(merchant) {
    	let mpc = null;
    	if (merchant && merchant.style_overrides && merchant.style_overrides[0].mobile_primary_color) { mpc = merchant.style_overrides[0].mobile_primary_color; }
    	return mpc;
  	}

  	getMerchantStats() {
    	const access_token = null;
    	return this.apiService.get('api/v1/merchant_stats', access_token);
  	}

  	getStoredMerchant(id) {
    	const merchant = this.merchants[id];
    	if (merchant) { this.calculateActualState(merchant); }
  		return merchant;
  	}

  	getStoredMerchants() {
    	return this.merchants;
  	}

    listMerchants() {
       return Object.values(this.merchants);
    }

  	merchantsSortedByOrders() {
    	const merchants = Object.values(this.merchants);
      const to_be_sorted = [];
      for (const merchant of merchants) {
        if (!merchant.daily_order_count) {
        } else { to_be_sorted.push(merchant); }
      }

    	const sorted_merchants = to_be_sorted.sort((a, b) => b.daily_order_count - a.daily_order_count);
  	  //console.log(sorted_merchants);
      return sorted_merchants;
    }

  	dailyOrderCount() {
    	let order_count = 0;
    	const merchants = Object.values(this.merchants);
      for (const merchant of merchants) {
      	if (merchant.daily_order_count) { order_count += merchant.daily_order_count; }
    	}
    	return order_count;
  	}

    getDailyRevenue() {
      let daily_revenue = 0;
      const merchants = Object.values(this.merchants);
      for (const merchant of merchants) {
        if (merchant.daily_revenue) { daily_revenue += merchant.daily_revenue; }
      }
      return daily_revenue;
    }

    getDailyRevenueWeekAgo() {
      let daily_revenue_week_ago = 0;
      const merchants = Object.values(this.merchants);
      for (const merchant of merchants) {
        if (merchant.daily_revenue_week_ago) { daily_revenue_week_ago += merchant.daily_revenue_week_ago; }
      }
      return daily_revenue_week_ago;
    }

  	dailyOrderCountWeekAgo() {
    	let order_count = 0;
    	const merchants = Object.values(this.merchants);
    	for (const merchant of merchants) {
      		if (merchant.daily_order_count_week_ago) { order_count += merchant.daily_order_count_week_ago; }
    	}
    	return order_count;
 	}

	updateStoredMerchants(merchants) {
    	for (let merchant of merchants) {
      		if (this.merchants[merchant.id])
        		for (let key in merchant) {
          			this.merchants[merchant.id][key] = merchant[key];
      			} else {
       	 			this.merchants[merchant.id] = merchant;
      			}
      			this.calculateActualState(merchant);
    	}
    	if (this.merchant) this.merchant = this.merchants[this.merchant.id];
  	}

  	updateMerchant(merchant) {

      merchant.primary_color = this.getPrimaryMerchantColor(merchant);

      if (this.merchants[merchant.id]) {

        for (const key in merchant) {
          this.merchants[merchant.id][key] = merchant[key];
      	}
      } else {
        	this.merchants[merchant.id] = merchant;
      }
      
      this.calculateActualState(this.merchants[merchant.id]);
      return this.merchants[merchant.id];

  	}

    updateMerchantInBackend(merchant) {
      let payload = {
        merchant: merchant
      }
      
      return this.apiService.put('api/v1/merchants/' + merchant.id, payload, this.userService.access_token);
    }

    updateMerchantVenueImage(merchant_id, payload) {
      let api_url: string = environment.base_api_url;
      let headers: HttpHeaders = new HttpHeaders()
        .set('Authorization', 'Bearer ' + this.userService.access_token)
      let apiEndPoint = api_url + 'api/v1/merchants/' + merchant_id + '/image?type=main'

      return this.http.post(`${apiEndPoint}`, payload, {headers: headers})
    }

  	calculateActualState(merchant) {

      merchant.state_info = {};

      if (merchant.current_week_scheme) {

      const date = new Date();

      let day = date.getDay();
      if (day == 0) { day = 6; }
      else { day -= 1; }

      let currentTime = day * 24 * 60 * 60;
      currentTime += date.getHours() * 60 * 60;
      currentTime += date.getMinutes() * 60;
      currentTime += date.getSeconds();

      merchant.state_info.is_open_calc = false;

      for (const slot of merchant.current_week_scheme.slots) {
        if (currentTime < slot.start_at_s) {
            merchant.state_info.opens_in_calc = slot.start_at_s - currentTime;
            merchant.state_info.opens_at_calc = slot.start_at_s - day * 24 * 60 * 60;
            break;
        } else if (currentTime >= slot.start_at_s && currentTime < slot.start_at_s + slot.duration) {
            merchant.state_info.is_open_calc = true;
            merchant.state_info.closes_in_calc = slot.start_at_s + slot.duration - currentTime;
            merchant.state_info.closes_at_calc = slot.start_at_s + slot.duration - day * 24 * 60 * 60;
            break;
        }
      }

      const timeLeftToday = (24 * 60 * 60) - date.getHours() * 60 * 60;
      if (merchant.state_info.opens_in_calc && merchant.state_info.opens_in_calc < timeLeftToday) {
        merchant.state_info.will_open_today = true;
      } else if (merchant.state_info.opens_in_calc) {
        merchant.state_info.will_open_today = false;
      }

      if (merchant.tags.includes('setup')) { merchant.status_formatted = this.translate.instant('coming_soon'); }
      else if (merchant.settings.s_closed) { merchant.status_formatted = this.translate.instant('exceptionally_closed'); }
      else if (merchant.state_info.is_open_calc) {
        const close_hour = Math.floor(merchant.state_info.closes_at_calc / 60 / 60);
        const close_mins = Math.round(((merchant.state_info.closes_at_calc / 60 / 60) - close_hour) * 60);
        //merchant.status_formatted = 'sluit om ' + close_hour + 'u';
        merchant.status_formatted = this.translate.instant('closes_at', {hour: close_hour });
        if (close_mins > 0) { merchant.status_formatted += close_mins; }
      } else if (merchant.state_info.will_open_today) {
        const open_hour = Math.floor(merchant.state_info.opens_at_calc / 60 / 60);
        const open_mins = Math.round(((merchant.state_info.opens_at_calc / 60 / 60) - open_hour) * 60);
        merchant.status_formatted = this.translate.instant('opens_at', {hour: open_hour });
        if (open_mins > 0) { merchant.status_formatted += open_mins; }
      } else {
        merchant.status_formatted = this.translate.instant('closed');
      }
      }
    }

    stillOpenInAnHour(merchant) {
      if (merchant.state_info.closes_in_calc && merchant.state_info.closes_in_calc > 3600) { return true; }
      return false;
    }

    calculateDistance(merchant) {

      if (!merchant || !merchant.address || !merchant.address.latitude || !merchant.address.longitude) { return null; }
      if (!this.userService.geoAddress && !this.userService.address) { return null; }

      var lat2 = merchant.address.latitude;
      var lng2 = merchant.address.longitude;
      
      var lat1;
      var lng1;

      if (this.userService.address && this.userService.address.latitude && this.userService.address.longitude) {
        lat1 = this.userService.address.latitude;
        lng1 = this.userService.address.longitude;
      } else if (this.userService.geoAddress && this.userService.geoAddress.latitude && this.userService.geoAddress.longitude) {
        lat1 = this.userService.geoAddress.latitude;
        lng1 = this.userService.geoAddress.longitude;
      } 

      /*var service = new google.maps.DistanceMatrixService;
      var currentdate = new Date();
      var datetime = currentdate.getDate() + "/"
        + (currentdate.getMonth()+1)  + "/"
        + currentdate.getFullYear() + " "
        + currentdate.getHours() + ":"
        + currentdate.getMinutes() + ":"
        + currentdate.getSeconds();

      service.getDistanceMatrix({
          origins: [origin],
          destinations: [destination],
          travelMode: 'DRIVING',
          drivingOptions: {
            departureTime: currentdate,
            trafficModel: 'bestguess'
          },
          unitSystem: google.maps.UnitSystem.METRIC,
          avoidHighways: false,
          avoidTolls: false
        }, (response, status) => {
          if (status !== 'OK') {
            alert('Error was: ' + status);
          } else {
            var originList = response.originAddresses;
            var destinationList = response.destinationAddresses;

            //this.info = 'INFO: ';

            for (var i = 0; i < originList.length; i++) {
              var results = response.rows[i].elements;
              for (var j = 0; j < results.length; j++) {
                let distance = results[j].distance;
                let duration = results[j].duration;
                let duration_in_traffic = results[j].duration_in_traffic;
                console.log(results[j]);
              }
            }

          }
        });*/

        const p = 0.017453292519943295;    // Math.PI / 180
        const c = Math.cos;
        const a = 0.5 - c((lat1 - lat2) * p) / 2 + c(lat2 * p) * c((lat1) * p) * (1 - c(((lng1 - lng2) * p))) / 2;
        const dis = (12742 * Math.asin(Math.sqrt(a))); // 2 * R; R = 6371 km

        merchant.distance_to_user = dis;

    }

  	createContact(merchantId, contact) {
    	return this.apiService.post('api/v2/merchants/' + merchantId + '/merchant_emails', contact, this.userService.access_token);
  	}

    hasFeature(merchant_id, feature) {
        if (this.merchants && this.merchants[merchant_id] && this.merchants[merchant_id].features.indexOf(feature) != -1) { return true; }
        else { return false; }
    }

    getMonday(d) {
      d = new Date(d);
      let day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
      return new Date(d.setDate(diff));
    }

    setCategoryStatus(category, order_constraints) {
      
      
      category.is_enabled = true;
      for (let order_constraint of order_constraints) {

          if (  order_constraint.category.includes(category.id.toString()) &&
                 (  (order_constraint.local_locations.length == 0) || 
                    (this.userService.basket && order_constraint.local_locations.includes(String(this.userService.basket.order.dist_location_id)))
                 )
             ) {
               
               let target_time = null;
               if (this.userService.basket && this.userService.basket.order) target_time = new Date(this.userService.basket.order.dist_timing_target);
               

               if ( !order_constraint.current_week_scheme && 
                   (!order_constraint.dates || !order_constraint.dates.startDate || !order_constraint.dates.endDate)) {
                 category.is_enabled = false;
               } else if (order_constraint.current_week_scheme) {

                 let compare = this.getMonday(new Date());

                 //Quickfix
                 compare.setHours(0);
                 compare.setMinutes(0);
                 compare.setSeconds(0);

                  for (let slot of order_constraint.current_week_scheme.slots) {
                    let start = compare.getTime() + slot.start_at_s*1000;
                    let end = compare.getTime() + (slot.start_at_s + slot.duration)*1000;
                    if (target_time && target_time.getTime()/1000 >= Math.floor(start/1000) && target_time.getTime()/1000 < Math.floor(end/1000)) {
                      category.is_enabled = false;
                    }
                  }
                  
               } else if (order_constraint.dates && order_constraint.dates.startDate && order_constraint.dates.endDate) {
                 
                 if (target_time >= new Date(order_constraint.dates.startDate) && target_time <= new Date(order_constraint.dates.endDate)) {
                   category.is_enabled = false;
                 }

               }
          }
      }
    }

    filterMenuByConstraints() {

      let group_id = null;
      if (this.userService.group) { group_id = this.userService.group.id };
      if (this.userService.basket && this.userService.basket.order) { group_id = this.userService.basket.order.group_id };

      if (this.merchant && this.merchant.order_constraints) {

        let filtered_order_constraints = this.merchant.order_constraints.filter(oc => {
          return  oc.enabled && 
                  oc.category.length > 0 && 
                  oc.payment_methods.length == 0 &&
                  !oc.cut_off &&
                  !oc.cut_off_wday 
           });
        
        //order constraints based on specific groups
        filtered_order_constraints = filtered_order_constraints.filter(oc => { 
          return  (oc.groups.length == 0 && oc.group_types.length == 0) || 
                  (!group_id && oc.group_types.includes('individual')) ||
                  (group_id && oc.groups.includes(group_id)) 
        } );

        //order constraints based on order_location_type
        if (this.userService.basket && this.userService.basket.order) {

          let order_location_typecode = this.userService.basket.order.order_location_typecode;
          let order_location_type = "RemoteLocation";
          if (order_location_typecode == "terminal") order_location_type = "LocalLocations::Terminal";
          if (order_location_typecode == "counter") order_location_type = "LocalLocations::Counter";
          if (order_location_typecode == "room") order_location_type = "LocalLocations::Room";
          if (order_location_typecode == "table") order_location_type = "LocalLocations::Table";
          if (order_location_typecode == "volatile") order_location_type = "LocalLocations::Volatile";

          //order constraints based on order_location_types
          filtered_order_constraints = filtered_order_constraints.filter(oc => { 
            return  (oc.order_location_types.length == 0) || 
                    (oc.order_location_types.includes(order_location_type)) 
          } );  

        }    

       if (this.userService.basket) {
          filtered_order_constraints = filtered_order_constraints.filter(oc => { return oc.scenarios.length == 0 || oc.scenarios.includes(this.userService.basket.order.scenario) });
        } else {
           filtered_order_constraints = filtered_order_constraints.filter(oc => { return oc.scenarios.length == 0 || oc.scenarios.includes('takeaway') });
        }
                
        if (this.merchant && this.merchant.categories) {
          for (let category of this.merchant.categories) {
            this.setCategoryStatus(category, filtered_order_constraints);
          }
        }

        if (this.merchant && this.merchant.menu) {
          for (let category of this.merchant.menu) {
            this.setCategoryStatus(category, filtered_order_constraints);
            for (let subcategory of category.subcategories) {
              this.setCategoryStatus(subcategory, filtered_order_constraints);
              for (let level3 of subcategory.subcategories) {
                level3.is_enabled = true;
                this.setCategoryStatus(level3, filtered_order_constraints);
              }
            }
          }
        }

        this.menuFiltered.emit(true);

      }
    }

    getListOfAllCountries() {
      return this.apiService.get('api/v1/countries', this.userService.access_token);
    }

    getListOfAllTimezones() {
      return this.apiService.get('api/v1/timezones', this.userService.access_token);
    }

    validateVatNumber(vat_number) {
      return this.http.get('https://controleerbtwnummer.eu/api/validate/' + vat_number + '.json');
    }
    
    getTaxons(merchant_id) {
      return this.apiService.get('api/v1/taxons?q[catalog_owner_id_eq]=' + merchant_id + '&q[catalog_owner_type_eq]=Merchant&q[s]=lft+asc', this.userService.access_token);
    }
   
    getCoupons(page, sort_by, searchText) {
      let encodedSearchText = encodeURIComponent(`%${searchText}%`);
      return this.apiService.get(`api/v1/coupons?&page=${page}&q[merchant_id_eq]=${this.merchant.id}&q[s]=${sort_by}&q[formatted_code_matches]=${encodedSearchText}`, this.userService.access_token);
    }

    getCouponsInCSV(page, sort_by, searchText) {
      let encodedSearchText = encodeURIComponent(`%${searchText}%`);

      let headers: HttpHeaders = new HttpHeaders()
        .set('Accept', 'application/json')
        .set('Content-Type', 'application/json');
      if (this.userService.access_token) { headers = headers.append('Authorization', 'Bearer ' + this.userService.access_token); }
      let path = environment.base_api_url + `api/v1/coupons.csv?&page=${page}&q[merchant_id_eq]=${this.merchant.id}&q[s]=${sort_by}&q[formatted_code_matches]=${encodedSearchText}`

      return this.http.get(path, {responseType: 'blob', headers: headers})
    }

    createNewCoupon(coupon, amount) {
      if (amount == 1) return this.apiService.post('api/v1/coupons',  { coupon: coupon, amount: amount }, this.userService.access_token)
      if (amount > 1) return this.apiService.post('api/v1/coupons.csv',  { coupon: coupon, amount: amount }, this.userService.access_token, 'blob')
    }

    updateCouponAmount(coupon, params) {
      return this.apiService.put('api/v1/coupons/' + coupon.id + '/update_amount', params, this.userService.access_token);
    }

    deleteCoupon(coupon) {
      return this.apiService.delete('api/v1/coupons/' + coupon.id, this.userService.access_token);
    }

    updateCouponExpiryDate(coupon, params) {
      return this.apiService.put('api/v1/coupons/' + coupon.id, params, this.userService.access_token);
    }

    getTabs(merchant_id) {
      return this.apiService.get('api/v1/tabs?page=1&q[merchant_id_eq]=' + merchant_id + '&q[s]=created_at+asc&q[status_eq]=open', this.userService.access_token);
    }

    getTab(tab_id) {
      return this.apiService.get('api/v1/tabs/' + tab_id, this.userService.access_token);
    }

    getOrderPriceModifiers(merchant_id) {
      return this.apiService.get('api/v1/order_price_modifiers?q[is_selectable_by_user_eq]=true&q[catalog_owner_id_eq]=' + merchant_id + '&q[catalog_owner_type_eq]=Merchant', this.userService.access_token);
    }

    createTabDiscountModifier(price_modifier) {
      return this.apiService.post('api/v1/tab_discount_modifiers', { tab_discount_modifier: price_modifier }, this.userService.access_token);
    }

    applyTabDiscountModifier(id, params) {
      return this.apiService.post('api/v1/tabs/' + id + '/apply_discount_modifier', params, this.userService.access_token);
    }

    deleteTabDiscountModifier(id, params) {
      return this.apiService.post('api/v1/tabs/' + id + '/revert_discount_modifier', params, this.userService.access_token);
    }

    createTabOrderLineModifier(price_modifier) {
      return this.apiService.post('api/v1/tabs/' + price_modifier.tab_id + '/tab_order_line_modifiers', { tab_order_line_modifier: price_modifier }, this.userService.access_token);
    }

    updateTabOrderLineModifier(price_modifier) {     
      return this.apiService.put('api/v1/tabs/' + price_modifier.tab_id + '/tab_order_line_modifiers/' + price_modifier.id, { tab_order_line_modifier: price_modifier }, this.userService.access_token);
    }

    deleteTabOrderLineModifier(modifier) {
      return this.apiService.delete('api/v1/tabs/' + modifier.tab_id + '/tab_order_line_modifiers/' + modifier.id, this.userService.access_token);
    }

    getXZreports(page, sort_by, filter_on_user = '') {
      return this.apiService.get('api/v1/reports?&page=' + page + '&q[merchant_id_eq]=' + this.merchant.id + '&q[s]=' + sort_by + (filter_on_user != '' ? '&q[user_id_eq]=' + filter_on_user : ''), this.userService.access_token);
    }

    printXZReport(report_id) {
      return this.apiService.get(`api/v1/reports/${report_id}/print`, this.userService.access_token);
    }
    
    getEmployees(merchant_id) {
      return this.apiService.get('api/v1/merchants/' + merchant_id + '/employees', this.userService.access_token);
    }

    getAdmins(merchant_id) {
      return this.apiService.get('api/v1/merchants/' + merchant_id + '/admins', this.userService.access_token);
    }

    getSuperAdmins(merchant_id) {
      return this.apiService.get('api/v1/merchants/' + merchant_id + '/super_admins', this.userService.access_token);
    }

    getFDMEvents(query) {
      return this.apiService.get('api/v1/fdm_events?' + query, this.userService.access_token);
    }

    exportEJ(query): Observable<any> {
      let headers: HttpHeaders = new HttpHeaders()
        .set('Accept', 'application/json')
        .set('Content-Type', 'application/json');
      if (this.userService.access_token) { headers = headers.append('Authorization', 'Bearer ' + this.userService.access_token); }  
      let path = environment.base_api_url + 'api/v1/fdm_events.csv?' + query; 
      return this.http.get(path, {responseType: 'text' as 'json', headers: headers})
      }
      
}