import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogConfig, DialogPosition } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';

import { OrderService } from '../services/order.service';
import { ProductService } from '../services/product.service';
import { TaxonService } from '../services/taxon.service';
import { MerchantService } from '../services/merchant.service';
import { UserService } from '../services/user.service';
import { PubnubService } from '../services/pubnub.service';
import { UtilityService } from '../services/utility.service';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog/confirmation-dialog.component';

import { CheckoutComponent } from '../checkout/checkout.component';
import { ConfirmationComponent } from '../order/confirmation/confirmation.component';

import { HostListener } from '@angular/core';
import { forkJoin, interval, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AppHelper } from '../helpers/app-helper';

import { Basket } from '../models/basket';
import { Order } from '../models/order';

import * as Sentry from '@sentry/browser';

import { trigger, state, style, animate, transition } from '@angular/animations';

import { environment } from '../../environments/environment';

import { faTruck } from '@fortawesome/pro-solid-svg-icons';

export interface Coords {
  latitude: Number;
  longitude: Number;
}

@Component({
	selector: 'app-my-orders',
	templateUrl: './my-orders.component.html',
	styleUrls: ['./my-orders.component.scss'],
	animations: [
  trigger('fadeInOut', [
    state('void', style({
      opacity: 0
    })),
    transition('void <=> *', animate(350)),
    ]),
  trigger('slideUp', [
    state('void', style({
      transform: 'translateY(150%)'
    })),
    transition('void <=> *', animate('0.5s ease-in-out')),
    ]),
  ]
})
export class MyOrdersComponent implements OnInit, AfterViewInit, OnDestroy {

	faTruck = faTruck;

	public finalizing = false;

	trackByIndex = index => index;

	public orders = [];
	public groupOrders: any;
	public subOrders: any;
	public selected_order_id: any;
	private vendingMachineTimer: any;
	public vending_machine_orders = [];
	public vending_data_loaded = false;
	public vending_machine_order: any;
	public whitelabel = "guide";

	private orderPushed;

	public deliverySelected = false;

	public active = 1;

	public fol = false;

	constructor(
		private orderService: OrderService,
		private productService: ProductService,
		private taxonService: TaxonService,
		public merchantService: MerchantService,
		public userService: UserService,
		private pubnubService: PubnubService,
		public util: UtilityService,
		private router: Router,
		private route: ActivatedRoute,
		private translate: TranslateService,
		private dialog: MatDialog,
		private appHelper: AppHelper,
		private cd: ChangeDetectorRef) { 
		this.fol = this.appHelper.isFol();
	}

	ngOnInit() {

		this.whitelabel = environment.whitelabel;

		if (!this.userService.user) { 
			this.router.navigate(['/']);
		} else {
			this.getProfile();
			this.getOrders();
			this.getSubOrders();
			this.userService.collectGroupOrders();
		}

		this.orderPushed = this.pubnubService.orderPushed.subscribe(
			(pubnubOrder) => {
				
				if (this.orders) {
					this.orders.forEach((order, index) => {
						if (order.id == pubnubOrder.id) { this.orders[index] = pubnubOrder; }
					});
					this.cd.detectChanges();
				}
			}
		);

		this.route.queryParams.subscribe(
      		params => {
        		this.selected_order_id = params["id"];
      	});

	}

	getVendingMachineOrder(order) {
		this.orderService.getVendingMachineOrder(order).subscribe(
			response => {
				this.vending_machine_order = response.vending_machine_order;
				console.log(this.vending_machine_order.unlock_code)
			},
			error => {
				console.log(error);
			}
		);

    this.vendingMachineTimer = setTimeout(() => {
      this.getVendingMachineOrder(order);
    }, 20000);
	}
	
	ngAfterViewInit() {
		if (!this.selected_order_id && this.userService.groupOrders && this.userService.groupOrders.length > 0) { 
			//this.tabGroup.selectedIndex = 2;
			this.active = 3;
			this.cd.detectChanges();
		}
	}

	ngOnDestroy() {
		if (this.orderPushed) { this.orderPushed.unsubscribe(); }
		if (this.vendingMachineTimer) { clearTimeout(this.vendingMachineTimer); }
	}

	getProfile() {

		this.userService.getProfile()
		.subscribe(
			user => {
				this.userService.user = user;
			},
			error => {
				console.log(error);
			}
			);

	}

	handlePanelChange(event) {
		let order = this.orders.find(order => order.id === event.panelId);

		if (event.nextState == true) {
			this.vending_machine_order == null
			if (order.dist_location_typecode == 'vending_machine' && order.state == 'prepared') {
				this.getVendingMachineOrder(order)
			}
		}

		if (order.group_id) {
			let query = 'q[parent_group_order_id_eq]=' + order.id;
			this.orderService
        	.getOrdersByQuery(query)
        	.subscribe(
          		(res: any) => {
          			order.suborders = res.orders;
          		},
          		err => {
          			console.log(err);
          		}
        	);
		}

	}

	handleCollapse(order) {

		if (order.group_id) {
			let query = 'q[parent_group_order_id_eq]=' + order.id;
			this.orderService
        	.getOrdersByQuery(query)
        	.subscribe(
          		(res: any) => {
          			order.suborders = res.orders;
          		},
          		err => {
          			console.log(err);
          		}
        	);
		}

		if (!this.deliverySelected) order.is_collapsed = !order.is_collapsed;

		this.cd.detectChanges();
		
	}

	getOrders() {

		this.orderService.getOrders(this.userService.user.id)
		.subscribe(
			response => {
				this.orders = response.orders;						
				this.orders.forEach((order, index) => {
					
					this.calculateOrderTotalPrice(order);
					
					order.is_collapsed = true;
					if (this.selected_order_id == order.id) { 
						this.handleCollapse(order); 
						 setTimeout(function() {
    						let el = document.getElementById(order.id);
							let rect = el.getBoundingClientRect();
							window.scrollTo({
          						top: rect.top,
          						behavior: 'smooth'
        					});

  						}, 1000);		
					}
					else if (!this.selected_order_id && index == 0) { this.handleCollapse(order); }
				});
			},
			error => {
				console.log(error);
			}
			);
	}

	getSubOrders() {

		this.orderService.getSubOrders(this.userService.user.id)
		.subscribe(
			response => {
				this.subOrders = response.orders;
				this.subOrders.forEach((order, index) => {
					//this.appHelper.formatOrderLines(order, this.merchantService.merchant);
					order.is_collapsed = true;
					if (index == 0) { this.handleCollapse(order); }
				});
			},
			error => {
				console.log(error);
			}
			);
	}

	modify(event, order) {
		console.log("MODIFY THE ORDER....");
		event.stopPropagation();

		this.merchantService.getMerchantById(order.merchant_id)
		.subscribe(
			response => {
				let reOrderMerchant = response;
				this.userService.newBasket(reOrderMerchant);
				this.userService.basket.order = order;
				this.userService.basket.order.state = "composing";

				this.orderService.updateOrder(this.userService.basket.order, false).subscribe(
					response => {
						this.userService.basket.order = response;
						this.userService.saveBasketInStorage();
       					this.appHelper.setVenue(reOrderMerchant.domain);
					},
					response => {
						Sentry.captureException(JSON.stringify(response.error));
						//if (response.error && response.error.errors && response.error.errors.base) { 
						this.util.openSnackBar(this.translate.instant('reorder_unavailable'), this.translate.instant('ok')); 
						//}
						//this.spinner.hide('basketbar');
					}
				);
			});
	}

	reOrder(event, order) {

		this.merchantService.getMerchantById(order.merchant_id)
		.subscribe(
			response => {

				let reOrder = JSON.parse(JSON.stringify(order));
				let reOrderMerchant = response;

				reOrder.state = 'composing';
				reOrder.code = null;
				reOrder.api_calls = null;
				reOrder.payment_method = null;
				reOrder.payment_state = null;
				reOrder.id = null;
				reOrder.order_state_changes = null;
				
				for (let order_line of reOrder.order_lines) { 
					
					delete order_line['id'];
					
					for (let order_option of order_line.order_options) { 
							delete order_option['id'];
							for (let order_option_value of order_option.order_option_values) {
								delete order_option_value['id'];
							}
						}

						for (let order_part_remove of order_line.order_part_removes) {
							delete order_part_remove['id'];
							delete order_part_remove['created_at'];
							delete order_part_remove['updated_at'];
						}

						for (let order_part_set of order_line.order_part_sets) {
							delete order_part_set['id'];
							delete order_part_set['created_at'];
							delete order_part_set['updated_at'];
							//delete order_part_set['price_adapt'];
							for (let order_part_add of order_part_set.order_part_adds) {
								delete order_part_add['id'];
								delete order_part_add['created_at'];
								delete order_part_add['updated_at'];
								delete order_part_add['order_part_set_id'];
							}
						}
				}

				reOrder.updated_at = new Date().getTime();

				this.userService.newBasket(reOrderMerchant);
				this.userService.basket.order = reOrder;


				this.orderService.updateOrder(this.userService.basket.order, false).subscribe(
					response => {
						this.userService.basket.order = response;
						this.userService.saveBasketInStorage();
       					this.appHelper.setVenue(reOrderMerchant.domain);
       					this.appHelper.setVenue(reOrderMerchant.domain); 
					},
					response => {
						Sentry.captureException(JSON.stringify(response.error));
						//if (response.error && response.error.errors && response.error.errors.base) { 
						this.util.openSnackBar(this.translate.instant('reorder_unavailable'), this.translate.instant('ok')); 
						//}
						//this.spinner.hide('basketbar');
					}
				);


			},
			error => {
				console.log(error);
			});



		event.stopPropagation();

	}

	/*getGroupOrder(group) {

		this.orderService.getGroupOrder(group.id)
		.subscribe(
			response => {
				group.in_review_orders = response.in_review_orders;
				console.log(group);
			},
			error => {
				console.log(error);
			}
			);
		}*/

		updateGroupOrderCount(count: number, group) {
			group.order_count = count;
		}

		handleCollapseGroup(group) {
			group.is_collapsed = !group.is_collapsed; 
			console.log(group.is_collapsed);
		}

		groupClosed(group) {
			//group.is_collapsed = true;
		}

		groupOpened(group) {
			//group.is_collapsed = false;
			//this.getOrdersById();
		}

		public loadData(merchant_id): Observable<any[]> {
    		const taxonsPromise =  this.taxonService.getTaxons(merchant_id);
    		const productsPromise = this.productService.getProductsForMerchant(merchant_id);
    		const productsWithPriceModifierPromise = this.productService.getProductsWithPriceModifier(merchant_id);
    		const merchantPromise = this.merchantService.getMerchantById(merchant_id);
    		return forkJoin([taxonsPromise, productsPromise, productsWithPriceModifierPromise, merchantPromise]);
  		}

  		setMenu(resMenu) {

  			console.log(resMenu);

    		this.merchantService.merchant.menu = resMenu['menu'];
    		this.merchantService.merchant.mainCategories = resMenu['mainCategories'];
    		this.merchantService.merchant.categories = resMenu['categories'];
    		this.merchantService.merchant.products = resMenu['products'];
  		}

		finalize(event,group) {

			this.finalizing = true;

			//let's first make sure we have all the good orders
			this.userService.collectGroupOrders().then(
				response => {
					group = response.find(the_group => { return group.id == the_group.id});

					//let's create an order
			const fullOrder = new Order(group.in_review_group_orders[0].merchant_id);
			
			//fullOrder.scenario = 'delivery';
			
			fullOrder.scenario 								= group.in_review_group_orders[0].scenario;
			fullOrder.dist_location_typecode 	= group.in_review_group_orders[0].dist_location_typecode;
			fullOrder.dist_location 					= group.in_review_group_orders[0].dist_location;
			fullOrder.dist_location_name 			= group.in_review_group_orders[0].dist_location_name;

			fullOrder.group_order = true;
			fullOrder.group_id = group.id;
			fullOrder.reference = group.name;
			fullOrder.group_who_pays = group.who_pays;
			fullOrder.dist_timing = 'timed';
			fullOrder.updated_at = String(new Date().getTime());
			fullOrder.payment_method = group.in_review_group_orders[0].payment_method;
			fullOrder.payment_state = group.in_review_group_orders[0].payment_state;

			if (fullOrder.scenario == 'delivery' && group.address) {
				fullOrder.dist_location_typecode = 'remote'; 
				const address_submit = JSON.parse(JSON.stringify(group.address));
				address_submit.id = null;
				fullOrder.dist_location = { address: address_submit };
				fullOrder.dist_location_name = this.appHelper.getAddressFormatted(group.address);
			}

			this.loadData(fullOrder.merchant_id).subscribe(
          		responseList => {

				this.merchantService.merchant = this.merchantService.updateMerchant(responseList[3]);
				
				const menu = this.appHelper.loadMenu(responseList[0].taxons,responseList[1].products,responseList[2].price_with_modifiers);
				this.setMenu(menu);

				this.userService.basket = new Basket(fullOrder.merchant_id);
				this.userService.basket.order = fullOrder;

				//valid address if we have filled in dist_location
				if (this.userService.basket.order.dist_location) { this.userService.setValidDeliveryAddress(1); }

				for (const order of group.in_review_group_orders) {
					for (let order_line of order.order_lines) {

						delete order_line['id'];
					
						for (let order_option of order_line.order_options) { 
							delete order_option['id'];
							for (let order_option_value of order_option.order_option_values) {
								delete order_option_value['id'];
							}
						}

						for (let order_part_remove of order_line.order_part_removes) {
							delete order_part_remove['id'];
							delete order_part_remove['created_at'];
							delete order_part_remove['updated_at'];
						}

						for (let order_part_set of order_line.order_part_sets) {
							delete order_part_set['id'];
							delete order_part_set['created_at'];
							delete order_part_set['updated_at'];
							//delete order_part_set['price_adapt'];
							for (let order_part_add of order_part_set.order_part_adds) {
								delete order_part_add['id'];
								delete order_part_add['created_at'];
								delete order_part_add['updated_at'];
								//delete order_part_add['price_adapt'];	
								delete order_part_add['order_part_set_id'];
							}
						}

						this.userService.addOrderLine(order_line);
					}
				}

				this.appHelper.initBasket(true).subscribe(
						response => {
							let domain = this.merchantService.merchant.domain;
							if (domain.includes('.')) { domain = domain.substring(0, domain.indexOf('.')); }
							this.router.navigate(['/' + domain + '/checkout']);
						},
              			error => {
              				console.log(error);
              			});

				},
				error => {
					console.log(error);
					this.finalizing = false;
				});

				},
				error => {
				}
			);

		  event.stopPropagation();

		}

		openRemoveAllSubordersDialog(group) {
			const dialogConfig = new MatDialogConfig();
			dialogConfig.data = {
				message: this.translate.instant('profile.remove_suborders_confirm'),
				group_id: group.id,
				merchant_id: group.in_review_group_orders[0].merchant_id
			};
			const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);

			dialogRef.afterClosed().subscribe(
				response => {
					console.log(response);
					if (response && response.action == 'confirmed') { this.removeAllSuborders(response.data.group_id, response.data.merchant_id); }
				}
				);
		}

		removeAllSuborders(groupId, merchantId) {
			this.orderService.removeAllSuborders(groupId, merchantId)
			.subscribe(
				response => {
					this.userService.groupOrders = this.userService.groupOrders.filter(function( group ) {
						return group.id !== groupId;
					});
					this.util.openSnackBar(this.translate.instant('my-orders.all_suborders_deleted'), this.translate.instant('ok'));
				},
				error => {
					this.util.openSnackBar(this.translate.instant('profile.suborders_delete_error'), this.translate.instant('ok'));
				}
				);
		}

		cancelSuborder(orderId) {
			this.orderService.cancelSuborder(orderId)
			.subscribe(
				response => {
					this.subOrders = this.subOrders.filter(function( order ) {
						if (order.id == orderId) { order.state = response.state; }
						return order;
					});
					this.util.openSnackBar(this.translate.instant('my-orders.suborder_cancelled_success'), this.translate.instant('ok'));
				},
				error => {
					this.util.openSnackBar(this.translate.instant('my-orders.error_cancelling_suborder'), this.translate.instant('ok'));
					console.log(error);
				}
				);
		}

		loadTab(event) {
			if (this.active == 1) { this.getOrders(); }
			else if (this.active == 2) { this.getSubOrders(); }
		}

		onDeliverySelected(event) {
			this.deliverySelected = event;
		}

		public calculateOrderTotalPrice(order) {

		order.price_shown = true;

		//no price shown if one of the order lines is slices
		for (const order_line of order.order_lines) {
        //which unit is selected?
        for (const order_option of order_line.order_options) {
          for (const order_option_value of order_option.order_option_values) {
          	if (order.price_shown == true && order_option_value.price_shown == false) {
          		console.log("FALSE for this order!"); 
          		order.price_shown = order_option_value.price_shown;
          		this.cd.detectChanges();
          	};
          }
        }
    }

		// calculate the price of the order
		order.price = +order.price_before_modifiers;
		// next line for safety
		order.price_after_coupons = order.price;

		//if (order.order_type == 'personal') {

			//price adaption because of modifiers
			if (order.order_order_price_modifiers) {
				for (const order_price_modifier of order.order_order_price_modifiers) {
					order.price += +order_price_modifier.price_adapt;
				}
			}

			order.price_after_coupons = order.price;

	}

	}
