
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ApiService } from '../services/api.service';
import { UserService } from '../services/user.service';

import { environment } from '../../environments/environment';

import { BehaviorSubject } from 'rxjs';

@Injectable()
export class OrderService {

	public merchant: any;

  public waitingOrdersSubject:BehaviorSubject<any> = new BehaviorSubject([]);

	constructor(
		private apiService: ApiService,
		private userService: UserService,
    private http: HttpClient
    ) {}

  setWaitingOrders(waiting_orders) {
    this.waitingOrdersSubject.next(waiting_orders);
  }

  downloadXLSX(merchant_id): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json');
    if (this.userService.access_token) { headers = headers.append('Authorization', 'Bearer ' + this.userService.access_token); }

    let path = environment.base_api_url + 'api/v1/pos_orders/sold_today.xlsx?q[merchant_id_eq]=' + merchant_id + '&q[catalog_owner_type_eq]=Merchant&q[tab_id_null]=true&q[state_in][]=distributed'

    return this.http.get(path, {responseType: 'text', headers: headers})
  }
  
	getOrderV1(order_id): Observable<any> {
		return this.apiService.get('api/v1/orders/' + order_id, this.userService.access_token);
	}

  getReservation(order_id): Observable<any> {
    return this.apiService.get('api/v1/orders/' + order_id + '/reservation', this.userService.access_token);
  }

  getApiCalls(order_id): Observable<any> {
    return this.apiService.get('api/v1/orders/' + order_id + '/api_calls', this.userService.access_token);
  }

  getOrder(orderId): Observable<any> {
    return this.apiService.get('api/v2/orders/' + orderId, this.userService.access_token);
  }

  getLoyaltyPoints(order_id, loyalty_card_qr_code=null): Observable<any> {
    let extra_query = "";
    if (loyalty_card_qr_code != null) { extra_query += `?qr_code=${loyalty_card_qr_code}` };
    return this.apiService.get('api/v1/orders/' + order_id + '/calculate_loyalty_points' + extra_query, this.userService.access_token);
  }

  getOrdersByQuery(query): Observable<any> {
    return this.apiService.get('api/v1/orders?' + query, this.userService.access_token);
  }

  getOrderPackages(query): Observable<any> {
    return this.apiService.get('api/v1/order_packages?' + query, this.userService.access_token);
  }

  printOrderPackages(query): Observable<any> {
    return this.apiService.get('api/v1/order_packages/print?' + query, this.userService.access_token);
  }

  printOrder(order,printer): Observable<any> {
    return this.apiService.get(`api/v1/orders/${order.id}/print?printer_id=${printer.id}`, this.userService.access_token);
  }

  printTab(tab,printer): Observable<any> {
    return this.apiService.get(`api/v1/tabs/${tab.id}/print?printer_id=${printer.id}`, this.userService.access_token);
  }

  createHanotOrder(order, machine_id): Observable<any> {
    return this.apiService.post(`api/v1/orders/${order.id}/create_hanot_order`, { machine_id: machine_id}, this.userService.access_token);
  }

  createFdmEvent(fdm_event): Observable<any> {
    fdm_event.pos_version = '5.4.0';
    return this.apiService.post(`api/v1/fdm_events/`, { fdm_event: fdm_event}, this.userService.access_token);
  }

  getVendingMachineOrder(order): Observable<any> {
    return this.apiService.get(`api/v1/orders/${order.id}/vending_machine_order`, this.userService.access_token);
  }

  getOrderPackageProducts(query): Observable<any> {
    return this.apiService.get('api/v1/order_package_products?' + query, this.userService.access_token);
  }

  getOrders(user_id, merchant_id?): Observable<any> {
    let query = 'q[user_id_eq]=' + user_id + '&q[order_type_eq]=personal&q[state_not_eq]=composing';
    if (merchant_id) { query += '&q[merchant_id_eq]=' + merchant_id; }
    return this.apiService.get('api/v1/orders?' + query, this.userService.access_token);
  }

  getOpenOrders(user_id: number) {
    return this.apiService.get('api/v1/orders?q[order_type_eq]=personal&q[user_id_eq]=' + user_id + '&q[state_in][]=sent&q[state_in][]=received&q[state_in][]=printed', this.userService.access_token);
  }

  getDistributingOrders(user_id: number) {
    return this.apiService.get('api/v1/orders?q[order_type_eq]=personal&q[user_id_eq]=' + user_id + '&q[state_in][]=distributing', this.userService.access_token);
  }
  
  getPreparedOrdersForVendingMachine(user_id: number) {
    return this.apiService.get('api/v1/orders?q[order_type_eq]=personal&q[user_id_eq]=' + user_id + '&q[state_in][]=prepared&q[dist_location_type_eq]=LocalLocations::VendingMachine', this.userService.access_token);
  }

  getSubOrders(user_id, merchant_id?): Observable<any> {
    let query = 'q[user_id_eq]=' + user_id + '&q[order_type_eq]=group&q[state_not_eq]=composing';
    if (merchant_id) { query += '&q[merchant_id_eq]=' + merchant_id; }
    return this.apiService.get('api/v1/orders?' + query, this.userService.access_token);
  }

  getGroupOrder(group_id): Observable<any> {
    return this.apiService.get('api/v2/groups/' + group_id, this.userService.access_token);
  }

  getCheckoutFields(order): Observable<any> {
    const data = '{ "order": ' + JSON.stringify(order) + '}';
    return this.apiService.post('api/v1/checkout_fields/', data, this.userService.access_token);
  }

  getDefaultValues(order): Observable<any> {
    const data = '{ "order": ' + JSON.stringify(order) + '}';
    return this.apiService.post('api/v1/checkout_fields/default_values', data, this.userService.access_token);
  }

  updateOrder(order: any, constraintsCheck: boolean) {

    let uri = "api/v1/orders";
    if (order.device_type == "POS") { uri = "api/v1/pos_orders" };

    const payload = '{ "order": ' + JSON.stringify(order) + ', "check_constraint": ' + constraintsCheck + '}';
    if (!order.id) { return this.apiService.post(uri, payload, this.userService.access_token); }
    else { return this.apiService.patch(uri + "/" + order.id, payload, this.userService.access_token); }
  }

  getCheckout(order: any, payload: String) {
    return this.apiService.post('api/v1/orders/' + order.id + '/payment', payload, this.userService.access_token);
  }

  mollieWebhook(order_id: any) {
    return this.apiService.post('api/v1/orders/mollie_webhook', JSON.stringify({order_id: order_id}), this.userService.access_token);
  }

  monizzePayment(order: any, payload: String) {
    return this.apiService.post('api/v2/orders/' + order.id + '/monizze_payment', payload, this.userService.access_token);
  }

  isMolliePayment(order) {
    if (order.payment_method && order.payment_method.includes('MOLLIE')) { return true; }
    else { return false; }
  }

  removeAllSuborders(groupId, merchantId): Observable<any> {
    return this.apiService.delete('api/v2/orders/cancel_group_order/' + groupId + '?merchant_id=' + merchantId, this.userService.access_token);
  }

  removeSuborder(orderId) {
    return this.apiService.delete('api/v2/orders/' + orderId + '/destroy_suborder', this.userService.access_token);
  }

  cancelSuborder(orderId) {
    return this.apiService.put('api/v2/orders/' + orderId + '/cancel_suborder', {}, this.userService.access_token);
  }

  buildCouponCodes(): any[] {
    const coupon_codes = [];
    for (const coupon of this.userService.basket.coupons) { coupon_codes.push(coupon.coupon_code); }
      return coupon_codes;
  }

  getPaymentStatus(order_id,payment_id): any {
    return this.apiService.get('api/v1/orders/' + order_id + '/payment_status?payment_id=' + payment_id, this.userService.access_token);
  }  

  getOrderConstraints(merchant_id) {
    let query = 'q[catalog_owner_id_eq]=' + merchant_id;
    return this.apiService.get('api/v1/order_constraints?' + query, this.userService.access_token);
  }

  getStats(query) {
     return this.apiService.get('api/v1/orders/stats?' + query, this.userService.access_token); 
  }


  initiatePayment(payment_method, method?, amount?, spare_change?) {

    const order = this.userService.basket.order;
    let access_token = null;
    if (this.userService.access_token) { access_token = this.userService.access_token; }
    if (!amount) { amount = order.price_after_coupons; }

    let payload = '{"pm": "' + payment_method + '"';
    if (method) payload += ', "method": "' + method + '"';

    if (payment_method == 'monizze') {

      let mid      = "4";
      let mref     = (order.id).toString();

      let amount   = (order.price_after_coupons * 100).toString();
      let type     = "200001";
      let email    = order.user_email;
      let shop     = order.merchant_name;

      let accept   = environment.base_api_url + 'api/v1/orders/monizze';
      let cancel   = environment.base_api_url + 'api/v1/orders/monizze';
      let decline  = environment.base_api_url + 'api/v1/orders/monizze';

      (<HTMLInputElement>document.getElementById("MID")).value              = mid;
      (<HTMLInputElement>document.getElementById("MREF")).value             = mref;
      (<HTMLInputElement>document.getElementById("AMOUNT")).value           = amount;
      (<HTMLInputElement>document.getElementById("TYPE")).value             = type;
      (<HTMLInputElement>document.getElementById("EMAIL")).value            = email;
      (<HTMLInputElement>document.getElementById("SHOP")).value             = shop;
      (<HTMLInputElement>document.getElementById("URL_ACCEPTED")).value     = accept;
      (<HTMLInputElement>document.getElementById("URL_CANCELED")).value     = cancel;
      (<HTMLInputElement>document.getElementById("URL_DECLINED")).value     = decline;

      payload += ', "mid":' + mid;
      payload += ', "amount":' + amount;
      payload += ', "type": "' + type + '"';
      payload += ', "email": "' + email + '"';
      payload += ', "shop": "' + shop + '"';
      payload += ', "accept": "' + accept + '"';
      payload += ', "cancel": "' + cancel + '"';
      payload += ', "decline": "' + decline + '"';
    }

    payload += ', "final_price":' + amount;
    payload += ', "coupon_codes":"' + this.buildCouponCodes().join(',') + '"';
    if (spare_change) { payload += ', "spare_change":' + spare_change; }; 
    payload += '}';

    console.log('PAYLOAD =>');
    console.log(payload);

    return this.apiService.post('api/v1/orders/' + order.id + '/payment', payload, access_token);
  }

  initiateOrderPayment(payment_method, method,order, pos_payment, counter_location_id?, amount?, spare_change?) {

    let access_token = null;
    if (this.userService.access_token) { access_token = this.userService.access_token; }

    let payload = '{"pm": "' + payment_method + '"';
    if (method) payload += ', "method": "' + method + '"';
    if (counter_location_id) payload += ', "counter_location_id": "' + counter_location_id + '"';
    if (!amount) { amount = order.price_after_coupons; }

    if (payment_method == 'monizze') {

      let mid      = "4";
      let mref     = (order.id).toString();

      let amount   = (order.price_after_coupons * 100).toString();
      let type     = "200001";
      let email    = order.user_email;
      let shop     = order.merchant_name;

      let accept   = environment.base_api_url + 'api/v1/orders/monizze';
      let cancel   = environment.base_api_url + 'api/v1/orders/monizze';
      let decline  = environment.base_api_url + 'api/v1/orders/monizze';

      (<HTMLInputElement>document.getElementById("MID")).value              = mid;
      (<HTMLInputElement>document.getElementById("MREF")).value             = mref;
      (<HTMLInputElement>document.getElementById("AMOUNT")).value           = amount;
      (<HTMLInputElement>document.getElementById("TYPE")).value             = type;
      (<HTMLInputElement>document.getElementById("EMAIL")).value            = email;
      (<HTMLInputElement>document.getElementById("SHOP")).value             = shop;
      (<HTMLInputElement>document.getElementById("URL_ACCEPTED")).value     = accept;
      (<HTMLInputElement>document.getElementById("URL_CANCELED")).value     = cancel;
      (<HTMLInputElement>document.getElementById("URL_DECLINED")).value     = decline;

      payload += ', "mid":' + mid;
      payload += ', "amount":' + amount;
      payload += ', "type": "' + type + '"';
      payload += ', "email": "' + email + '"';
      payload += ', "shop": "' + shop + '"';
      payload += ', "accept": "' + accept + '"';
      payload += ', "cancel": "' + cancel + '"';
      payload += ', "decline": "' + decline + '"';
    }

    payload += ', "final_price":' + amount;
    payload += `,"pos_payment": ${pos_payment}`
    if (spare_change) { payload += ', "spare_change":' + spare_change; };
    //payload += ', "coupon_codes":"' + this.buildCouponCodes().join(',') + '"';
    payload += '}';

    console.log('PAYLOAD =>');
    console.log(payload);


    return this.apiService.post('api/v1/orders/' + order.id + '/payment', payload, access_token);
  }
  
  sendOrder(): Observable<any> {

    let order = this.userService.basket.order;
    order.state = 'sent';

    const updateOrder = this.updateOrder(order, true);

    updateOrder.subscribe(
      response => {
        order = response;
        return true;
      },
      response => {
        return false;
      });

    return updateOrder;
  }

  validateCouponCode(merchant_id, coupon_code) {
    let access_token = null;
    if (this.userService.access_token) { access_token = this.userService.access_token; }
    return this.apiService.post('api/v1/merchants/' + merchant_id + '/validate_coupon/' + coupon_code, '{}', access_token);
  }

  suggestProducts(order_id) {
    return this.apiService.get('api/v2/orders/' + order_id + '/user_suggestions', this.userService.access_token);
  }

  closeTab(tab_id) {
    return this.apiService.post('api/v1/tabs/' + tab_id + '/close_tab', '{}', this.userService.access_token);
  }
}
