import { Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { DialogData } from '../../pos/pos.component';
import { MerchantService } from 'src/app/services/merchant.service';
import { faArrowAltLeft} from '@fortawesome/pro-solid-svg-icons';

import { OrderService } from 'src/app/services/order.service';
import { UserService } from 'src/app/services/user.service';
import { UtilityService } from '../../../services/utility.service';
import { TranslateService } from '@ngx-translate/core';
import { FdmService } from '../../services/fdm.service';

import { Order } from '../../../models/order';

import * as Sentry from '@sentry/browser';

@Component({
  selector: 'app-pos-cash-dialog',
  templateUrl: './pos-cash-dialog.component.html',
  styleUrls: ['./pos-cash-dialog.component.scss']
})
export class PosCashDialogComponent implements OnInit {

  public order:Order;
  public change: String = "";
  public moneyReceived: String = "";
  private timer: any;
  public arrow_left = faArrowAltLeft;
  public ongoing: boolean = true;
  public fdm = false;

  @ViewChild('input') input: ElementRef<HTMLInputElement>;

  constructor(
    private matdialog: MatDialog,
    private dialogRef: MatDialogRef<PosCashDialogComponent>,
    public merchantService: MerchantService,
    private orderService: OrderService,
    private userService: UserService,
    private util: UtilityService,
    private translate: TranslateService,
    private fdmService: FdmService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  
  roundPrice(price) {

    let decimals = Math.round(100 * (price % 0.05)) / 100;
    let rounded_price = price;

    if (price >= 0.05) {
      if (decimals == 0.04) rounded_price += 0.01;
      if (decimals == 0.03) rounded_price += 0.02;
      if (decimals == 0.02) rounded_price -= 0.02;
      if (decimals == 0.01) rounded_price -= 0.01;
    } 

    return rounded_price;
  }

  ngOnInit(): void {

    this.fdm = this.merchantService.hasFeature(this.merchantService.merchant.id, 'f_fdm');
    this.order = this.data.order;
    this.order.rounded_price = this.roundPrice(+this.order.price);

    if (this.order.payment_method == "PAY_LATER") { this.onPayLater(); };
    if (this.order.order_type == 'refund') { this.moneyReceived = "-"; }
  }

  clickNumber(number) {
    if (this.timer) { clearTimeout(this.timer) }
    this.moneyReceived += number;
    this.calculateChange();
  }

  erase() {
    if (this.timer) { clearTimeout(this.timer) }
    if (this.moneyReceived != "-") { this.moneyReceived = this.moneyReceived.slice(0, -1) }
    this.calculateChange();
  }

  onConfirmPayment() {
    console.log("onConfirmPayment()");
    this.ongoing = false;

    if (this.order.order_location_typecode == 'counter' ) {
      this.fdmService.needsProForma(this.order, this.userService.user, this.userService.posCounterId, true);
    }
   
    this.orderService
           .initiateOrderPayment("cash", null, this.order, true, null, this.moneyReceived, this.change)
        .subscribe(
          (response: any) => {

            let received_order = response.order;

            this.order.state = received_order.state;
            this.order.payment_state = received_order.payment_state;
            this.order.payment = received_order.payment;

            if (this.fdm) { 
              let event_label = this.order.order_type == 'refund' ? 'NR' : 'NS';
              this.fdmService.addOrderToQueue(event_label, this.order, this.userService.user, this.userService.posCounterId, true); 
            } else {
              //no fdm, but we do create an fdm event for X/Z reports
              this.fdmService.sendConfirmationToBE(this.order.merchant_id, this.order, null, true, null, this.userService.user, null,  this.userService.posCounterId, null);
            }
        
           setTimeout(() => {
              this.dialogRef.close({"order_id": this.order.id, "status": "completed"});
            }, 5000);

          },
          err => {
            console.log(err);
            Sentry.captureException(JSON.stringify(err));
          }
        );
    
    /*
    if (this.order.state == 'composing') { this.order.state = 'sent' };
    this.order.payment_state = 'paid'
    this.orderService.updateOrder(this.order, true).subscribe(
      response => {
        this.order = response;
        setTimeout(() => {
          this.dialogRef.close({"order_id": this.order.id, "status": "completed"});
        }, 5000);
      },
      response => {
        console.log(response);
        Sentry.captureException(JSON.stringify(response.error));
        if (response.error && response.error.errors && response.error.errors.base) { this.util.openSnackBar(response.error.errors.base[0], this.translate.instant('ok')); }
      });
      */

  }

  onCancel() {
    this.ongoing = false;
    this.dialogRef.close({"order_id": this.order.id, "status": "cancelled"})
  }

  onOk() {
    this.dialogRef.close({"order_id": this.order.id, "status": "completed"})
  }

  onPayLater() {
    this.ongoing = false;
    if (this.order.state == 'composing') { this.order.state = 'sent' };
    this.order.payment_state = 'unpaid';
    this.orderService.updateOrder(this.order, true).subscribe(
      response => {
        this.order = response;
        if (this.fdm) { 
          console.log("#########WE WILL NOW ADD THIS PAY LATER ORDER TO THE FDM#############");
          this.fdmService.addOrderToQueue("PS", this.order, this.userService.user, this.userService.posCounterId, true); 
        }

        this.dialogRef.close({"order_id": this.order.id, "status": "completed"})
      },
      response => {
        Sentry.captureException(JSON.stringify(response.error));
        if (response.error && response.error.errors && response.error.errors.base) { this.util.openSnackBar(response.error.errors.base[0], this.translate.instant('ok')); }
      });
  }

  calculateChange() {
    this.timer = setTimeout(() => {
      if (this.moneyReceived == "-") {
        this.change = this.order.rounded_price.toFixed(2);
      } else {
        this.change = (Number(this.moneyReceived) - this.order.rounded_price).toFixed(2)
      }
    }, 1000);
  }
}
