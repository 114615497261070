import { Component, OnInit } from '@angular/core';
import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-fol-footer',
  templateUrl: './fol-footer.component.html',
  styleUrls: ['./fol-footer.component.scss']
})
export class FolFooterComponent implements OnInit {
  public faFacebook = faFacebook;
  public faInstagram = faInstagram;
  public faTwitter = faTwitter;

  constructor() { }

  ngOnInit(): void {
  }

}
