import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { AdvertisementService } from '../services/advertisement.service';
import { GroupService } from '../services/group.service';
import { MerchantService } from '../services/merchant.service';
import { OrderService } from '../services/order.service';
import { PrintService } from '../services/print.service';
import { ProductService } from '../services/product.service';
import { PubnubService } from '../services/pubnub.service';
import { TaxonService } from '../services/taxon.service';
import { UserService } from '../services/user.service';
import { UtilityService } from '../services/utility.service';

import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, DialogPosition } from '@angular/material/dialog';
import { CategoryDialogComponent } from '../category-dialog/category-dialog.component';

import { User } from '../models/user';

import { AppHelper } from '../helpers/app-helper';
import { StylingHelper } from '../helpers/styling-helper';

import { Product } from '../models/product';
import { Taxon } from '../models/taxon';

import { environment } from '../../environments/environment';

import { MatSnackBar } from '@angular/material/snack-bar';

import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import { NgxSpinnerService } from 'ngx-spinner';

import { Location } from '@angular/common';

import { animate, state, style, transition, trigger } from '@angular/animations';

import smoothscroll from 'smoothscroll-polyfill';

import * as Sentry from '@sentry/browser';

import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

import { animate as animateMotion } from "motion";



@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition('void <=> *', animate(350)),
    ]),
    trigger('fadeInOut2', [
      state('invisible', style({
        opacity: 0
      })),
      state('visible', style({
        opacity: 1
      })),
      transition('visible <=> *', animate(350)),
    ]),
    trigger('fadeIn', [
      state('void', style({
        opacity: 0
      })),
      transition('void => *', animate(350)),
    ]),
    trigger('slideUp', [
      state('void', style({
        transform: 'translateY(150%)'
      })),
      transition('void <=> *', animate('0.5s ease-in-out')),
    ])
  ]
})

export class MenuComponent implements OnInit, OnDestroy {

  public template: string;

  private ENVIRONMENT: string = environment.ENVIRONMENT;
  public whitelabel = environment.whitelabel;

  public selectedCategory: Taxon;
  public targetCategory: Taxon;
  public selectedCategoryIndex = 0;

  public loading = false;
  public headerLoading = false;

  private snackbarToBeShown = false;

  public selectedProduct: Product;
  public needsUserDetails = false;
  public needsKioskDetails = false;
  public needsOrderDetails = false;
  public forceOrderDetails = false;
  public data_request_screen_shown = false;
  public basketHasContent = false;
  public basketFeature = 'checkout';

  public scrollPos = 0;

  private userUpdateSubscription;
  private languageUpdateSubscription;
  private menuFilteredSubscription;
  private locationSubscription;
  private basketPushedSubscription;
  private orderUpdateSubscription;
  private timeChosen;

  private merchant_slug;
  private merchant_domain;

  public advertisements: any;

  public backGoesTo = '../venues';

  public primary_color;

  private levelOfActivitySubscription: any;

  public background_image: string;

  public basketHeight = 'calc(100vh - 350px)';

  public kioskPaymentMethods = [];

  public mobileSearchEnabled = true;
  public mobileSearch = false;
  public searchText = '';
  public searchInitText = '';

  private qr_scan = false;
  private qr_token = null;

  public menu_only = false;
  public menu_only_authentication = false;
  public table_authentication = false;

  public loyalty_enabled = false;

  public menu: any;
  public mainCategories: any;

  public productIndex = 0;
  private productIndexTimer: any;

  public category_overview_height: 400;

  public loyalty_points: any;

  public breakpoint = "sm";

  public printer_issue = false;
  public printer_issue_description;
  private printer_timer: any;

  private animation: any;

  public payment_methods = [];
  public comment_editable = false;

  public fol = false;

  public dialogRef;

  @ViewChild('mobileProductSearch') mobileProductSearchElement: ElementRef;
  @ViewChild('kioskOrderHere', { read: ElementRef }) kioskOrderHere: ElementRef<any>;

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      map(term => {
        this.spinner.show('menu');

        setTimeout(() => {
          this.searchText = term;
          this.spinner.hide('menu');
          this.cd.detectChanges();
        }, 1000);

        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });

      })
    );

  @ViewChild('categorybar') public categorybar: ElementRef<any>;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.breakpoint = this.appHelper.getBootstrapBreakpoint();
  }

  constructor(
    public merchantService: MerchantService,
    public groupService: GroupService,
    public advertisementService: AdvertisementService,
    public userService: UserService,
    public orderService: OrderService,
    public productService: ProductService,
    private taxonService: TaxonService,
    private pubnubService: PubnubService,
    private printService: PrintService,
    private router: Router,
    private route: ActivatedRoute,
    private util: UtilityService,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    private spinner: NgxSpinnerService,
    private location: Location,
    private renderer: Renderer2,
    public appHelper: AppHelper,
    private stylingHelper: StylingHelper,
    private cd: ChangeDetectorRef,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
  ) {

    this.fol = this.appHelper.isFol();
    this.setBasketFeature();

    this.route.params.subscribe(
      params => {
        this.merchant_slug = params['merchant_slug'];
        this.merchant_domain = params['merchant_slug'];
        if (this.merchant_domain.indexOf('one2three') == -1) { this.merchant_domain += '.one2three.be'; }
      });

    this.route.queryParams.subscribe(params => {
      if (params['scenario']) { this.userService.scenario = params['scenario'] };
      if (params['locale']) { this.translate.use(params['locale']) };
      if (params['forceOrderDetails']) { this.forceOrderDetails = true; }
      if (params['qr_token']) this.qr_token = params['qr_token'];
      if (params['qr_scan']) {
        this.qr_scan = true;
        this.location.go(this.merchant_slug + '/menu?qr_token=' + this.qr_token);
      }
    });
  }

  toggleMobileSearch() {
    this.mobileSearch = !this.mobileSearch;
    if (this.mobileSearch) {
      setTimeout(() => {
        this.mobileProductSearchElement.nativeElement.focus();
      }, 100);
    } else {
      if (this.searchText.length > 0) this.spinner.show('menu');
      setTimeout(() => {
        this.clearText();
        setTimeout(() => {
          this.spinner.hide('menu');
        }, 1500);
      }, 500);

    }
  }

  toggleNeedsKioskDetails(true_or_false) {
    this.needsKioskDetails = true_or_false
    if (this.needsKioskDetails == true) {
      this.setVenue(this.merchant_domain, true);
      this.animation = setTimeout(() => {
        if (this.kioskOrderHere) {
          animateMotion(
            this.kioskOrderHere.nativeElement,
            { scale: [1.1, 1] },
            {
              direction: "alternate",
              duration: 2,
              repeat: Infinity,
            });
        }
      }, 1000);
    } else {
      if (this.animation) clearTimeout(this.animation);
    }
  };

  register() {

    let user_submit = new User();
    user_submit.email = "table-" + Math.floor(Math.random() * 10000) + "@one2three.be";
    //user_submit.locale = 'nl';
    user_submit.locale = this.translate.currentLang;

    this.userService.anonymousUser(user_submit)
      .subscribe(
        user => {
          const credentials = {
            email: user.email,
            client_id: 'fb1fd14bb6bdcbad74ce47133392d1bd49a5c9dadc8f5301177d5e23932c17f4'
          };
          this.anonymousUserToken(credentials);
        },
        error => {
          console.log(error);
        }
      );
  }

  anonymousUserToken(credentials) {

    this.userService.anonymousUserToken(credentials)
      .subscribe(
        auth => {
          sessionStorage.setItem('ngStorage-token', JSON.stringify(auth));
          localStorage.setItem('ngStorage-token', JSON.stringify(auth));
          this.userService.access_token = auth.access_token;
          this.getProfile();
        },
        error => {
          console.log(error);
          this.util.openSnackBar(this.translate.instant('user.login_error'), this.translate.instant('ok'));
        }
      );
  }

  getProfile() {

    this.userService.getProfile()
      .subscribe(
        user => {
          this.userService.setUser(user);
          this.initPart2();
        },
        error => {
          console.log(error);
        }
      );

  }

  loadMerchantDataPromises(domain, qr_token): Observable<any[]> {
    let qrCodePromise = this.merchantService.getLocalLocationByQRCode(qr_token);
    if (qr_token.startsWith("G-")) { qrCodePromise = this.groupService.getGroupByQrCode(qr_token); };
    let merchantPromise = this.merchantService.getMerchantByDomain(domain);
    return forkJoin([qrCodePromise, merchantPromise]);
  }

  increaseProductIndex() {

    let detectedChange = false;

    if (this.mainCategories) {
      for (let category of this.mainCategories) {

        //read the svg
        if (!category.icon_data && category.icon) {
          this.http.get(category.icon.svg_url, { responseType: 'text' })
            .subscribe(data => {
              data = data.replace("width", "xidth");
              data = data.replace("height", "xeight");
              data = data.replace(/fill/g, "xill");
              let start_index = data.indexOf("<svg");
              category.icon_data = this.sanitizer.bypassSecurityTrustHtml(data.substring(start_index));
              this.cd.detectChanges();
            });
        }

        if (!category.productIndex) category.productIndex = 0;
        category.productIndex++;

        while (category.productIndex > 0 && (!category.products[category.productIndex] || !category.products[category.productIndex].main_image)) {

          category.productIndex++;

          if (!category.products[category.productIndex]) {
            category.productIndex = 0;
          }
        }

        if (category.products[category.productIndex] && category.products[category.productIndex].main_image) {
          category.background_url = category.products[category.productIndex].main_image.medium_url;
          detectedChange = true;
        }
      }

      if (detectedChange == true) { this.cd.detectChanges(); }
    }



  }

  increaseProductIndexTimeout() {

    this.increaseProductIndex();

    this.productIndexTimer = setTimeout(() => {
      this.increaseProductIndexTimeout();
    }, 5000);

  }

  ngOnInit() {

    //determine device sceeen size
    this.breakpoint = this.appHelper.getBootstrapBreakpoint();

    if (this.qr_token) {

      this.loadMerchantDataPromises(this.merchant_domain, this.qr_token).subscribe(
        responseList => {

          if (this.qr_token.startsWith("G-")) {
            this.userService.group = responseList[0][0];
          } else { this.userService.local_location = responseList[0] };
          this.merchantService.merchant = this.merchantService.updateMerchant(responseList[1]);
          this.template = this.stylingHelper.getTemplate(this.merchantService.merchant.settings["s_template"]);

          if (this.template == 'template2') {
            this.showDataRequestScreen();
            this.cd.detectChanges();
          }

          this.menu_only = this.merchantService.hasFeature(this.merchantService.merchant.id, 'f_menu_only');
          this.menu_only_authentication = this.merchantService.hasFeature(this.merchantService.merchant.id, 'f_menu_only_authentication');
          this.table_authentication = this.merchantService.hasFeature(this.merchantService.merchant.id, 'f_table_authentication');
          this.loyalty_enabled = this.merchantService.hasFeature(this.merchantService.merchant.id, "f_loyalty_points");

          if (this.qr_scan && this.userService.local_location && this.userService.local_location.typecode == 'table' && !this.table_authentication && !this.userService.user && !(this.menu_only && this.menu_only_authentication)) {
            this.register();
          } else {
            this.initPart2();
          }

        }, error => {
          console.log(error);
        }
      );

    } else {
      this.userService.local_location = null;
      this.initPart2();
    }

    this.timeChosen = this.appHelper.timeChosenEmitter.subscribe(() => {
      this.showDataRequestScreen();
      console.log("(A) needsOrderDetails true");
      this.needsOrderDetails = true;
      this.cd.detectChanges();
    });

    this.increaseProductIndexTimeout();

    if (this.merchantService.kiosk) {

      //need a safety
      if (!this.userService.user) {
        this.router.navigate(['../'], { relativeTo: this.route });
      } else {

        this.getPrinterStatus();

        if (this.userService.basket && this.userService.basket.order) {
          //it's a kiosk, but we currently have an ongoing order
        } else if (this.userService.user.virtual_user) {
          this.toggleNeedsKioskDetails(true);
        }

      }
      
    }

  }

  hexToRgbA(hex){
    let c;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c= hex.substring(1).split('');
        if(c.length== 3){
            c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',0.7)';
    }
    throw new Error('Bad Hex');
  }

  getPrinterStatus() {


    try {

      if (this.merchantService.merchant && this.merchantService.kiosk) {

        this.printService.getStatus(this.merchantService.merchant.id, this.merchantService.kiosk.id)
          .subscribe(
            response => {
              const printer = response.printer
              if (!printer || printer.status.indexOf("200") !== -1) {
                this.printer_issue = false;
              } else {
                this.printer_issue = true;
                this.printer_issue_description = printer.status.replaceAll("%20", " ");
              }
            },
            error => {
              console.log(error);
            }
          );

      }

    } catch (error) {
      console.log(error);
    }

    this.printer_timer = setTimeout(() => {
      this.getPrinterStatus();
    }, 10000);

  }

  showDataRequestScreen() {
    if ((!this.merchantService.kiosk || !this.userService.user.virtual_user) && !this.dialogRef) {

          console.log(this.dialogRef);

          this.data_request_screen_shown = true;

          let dialogConfig = new MatDialogConfig();

          dialogConfig.hasBackdrop = true;
          dialogConfig.backdropClass = 'backdropBackground';

          if (window.innerWidth < 500) {
            dialogConfig.panelClass = ['full-width-dialog'];
            dialogConfig.height = '100vh';
          } else { 
            dialogConfig.maxWidth = '75vw';
            dialogConfig.minWidth = '50vw';
            dialogConfig.maxHeight = '75vh';
          }

          dialogConfig.data = {
            needsUserDetails: this.needsUserDetails,
            needsOrderDetails: this.needsOrderDetails,      
            mainCategories: this.mainCategories
          };

          console.log("This is the dialogConfig used: ");
          console.log(dialogConfig);

          this.dialogRef = this.dialog.open(CategoryDialogComponent, dialogConfig);
          this.dialogRef.afterClosed().subscribe(result => {
             if (result?.taxon) this.clickTaxon(result.taxon);
             this.dialogRef = null;
             this.hideDialog();
             console.log("DIALOG CLOSED, WILL VERIFY IF BASKET HAS CONTENT");
             this.checkBasketHasContent();
             this.cd.detectChanges();
          });

          this.router.events
           .subscribe(() => {
             if (this.dialogRef) this.dialogRef.close();
           });

          this.cd.detectChanges();
    };
  }

  initBackButton() {

    if (this.template == 'template2') { this.backGoesTo = null; }
    else if (this.merchantService.kiosk) { this.backGoesTo = null; }
    else if (this.userService.user && this.userService.user.virtual_user) { this.backGoesTo = null; }
    else if (this.merchantService.singleMerchantWhitelabel) { this.backGoesTo = '../'; }
    else this.backGoesTo = '../venues';

  }

  initPart2() {

    // kick off the polyfill!
    smoothscroll.polyfill();

    this.initBackButton();

    this.locationSubscription = this.location.subscribe(x => {
      if (x.pop) { this.onProductCanceled(); }
    });

    this.spinner.show('menu');

    this.appHelper.verifyBasketOrderState(this.merchant_slug);

    //we still need to load the merchant
    this.loading = true;
    this.setVenue(this.merchant_domain);

    this.userUpdateSubscription = this.userService.userUpdate.subscribe((user) => {
      this.checkBasketHasContent();
      this.cd.detectChanges();
    });

    this.languageUpdateSubscription = this.userService.languageUpdate.subscribe((language) => {
      this.appHelper.loadMerchantData(this.merchantService.merchant.id).subscribe(
        responseList => {
          this.menu = this.merchantService.merchant.menu;
          this.mainCategories = this.merchantService.merchant.mainCategories;
          this.setMenuTaxonIndexes();
          this.cd.detectChanges();
        });
    });

    this.menuFilteredSubscription = this.merchantService.menuFiltered.subscribe((user) => {
      this.setMenuTaxonIndexes();
      this.cd.detectChanges();
    });

    this.levelOfActivitySubscription = this.pubnubService.levelOfActivityPushed.subscribe(
      (message) => {
        if (message.order.level_of_activity > 0) this.util.openSnackBar(this.translate.instant('level_of_activity.' + message.order.level_of_activity), this.translate.instant('ok'));
        this.merchantService.merchant.level_of_activity = message.order.level_of_activity;
      }
    );

    this.basketPushedSubscription = this.userService.basketPushed.subscribe((basket) => {
      if (!this.userService.basket) this.initBasket();
    });

    this.orderUpdateSubscription = this.userService.orderUpdate.subscribe((updateOngoing) => {
      console.log("THE MENU COMPONENT DETECTED AN ORDER UPDATE!!!");
      this.checkBasketHasContent();
      this.cd.detectChanges();
    });

  }

  setMenuTaxonIndexes() {

    if (this.merchantService.merchant &&
      this.merchantService.merchant.menu) {

      this.menu = this.merchantService.merchant.menu.filter(category => { return category.is_enabled });
      let index = 0;
      for (let category of this.menu) {
        for (let subcategory of category.subcategories) {
          for (let subsubcategory of subcategory.subcategories) {
            subsubcategory.index = index;
            if (subsubcategory.is_enabled && !subsubcategory.empty) { index++; }
          }
        }
      }

      this.mainCategories = this.merchantService.merchant.mainCategories.filter(category => { return category.is_enabled; });

    }

  }

  backClicked() {

    if (this.merchantService.kiosk) {
      if (this.userService.user.virtual_user) {
        this.userService.setBasket(null);
      } else {
        this.userService.logoutUser();
        this.router.navigate(['login'], { queryParams: { domain: this.merchant_slug } });
      }
    }

    this.cd.detectChanges();
  }

  ngOnDestroy() {

    if (this.userUpdateSubscription) this.userUpdateSubscription.unsubscribe();
    if (this.languageUpdateSubscription) this.languageUpdateSubscription.unsubscribe();
    if (this.menuFilteredSubscription) this.menuFilteredSubscription.unsubscribe();
    if (this.locationSubscription) this.locationSubscription.unsubscribe();
    if (this.levelOfActivitySubscription) this.levelOfActivitySubscription.unsubscribe();
    if (this.levelOfActivitySubscription) this.basketPushedSubscription.unsubscribe();
    if (this.orderUpdateSubscription) this.orderUpdateSubscription.unsubscribe();
    if (this.timeChosen) this.timeChosen.unsubscribe();
    if (this.merchantService.merchant) this.pubnubService.unSubscribeChannel(this.ENVIRONMENT + '.merchant-' + this.merchantService.merchant.id);

    if (this.productIndexTimer) clearTimeout(this.productIndexTimer);
    if (this.printer_timer) clearTimeout(this.printer_timer);

  }

  checkBasketHasContent() {
    if (this.userService.basket && this.userService.basket.order.order_lines && this.userService.basket.order.order_lines.length > 0) { 
       console.log("BASKET HAS CONTENT");
       this.basketHasContent = true;
    }
    else { 
      console.log("BASKET HAS NO CONTENT");
      this.basketHasContent = false;
    }
  }

  getMerchantStats(res) {
    const merchant_stats = res.merchant_stats;
    for (const merchant_stat of merchant_stats) {
      if (this.merchantService.merchant.id == merchant_stat.merchant_id) {
        this.merchantService.merchant.waiting_time = merchant_stat.waiting_time;
        this.merchantService.merchant.waiting = merchant_stat.waiting;
        this.merchantService.merchant.daily_order_count = merchant_stat.daily_order_count;
        this.merchantService.merchant.daily_order_count_week_ago = merchant_stat.daily_order_count_week_ago;
        break;
      }
    }
  }

  handleMerchantData() {

    this.spinner.hide('menu');

    this.merchantService.merchant.primary_color = this.appHelper.getMerchantPrimaryColor();

    this.pubnubService.pubnubSubscribeChannel(this.ENVIRONMENT + '.merchant-' + this.merchantService.merchant.id);

    this.selectedCategoryIndex = 0;
    this.loading = false;

    this.menu = this.merchantService.merchant.menu;
    this.mainCategories = this.merchantService.merchant.mainCategories;
    this.merchantService.filterMenuByConstraints();

    this.setMenuTaxonIndexes();

    this.selectedCategory = this.mainCategories[0];

    this.increaseProductIndex();

    this.menu_only = this.merchantService.hasFeature(this.merchantService.merchant.id, 'f_menu_only');
    this.menu_only_authentication = this.merchantService.hasFeature(this.merchantService.merchant.id, 'f_menu_only_authentication');
    this.table_authentication = this.merchantService.hasFeature(this.merchantService.merchant.id, 'f_table_authentication');
    this.loyalty_enabled = this.merchantService.hasFeature(this.merchantService.merchant.id, "f_loyalty_points");

    if (!this.userService.user && this.menu_only && this.menu_only_authentication) {
      this.router.navigate(['/covid19']);
    } else if (!this.userService.user && this.userService.local_location && this.userService.local_location.typecode == 'table' && this.table_authentication) {
      this.router.navigate(['/covid19']);
    } else {

      //show the popup that shop is closed
      //if (this.merchantService.merchant.settings.s_closed == true) { this.needsMerchantClosedDetails = true };

      if (localStorage && localStorage.getItem('basket')) {
        this.userService.basket = JSON.parse(localStorage.getItem('basket'));
        this.appHelper.verifyBasketOrderState(this.merchant_slug);
        this.userService.alterUpdateOngoing(false);
        this.checkBasketHasContent();
        this.basketHeight = this.appHelper.getBasketHeight(50);
        this.setBasketFeature();
        this.cd.detectChanges();
      }

    }
  }

  setVenue(domain, force_menu_reload = false) {

    this.merchantService.getMerchantByDomain(domain).subscribe(
      merchant => {
        
        if (this.merchantService.kiosk) { this.setBackgroundImage(); };

        this.merchantService.merchant = this.merchantService.updateMerchant(merchant);
        
        if (this.merchantService.kiosk) this.template = 'template_kiosk';
        else if (this.fol) this.template = 'template2';
        else this.template = this.stylingHelper.getTemplate(this.merchantService.merchant.settings["s_template"]);

        this.menu_only = this.merchantService.hasFeature(this.merchantService.merchant.id, 'f_menu_only');

        if (this.userService.hasUserLoyaltyPoints()) {
          this.loyalty_points = this.userService.getUserLoyaltyPoint(this.merchantService.merchant.id);
        };

        //hardcoding for now
        if (this.merchantService.merchant.id == 1001) {
          this.forceOrderDetails = true;
        }

        if (this.merchantService.merchant.menu && !force_menu_reload) {
          this.handleMerchantData();
          this.initBasket();
          this.cd.detectChanges();
        } else {

          this.appHelper.loadMerchantData(merchant.id).subscribe(
            responseList => {
              this.handleMerchantData();
              this.initBasket();

               
               /*if (this.template == 'template2') {
                this.showDataRequestScreen();
               }*/

              this.cd.detectChanges();
            },
            error => {
              console.log("ERROR!!!!");
              console.log(error);
              //this.router.navigate(['/']);
            });

        }

        this.comment_editable = this.merchantService.hasFeature(this.merchantService.merchant.id, "f_comment_on_orderline");

      },
      error => {
        if (error.status == 401) {
          this.router.navigate(['/private'], { queryParams: { unauthorized: true, domain: domain } });
        } else {
          this.router.navigate(['/']);
        }
      });

  }

  initBasket() {

    if (this.userService.basket) {
      let is_valid = this.appHelper.basketIsValid();
      if (!is_valid && !this.qr_scan && this.userService.user && this.userService.user.virtual_user) {
        this.userService.logoutUser();
      }
      this.cd.detectChanges();
    }

    //we also verify if the basket location corresponds to the local location
    //if not, we reset (the local location should be identical or coming from a new scan)
    if (!this.qr_scan) {
      if (!this.userService.basket || (this.userService.local_location && this.userService.local_location.id != this.userService.basket.order.order_location_id)) {
        this.userService.local_location = null;
        this.location.go(this.merchant_slug + '/menu');
        if (this.userService.basket) { this.userService.destroyBasket(); }
      }
    }

    this.appHelper.initBasket(false).subscribe(
      response => {
        this.payment_methods = this.appHelper.filterPaymentMethodsForKiosk(this.userService.basket.checkoutFields.payment_method);
        if (response == 'new') {
          if (this.merchantService.kiosk && this.userService.user.virtual_user) {
            this.toggleNeedsKioskDetails(true);
            
            if (this.forceOrderDetails) {
              console.log("(B) needsOrderDetails true");
              this.needsOrderDetails = true;
              this.showDataRequestScreen();
            }
          } else if (this.userService.basket.order.scenario != 'table') {
            console.log("(C) needsOrderDetails true");
            this.needsOrderDetails = true;
            this.showDataRequestScreen();
          } else if (this.userService.basket.order.scenario == 'table') this.userService.basket.orderingPossible = true;
        }
        this.verifyOrderDetailsNeeded();
        this.checkBasketHasContent();
        this.basketHeight = this.appHelper.getBasketHeight(50);
        this.cd.detectChanges();
      },
      error => {
        console.log(error);
        if (this.merchantService.kiosk && this.userService.user.virtual_user) { 
          this.toggleNeedsKioskDetails(true);
        } else if (this.userService.basket.order.scenario != 'table') {
          console.log("(D) needsOrderDetails true");
          this.needsOrderDetails = true;
          this.showDataRequestScreen();
        } else if (this.userService.basket.order.scenario == 'table') this.userService.basket.orderingPossible = true;
        this.cd.detectChanges();
      });

    this.setBasketFeature();

  }

  getAdvertisements(res) {
    this.advertisements = res.images;
  }

  changeTaxonFromScroll(taxon) {

    if (!this.targetCategory || this.targetCategory.id == taxon.id) {
      this.selectedCategory = taxon
      this.selectedCategoryIndex = this.mainCategories.findIndex(x => x.id == this.selectedCategory.id);
    };


    const mainCategory = document.getElementById('mainCategory_' + taxon.id);

    if (mainCategory && !this.targetCategory) {
      const target = mainCategory.parentElement.offsetLeft - 30;
      this.categorybar.nativeElement.scrollTo({ left: target, behavior: 'smooth' });
    }

    this.renderer.removeClass(document.body, 'noscroll');

  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  clickTaxon(taxon) {
    this.cd.detectChanges();
    this.targetCategory = taxon;
    this.changeTaxon(taxon);
  }

  changeTaxon(taxon, scrollMenu = true) {

    if (this.merchantService.kiosk) { scrollMenu = false };
    if (this.merchantService.merchant.products.length >= 400 && this.template == 'template2') { scrollMenu = false };
    this.selectedCategory = taxon;
    this.selectedCategoryIndex = this.mainCategories.findIndex(x => x.id == this.selectedCategory.id);

    this.cd.detectChanges();

    const the_category = document.getElementById('category_' + taxon.id);
    const mainCategory = document.getElementById('mainCategory_' + taxon.id);

    if (mainCategory) {
      const target = mainCategory.parentElement.offsetLeft - 30;
      this.categorybar.nativeElement.scrollTo({ left: target, behavior: 'smooth' });
    }

    if (scrollMenu) {

      setTimeout(() => {

        try {
          const bodyRect = document.body.getBoundingClientRect();
          const elemRect = the_category.getBoundingClientRect();
          const offset = elemRect.top - bodyRect.top;

          let adjuster = 220;
          if (this.template == 'template1') adjuster = 195;
          if (this.appHelper.isMobile()) { adjuster = 95; };
          //if (this.appHelper.isMobile() && taxon.id == this.mainCategories[0].id) { adjuster = 140; }

          let scrollBehavior: ScrollBehavior = 'smooth';
          if (this.userService.user && this.userService.user.is_waiter) { scrollBehavior = 'auto'; }

          window.scrollTo({
            top: offset - adjuster,
            behavior: scrollBehavior
          });

        } catch (err) {
          //this is ok, no catch necessary
        }

      }, 100);
    }

    this.renderer.removeClass(document.body, 'noscroll');

  }

  taxonAppears(category) {
    if (this.targetCategory && this.targetCategory.id == category.id) {
      this.targetCategory = null;
    }
    if (category.name != this.selectedCategory.name) this.changeTaxonFromScroll(category);
  }

  onProductSelected(category, product) {

    if (this.menu_only) {

      this.changeTaxon(category, false);
      this.selectedProduct = product;
      this.scrollPos = window.scrollY;
      this.renderer.addClass(document.body, 'noscroll');
      if (this.appHelper.isMobile()) { this.location.go(this.merchant_slug + '/product-detail'); }
      this.cd.detectChanges();

    } else {

      this.verifyOrderDetailsNeeded();

      if (this.needsOrderDetails) {
      } else if (!this.userService.user) {
        console.log("USERSERVICE.USER =>");
        console.log(this.userService.user);
        this.needsUserDetails = true;
      } else if (!this.userService.basket || !this.userService.basket.orderingPossible) {
        console.log("(E) needsOrderDetails true");
        this.needsOrderDetails = true;
      }

      this.changeTaxon(category, false);
      this.selectedProduct = product;
      this.scrollPos = window.scrollY;
      this.renderer.addClass(document.body, 'noscroll');
      if (this.appHelper.isMobile()) { this.location.go(this.merchant_slug + '/product-detail'); }

      this.cd.detectChanges();

    }

  }

  onShowDataRequestScreen() {
    this.onProductCanceled();
    this.showDataRequestScreen();
    this.cd.detectChanges();
  }

  onProductCanceled() {
    this.renderer.removeClass(document.body, 'noscroll');
    if (this.appHelper.isMobile()) {
      //take into the local location....
      if (this.userService.local_location) {
        this.location.go(this.merchant_slug + '/menu?qr_token=' + this.userService.local_location.qr_token);
      } else { this.location.go(this.merchant_slug + '/menu') };
    }
    this.selectedProduct = null;
    this.checkBasketHasContent();
    this.setBasketFeature();
  }

  hideDialog() {
    this.needsOrderDetails = false;
    this.needsUserDetails = false;
    this.forceOrderDetails = false;
    this.data_request_screen_shown = false;
  }

  getUpdate() {
    this.userService.saveBasketInStorage();
    this.userService.alterUpdateOngoing(true);
    this.appHelper.updateCheckoutFieldsV2().subscribe(
      respone => {
        this.userService.alterUpdateOngoing(false);
      },
      error => {
        this.userService.alterUpdateOngoing(false);
      });
  }

  /*onDataRequestCompleted() {
    this.hideDataRequestScreen();
    this.setBasketFeature();
  }*/

  verifyOrderDetailsNeeded() {
    if (this.userService.basket) {
      if (this.forceOrderDetails) {
        console.log("(F) needsOrderDetails true");
        this.needsOrderDetails = true;
      } else if (this.userService.basket.order.scenario == 'delivery' && this.userService.basket.validDeliveryAddress == 0) {
        console.log("(G) needsOrderDetails true");
        this.needsOrderDetails = true;
      } else if (this.userService.basket.order.scenario == 'delivery' && (this.userService.basket.order.order_type == 'personal' && !this.userService.basket.order.dist_location && !this.userService.basket.order.dist_location_id )) {
        console.log("(H) needsOrderDetails true");
        console.log(this.userService.basket.order);
        this.needsOrderDetails = true;
      } else if (this.userService.basket.order.scenario == 'catering' && !this.userService.basket.order.location) {
        console.log("(I) needsOrderDetails true");
        this.needsOrderDetails = true;
      } else if (this.userService.basket.order.scenario == 'roomservice' && !this.userService.basket.order.dist_location_id) {
        console.log("(J) needsOrderDetails true");
        this.needsOrderDetails = true;
      }

      if (this.needsOrderDetails) {
        this.showDataRequestScreen();
      }
    }
  }

  onDataRequestUpdated() {
    this.basketHeight = this.appHelper.getBasketHeight(50);
    this.checkBasketHasContent();
    this.setBasketFeature();
    this.cd.detectChanges();
  }

  setBasketFeature() {
    if (this.merchantService.kiosk) { this.basketFeature = 'checkout-kiosk'; }
    else if (this.userService.basket && this.userService.basket.order && this.userService.basket.order.scenario == 'waiter') { this.basketFeature = 'final'; }
    else if (this.userService.basket && this.userService.basket.order && this.userService.basket.order.scenario == 'table') {
      if (this.merchantService.merchant &&
        !this.merchantService.merchant.settings.s_reference_required &&
        !this.merchantService.merchant.settings.s_comment_required) {

        let payment_methods = this.userService.basket.checkoutFields.payment_method
        let filtered_pm = payment_methods.filter(payment_method => { return payment_method.is_supported && payment_method.is_enabled; })

        if (filtered_pm.length == 1 && filtered_pm[0].value == "CASH"
        && !this.merchantService.hasFeature(this.userService.basket.order.merchant_id,'f_coupons')
        && !this.merchantService.hasFeature(this.userService.basket.order.merchant_id,'f_loyalty_points')) {
          this.userService.basket.order.payment_method = "CASH";
          this.basketFeature = 'final';
        } else {
          this.basketFeature = 'payment';
        }
      } else {
        if (window.innerWidth < 992) { this.basketFeature = 'basket'; }
      }
    }
    else if (window.innerWidth < 992) { this.basketFeature = 'basket'; }
  }

  backgroundClicked() {
    if (this.needsUserDetails || this.needsOrderDetails) { this.hideDialog(); }
    if (this.selectedProduct) { this.onProductCanceled(); }
  }

  moveCategory(position) {
    this.clickTaxon(this.mainCategories[this.selectedCategoryIndex + position]);
  }

  sendNumberOrder() {

    this.userService.basket.order.state = 'sent';

    this.orderService.updateOrder(this.userService.basket.order, true).subscribe(
      response => {
        this.appHelper.setBasketOrder(response, this.merchantService.merchant);
        this.userService.saveBasketInStorage();
        this.router.navigate(['../order-status'], { relativeTo: this.route });
      },
      response => {
        Sentry.captureException(JSON.stringify(response.error));
        if (response.error && response.error.errors && response.error.errors.base) { this.util.openSnackBar(response.error.errors.base[0], this.translate.instant('ok')); }
      });

  }

  clearText() {
    this.searchInitText = '';
    this.searchText = '';

    this.selectedCategoryIndex = 0;
    this.selectedCategory = this.mainCategories[0];

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });

    this.cd.detectChanges();

  }

  onAnimationStateChanged(event) {
    if (event == 'small') this.mobileSearchEnabled = true;
    else this.mobileSearchEnabled = false;
  }

  updateKioskOrder(scenario) {

    this.userService.basket.order.scenario = scenario;
    this.orderService.updateOrder(this.userService.basket.order, true).subscribe(
      response => {
        this.appHelper.setBasketOrder(response, this.merchantService.merchant);
        this.userService.saveBasketInStorage();
      });

    window.scrollTo({
      top: 0,
    });

    this.toggleNeedsKioskDetails(false);
    this.merchantService.kioskTimeLeft = 60;

  }

  selectOneScenario() {

    if (this.merchantService.merchant.settings.s_scenario_takeaway_enabled == undefined) this.merchantService.merchant.settings.s_scenario_takeaway_enabled = false;
    if (this.merchantService.merchant.settings.s_scenario_eatin_enabled == undefined) this.merchantService.merchant.settings.s_scenario_eatin_enabled = false;
    if (this.merchantService.merchant.settings.s_scenario_delivery_enabled == undefined) this.merchantService.merchant.settings.s_scenario_delivery_enabled = false;

    if (this.merchantService.merchant.settings.s_scenario_takeaway_enabled == true &&
      this.merchantService.merchant.settings.s_scenario_eatin_enabled == false &&
      this.merchantService.merchant.settings.s_scenario_delivery_enabled == false
    ) {
      this.updateKioskOrder('takeaway');
    } else if (this.merchantService.merchant.settings.s_scenario_takeaway_enabled == false &&
      this.merchantService.merchant.settings.s_scenario_eatin_enabled == true &&
      this.merchantService.merchant.settings.s_scenario_delivery_enabled == false) {
      this.updateKioskOrder('eatin');
    } else if (this.merchantService.merchant.settings.s_scenario_takeaway_enabled == false &&
      this.merchantService.merchant.settings.s_scenario_eatin_enabled == false &&
      this.merchantService.merchant.settings.s_scenario_delivery_enabled == true) {
      this.updateKioskOrder('delivery');
    }

  }

  setBackgroundImage() {
    let merchant_slug = this.merchant_slug;
    if (this.merchant_slug.includes('.')) { merchant_slug = this.merchant_slug.substring(0, this.merchant_slug.indexOf('.')); }
    if (environment.whitelabel == 'guide') {
      let file_exists = this.appHelper.doesFileExist('assets/backgrounds/' + merchant_slug + '.jpg');
      if (file_exists) {
        this.background_image = 'assets/backgrounds/' + merchant_slug + '.jpg';
      } else {
        this.background_image = 'assets/backgrounds/' + environment.whitelabel + '.jpg';
      }
    } else {
      this.background_image = 'assets/backgrounds/' + environment.whitelabel + '.jpg';
    }
  }
}
