import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Renderer2, ChangeDetectorRef, ChangeDetectionStrategy, HostListener } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { TaxonService } from '../services/taxon.service';
import { MerchantService } from '../services/merchant.service';
import { UserService } from '../services/user.service';
import { OrderService } from '../services/order.service';
import { ProductService } from '../services/product.service';
import { ReservationService } from '../services/reservation.service';
import { AdvertisementService } from '../services/advertisement.service';
import { UtilityService } from '../services/utility.service';
import { PubnubService} from '../services/pubnub.service';

import { User } from '../models/user';

import { AppHelper } from '../helpers/app-helper';
import { StylingHelper } from '../helpers/styling-helper';

import { Product} from '../models/product';
import { Taxon } from '../models/taxon';
import { Merchant } from '../models/merchant';
import { OrderLine } from '../models/order_line';

import { environment } from '../../environments/environment';

import { MerchantPromotionComponent } from '../merchant-promotion/merchant-promotion.component';


import { MatSnackBar } from '@angular/material/snack-bar';

import { Router, ActivatedRoute } from '@angular/router';
import { Observable, forkJoin, pipe } from 'rxjs';
import { map, switchMap, tap, debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { NgxSpinnerService } from 'ngx-spinner';

import { Location, PlatformLocation } from '@angular/common';

import { trigger, state, style, animate, transition } from '@angular/animations';

import smoothscroll from 'smoothscroll-polyfill';

import * as Sentry from '@sentry/browser';

import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
  trigger('fadeInOut', [
    state('void', style({
      opacity: 0
    })),
    transition('void <=> *', animate(350)),
    ]),
  trigger('fadeInOut2', [
    state('invisible', style({
      opacity: 0
    })),
    state('visible', style({
      opacity: 1
    })),
    transition('visible <=> *', animate(350)),
    ]),
  trigger('fadeIn', [
    state('void', style({
      opacity: 0
    })),
    transition('void => *', animate(350)),
    ]),
  trigger('slideUp', [
    state('void', style({
      transform: 'translateY(150%)'
    })),
    transition('void <=> *', animate('0.5s ease-in-out')),
    ])
  ]
})

export class ReservationComponent implements OnInit, OnDestroy {

  private merchant_slug;
  private merchant_domain;

  //reservation
  public reservation: any;

  public id: number;
  public customerToken: string;
  public bearerToken: string;
  public food_requested: number = 0;
  public product_id: number = 0;

  constructor(
  	public merchantService: MerchantService,
    public userService: UserService,
    public orderService: OrderService,
    public productService: ProductService,
    public reservationService: ReservationService,
    private taxonService: TaxonService,
    private pubnubService: PubnubService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    public appHelper: AppHelper,
    private cd: ChangeDetectorRef,
    ) {

    this.route.params.subscribe(
      params => {
        this.merchant_slug = params['merchant_slug'];
        this.merchant_domain = params['merchant_slug'];
        if (this.merchant_domain.indexOf('one2three') == -1) { this.merchant_domain += '.one2three.be'; }
      });

    this.route.queryParamMap.subscribe(params => {

      //http://localhost:4200/demo-frituur/reservation?id=21&customerToken=5cc1beff99ed693cf8e07bda5ae5e31419288455627db98bb55d93eef609dbba&bearerToken=eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoxMDAxNSwidXNlcl9uYW1lIjoicGFzY2FsLnBlZXRlcnNAZW50cnVzdC1pdC5iZSIsInVzZXJfdHlwZSI6ImN1c3RvbWVyIiwiZXhwIjoxNjU0MjgxMTY4fQ.npVU9qIFAcWIssjs0MyiOHC2Cr7fK532L9wqdbp5PoQ&food_requested=1&product_id=1

      this.id = parseInt(params.get('id'));
      this.customerToken = params.get('customerToken');
      this.bearerToken = params.get('bearerToken');
      //this.food_requested = parseInt(params.get('food_requested'));
      this.food_requested = 0;
      this.product_id = parseInt(params.get('product_id'));

      if (this.id) { 
        this.userService.destroyBasket();

        this.loadPromises(this.merchant_domain, this.id).subscribe(
          responseList => {
            console.log(responseList);
            this.merchantService.merchant = this.merchantService.updateMerchant(responseList[0]);
            this.reservation = responseList[1];
            this.userService.newBasket(this.merchantService.merchant, false);
            this.userService.basket.order.scenario = 'table';
            this.userService.basket.order.dist_timing_target = this.reservation.startDate;

            console.log("FOUND A TABLE ID?");
            console.log(this.reservation.tableId);

            //we have all the info, now search for the local location
            //and set the order constraints
            this.loadLocalLocationAndOrderConstraintsPromises(this.merchantService.merchant.id, this.reservation.tableId).subscribe(
              responseList => {
                 this.appHelper.setOrderConstraints(responseList[1].order_constraints);
                 let local_location = responseList[0];
                 console.log("LOCAL LOCATION =>");
                  console.log(local_location);

                  this.userService.basket.order.reservation_id = this.reservation.id;
                  this.userService.basket.order.reservations_token = this.bearerToken;
                  this.userService.basket.order.dist_timing = 'timed';
                  this.userService.basket.order.dist_timing_target =  this.reservation.startDate;
                  this.userService.basket.order.dist_location_id = local_location.id;
                  this.userService.basket.order.dist_location_typecode  = local_location.typecode;

                  //the reservation fee

                  if (this.product_id == 1) {
                    let orderLine = new OrderLine(local_location.reservation_fee);
                    this.userService.addOrderLine(orderLine, 1.00, false);
                  }

                  this.makeDecision();

              });

          });
      }
    });
  }

  makeDecision() {

    this.orderService.updateOrder(this.userService.basket.order, false).subscribe(
      response => {
        this.appHelper.setBasketOrder(response, this.merchantService.merchant);

        this.appHelper.updateCheckoutFieldsV2().subscribe(
          respone => {
            this.userService.saveBasketInStorage();
              if (this.food_requested != 1) {
                this.router.navigate(['../payment'], { relativeTo: this.route });
              } else {
                this.router.navigate(['../menu'], { relativeTo: this.route });
              }
          },
          error => {
            console.log(error);
          }
        );

      },
      response => {
        Sentry.captureException(JSON.stringify(response.error));
      }
      );
  }


  loadPromises(domain, reservation_id): Observable<any[]> {
    
    let merchantPromise = this.merchantService.getMerchantByDomain(domain);
    let reservationPromise = this.reservationService.getReservation(this.bearerToken,reservation_id);
    return forkJoin([merchantPromise, reservationPromise]);
  }

  loadLocalLocationAndOrderConstraintsPromises(merchant_id, table_id): Observable<any[]> {
       let localLocationPromise = this.merchantService.getLocalLocationByQRCode(table_id); 
       let orderConstraintsPromise = this.orderService.getOrderConstraints(merchant_id);
       return forkJoin([localLocationPromise, orderConstraintsPromise]);
  }


  ngOnInit() {
  }

  ngOnDestroy() {
  }

}
