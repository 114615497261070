import { EventEmitter, Injectable } from '@angular/core';

import { forkJoin, Observable } from 'rxjs';

import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { MerchantService } from '../services/merchant.service';
import { OrderService } from '../services/order.service';
import { ProductService } from '../services/product.service';
import { TaxonService } from '../services/taxon.service';
import { UserService } from '../services/user.service';
import { UtilityService } from '../services/utility.service';

import { environment } from '../../environments/environment';

import { AdvertisementService } from '../services/advertisement.service';

import * as Sentry from '@sentry/browser';

declare var device: any;

@Injectable()
export class AppHelper {

	private timingChosen = false;
	private timing_target_exists = false;
	private previous_timing_target = null;
	private cateringLocationChosen = false;
	public listOfAllCountries = [];
	public listOfAllTimezones = [];

	public timeChosenEmitter = new EventEmitter();

	constructor(
		private userService: UserService,
		private merchantService: MerchantService,
		private orderService: OrderService,
		private taxonService: TaxonService,
		private productService: ProductService,
		private util: UtilityService,
		private translate: TranslateService,
		private advertisementService: AdvertisementService,
		private router: Router
	) { }

	loadMerchantDataPromises(merchant_id): Observable<any[]> {
		const optionsPromise = this.productService.getOptions(merchant_id);
		const partSetsPromise = this.productService.getPartSets(merchant_id);
		console.log("MERCHANTSERVICE ===>");
		console.log(this.merchantService);
		console.log(this.merchantService.merchant);
		console.log(this.orderService);
		const localLocationsPromise = this.merchantService.getLocalLocations();
		const orderConstraintsPromise = this.orderService.getOrderConstraints(merchant_id);

		const taxonsPromise = this.taxonService.getTaxons(merchant_id);
		const productsPromise = this.productService.getProductsForMerchant(merchant_id);
		const productsWithPriceModifierPromise = this.productService.getProductsWithPriceModifier(merchant_id);

		return forkJoin([optionsPromise, partSetsPromise, localLocationsPromise, orderConstraintsPromise, taxonsPromise, productsPromise, productsWithPriceModifierPromise]);
	}

	loadMerchantData(merchant_id) {

		const loader = new Observable(observer => {

			this.loadMerchantDataPromises(merchant_id).subscribe(
				responseList => {

					const menu = this.loadMenu(responseList[4].taxons, responseList[5].products, responseList[6].price_with_modifiers);
					this.setMenu(menu);
					this.getOptions(responseList[0]);
					this.getPartSets(responseList[1]);
					//this.getMerchantStats(responseList[2]);
					this.getLocalLocations(responseList[2]);
					this.setOrderConstraints(responseList[3].order_constraints);

					observer.next(responseList);

				});
		});

		return loader;

	}

	getMerchantStats(res) {
		const merchant_stats = res.merchant_stats;
		for (const merchant_stat of merchant_stats) {
			if (this.merchantService.merchant.id == merchant_stat.merchant_id) {
				this.merchantService.merchant.waiting_time = merchant_stat.waiting_time;
				this.merchantService.merchant.waiting = merchant_stat.waiting;
				this.merchantService.merchant.daily_order_count = merchant_stat.daily_order_count;
				this.merchantService.merchant.daily_order_count_week_ago = merchant_stat.daily_order_count_week_ago;
				break;
			}
		}
	}

	setMenu(resMenu) {
		this.merchantService.merchant.menu = resMenu['menu'];
		this.merchantService.merchant.mainCategories = resMenu['mainCategories'];
		this.merchantService.merchant.categories = resMenu['categories'];
		this.merchantService.merchant.products = resMenu['products'];
	}

	getLocalLocations(localLocations) {
		this.merchantService.merchant.local_locations = localLocations.local_locations.sort(function (a, b) {
			return a.name.localeCompare(b.name);
		});
	}

	getOptions(res) {
		this.productService.updateStoredOptions(res.options);
	}

	setOrderConstraints(order_constraints) {
		this.merchantService.merchant.order_constraints = order_constraints;
	}

	getPartSets(res) {

		for (const part_set of res.part_sets) {

			const filtered = [];

			for (const part_set_containee_taxon of part_set.part_set_containee_taxons) {


				filtered.push.apply(filtered, this.merchantService.merchant.products.filter(x => {
					let found = false;
					for (const product_taxon of x.product_taxons) {
						if (product_taxon.taxon_id == part_set_containee_taxon.taxon_id) {
							found = true;
							break;
						}
					}
					return found;
				}));
			}

			part_set.products = filtered;
		}

		if (res.part_sets) {
			this.productService.updateStoredPartSets(res.part_sets);
		}
	}

	prepareOrder() {

		if (this.userService.basket && this.userService.basket.order) {

			this.prepareScenario();
			this.prepareTiming();

			if (!this.merchantService.kiosk && !this.merchantService.pos) {
				if (this.timingChosen && !this.cateringLocationChosen && this.userService.basket.order.order_type == 'personal') {
					if (!this.timing_target_exists) {
						this.util.openSnackBar(this.translate.instant('defaults.timing'), this.translate.instant('ok'), 10000);
					} else {
						this.updateCheckoutFieldsV2().subscribe(response => { });
						this.util.openSnackBar(this.translate.instant('back_to_checkout'), this.translate.instant('ok'), -1);
					}
				} else if (this.timingChosen && this.cateringLocationChosen) {
					this.util.openSnackBar(this.translate.instant('defaults.timing_catering'), this.translate.instant('ok'), 10000);
				} else if (this.cateringLocationChosen) {
					this.util.openSnackBar(this.translate.instant('defaults.catering'), this.translate.instant('ok'), 10000);
				}
			}

			this.timingChosen = false;
			this.timing_target_exists = false;
			this.cateringLocationChosen = false;


			try {
				this.merchantService.filterMenuByConstraints();
			} catch (err) {
				//console.log(err);
			}

		}

	}

	prepareScenario() {

		//always consumer, except for waiter scenario's
		this.userService.basket.order.intaker = 'consumer';

		// we unset the payment method, to make sure that when we go back in
		// the checkout screens, we do not get blocked
		// kiosk = 1 checkout screen, payment method can stay as is
		if (!this.merchantService.pos && !this.userService.basket.order.kiosk_id && (!this.userService.basket.order.payment_state || this.userService.basket.order.payment_state != 'paid')) { this.userService.basket.order.payment_method = undefined; }

		//TEMP FIX TEMP FIX TEMP FIX
		if (!this.merchantService.pos && this.userService.basket.checkoutFields && this.userService.basket.checkoutFields.payment_method.length == 1) {
			const fixed_payment_method = this.userService.basket.checkoutFields.payment_method[0];
			if (fixed_payment_method.value == "CASH" && fixed_payment_method.is_supported && fixed_payment_method.is_enabled) {
				this.userService.basket.order.payment_method = "CASH";
			}
		}

		if (this.userService.basket.order.scenario == 'takeaway') {

			this.prepareTakeawayLocation();

		} else if (this.userService.basket.order.scenario == 'delivery') {

			this.prepareDeliveryLocation();

			if (this.userService.basket.order.order_type == 'group') { this.userService.basket.validDeliveryAddress = 1 };

			if (this.userService.basket.validDeliveryAddress == -1 && this.userService.address) {

				console.log("are we here?");

				this.merchantService.valid_delivery_address(this.userService.basket.order.merchant_id, { address: this.userService.address }).subscribe(
					response => {
						if (response == true) { this.userService.setValidDeliveryAddress(1); }
						else { this.userService.setValidDeliveryAddress(0); }
						this.userService.saveBasketInStorage();
					},
					error => {
						let sentryError = '';
						if (this.userService && this.userService.address) { sentryError = JSON.stringify(this.userService.address) + '.'; }
						sentryError += error;
						Sentry.captureException(JSON.stringify(sentryError));
						console.log(sentryError);
					});
			}

		} else if (this.userService.basket.order.scenario == 'catering') {

			this.userService.basket.order.dist_location_typecode = 'remote';

			// if (!this.userService.basket.order.location) {
			// this.userService.basket.order.location = this.userService.basket.checkoutFields.location[0].value;
			// this.cateringLocationChosen = true;
			// }

			const address = {
				line1: '',
				line2: '',
				city: '',
				country_alpha2: '',
				zipcode: '',
				location: this.userService.basket.order.location
			};

			this.userService.basket.order.dist_location = { address };

			//always CASH for the waiter scenario		
		} else if (this.userService.basket.order.scenario == 'waiter') {
			this.userService.basket.order.payment_method = "CASH";
			this.userService.basket.order.intaker = 'waiter';
		}

	}

	preparePaymentMethod(): boolean {

		let has_changed = false;

		// verify if the current payment method is valid
		let payment_methods = this.userService.basket.checkoutFields.payment_method;
		payment_methods = this.filterPaymentMethods(this.merchantService.kiosk, this.userService.basket.order, payment_methods);

		let found = false;
		if (this.userService.basket.order.payment_method) {
			for (const payment_method of payment_methods) {
				if (payment_method.value == this.userService.basket.order.payment_method) {
					found = true;
					break;
				}
			}
		}

		// if none is ok or selected, let's set one
		if (!found || !this.userService.basket.order.payment_method) {

			for (const payment_method of payment_methods) {
				if (payment_method.is_enabled) {
					this.userService.basket.order.payment_method = payment_method.value;
					has_changed = true;
					break;
				}
			}
		}

		return has_changed;

	}

	getMerchantPrimaryColor(isPos = false) {
		if (this.merchantService.merchant.style_overrides &&
			this.merchantService.merchant.style_overrides[0] &&
			this.merchantService.merchant.style_overrides[0].mobile_primary_color
		) {
			return this.merchantService.merchant.style_overrides[0].mobile_primary_color;
		} else if (isPos) {
			return '#c0041d';
		}
	}

	prepareTiming() {

		let orderingPossible = false;

		// is the selected timing valid for a timed order?
		if (this.userService.basket.order.dist_timing == 'timed') {
			let possible_slot = false;
			for (const dist_timing_target of this.userService.basket.checkoutFields.dist_timing_target) {
				for (const slot of dist_timing_target.slots) {
					if (slot.value == this.userService.basket.order.dist_timing_target && (slot.is_enabled || slot.price_reason)) {
						possible_slot = true;
						orderingPossible = true;
					}
				}
			}

			if (!possible_slot) {
				if (this.userService.basket.order.dist_timing_target) {
					this.timing_target_exists = true;
					this.previous_timing_target = this.userService.basket.order.dist_timing_target;
				}
				this.userService.basket.order.dist_timing_target = null;
				this.userService.basket.order.dist_timing_target_index = -1;
			}
		}

		// is asap and/or timed possible
		let asap_is_candidate = false;
		let timed_is_candidate = false;

		for (const dist_timing of this.userService.basket.checkoutFields.dist_timing) {
			if (dist_timing.is_supported) {
				if (dist_timing.value == 'asap') {

					let filtered_constraints = null;

					// first filtering
					try {
						filtered_constraints = this.merchantService.merchant.order_constraints.filter(constraint => {

							return constraint.category.length == 0 &&
								constraint.catering_locations.length == 0 &&
								!constraint.current_week_scheme &&
								!constraint.cut_off &&
								!constraint.cut_off_wday &&
								constraint.dist_location_types.length == 0 &&
								constraint.dist_timings.includes('asap') &&
								constraint.enabled == true &&
								constraint.group_types.length == 0 &&
								constraint.order_location_types.length == 0 &&
								constraint.payment_methods.length == 0 &&
								constraint.price_max == '0.0' &&
								constraint.price_min == '0.0' &&
								constraint.scenarios.includes(this.userService.basket.order.scenario);
						});

					} catch (err) {
						console.log(err);
					}

					// WORK IN PROGRESS!!!!
					// filter based on scenario
					/*try { filtered_constraints = filtered_constraints.filter(constraint => {
						let applicable = false;
						if (constraint.scenarios.length == 0) { applicable = true; }
						else if (constraint.scenarios.contains(this.userService.basket.order.scenario)) { applicable = true; };
						return applicable;
					});

					} catch(err) {
						console.log(err);
					}

					console.log("FILTERING AFTER SCENARIO'S ==>");
					console.log(filtered_constraints);

					//filter based on groups
					try { filtered_constraints = filtered_constraints.filter(constraint => {
						let applicable = false;
						if (constraint.group_types.length == 0) { applicable = true; }
						console.log(this.userService.basket.order);
						console.log(this.userService.basket.order.group);
						return applicable;
					});

					} catch(err) {
						console.log(err);
					}

					console.log("FILTERING AFTER GROUP TYPES ==>");
					console.log(filtered_constraints);
					*/

					if (!filtered_constraints || filtered_constraints.length == 0) {
						asap_is_candidate = true;
					}

				}

				if (dist_timing.value == 'asap' && !this.merchantService.merchant.settings.s_closed && (dist_timing.is_enabled || dist_timing.price_reason)) {
					if (!this.userService.basket.order.dist_timing) { this.userService.basket.order.dist_timing = 'asap'; }
					orderingPossible = true;
				} else if (dist_timing.value == 'timed') {
					timed_is_candidate = true;
					if (!this.userService.basket.order.dist_timing) { this.userService.basket.order.dist_timing = 'timed'; }
					// if it is a timed, do we already have a dist_timing_target?
					let timingTargetFound = false;
					if (this.userService.basket.order.dist_timing == 'timed' && !this.userService.basket.order.dist_timing_target) {
						for (const dist_timing_target of this.userService.basket.checkoutFields.dist_timing_target) {
							for (const slot of dist_timing_target.slots) {
								if ((slot.is_enabled || slot.price_reason) && !timingTargetFound) {
									if (this.previous_timing_target == null || (this.previous_timing_target < slot.value)) {
										this.userService.basket.order.dist_timing_target = slot.value;
										this.timingChosen = true;
										this.timeChosenEmitter.emit();
										timingTargetFound = true;
										orderingPossible = true;
										break;
									}
								}
							}
						}
					}
					// we could not find any possible dist_timing_target
					// if asap is supported, let's put at asap
					if (!this.userService.basket.order.dist_timing_target && !timingTargetFound && asap_is_candidate) {
						this.userService.basket.order.dist_timing = 'asap';
						// we need to an extra checkout of the fields
						this.userService.saveBasketInStorage();
						this.updateCheckoutFieldsV2().subscribe(response => { });
					}
				}
			}
		}

		if (!this.userService.basket.orderingPossible) {
			this.userService.basket.orderingPossible = orderingPossible;
		}

		/*CANDIDATE FOR REFACTORING, SAME CODE IN TIMING COMPONENT INIT*/
		if (this.userService.basket.order.dist_timing == 'timed' && this.userService.basket.order.dist_timing_target) {
			let index = 0;
			for (const dist_timing_target of this.userService.basket.checkoutFields.dist_timing_target) {
				for (const slot of dist_timing_target.slots) {
					if (slot.value == this.userService.basket.order.dist_timing_target) {
						this.userService.basket.order.dist_timing_target_index = index;
						break;
					}
				}
				index++;
			}
		} else if (this.userService.basket.order.dist_timing == 'asap' && !asap_is_candidate && timed_is_candidate) {
			this.userService.basket.order.dist_timing = 'timed';
			this.userService.saveBasketInStorage();
			this.updateCheckoutFieldsV2().subscribe(response => { });
		}
	}

	prepareTakeawayLocation() {

		let counters = null;
		if (this.merchantService.merchant.local_locations) counters = this.merchantService.merchant.local_locations.filter(location => location.typecode == 'counter');

		if (this.userService.basket.order.dist_location_id == undefined && counters && counters.length == 1) {
			this.userService.basket.order.dist_location_id = counters[0].id;
			this.userService.basket.order.dist_location_name = counters[0].name;
			this.userService.basket.order.dist_location_typecode = counters[0].typecode;
		}

	}

	prepareDeliveryLocation() {

		this.userService.basket.order.dist_location_typecode = 'remote';

		let address_set = false;

		if (!this.userService.basket.order.dist_location &&
			this.userService.basket.order.order_type == 'group') {
			const the_group = this.userService.user.membered_groups.find(group => {
				return group.id == this.userService.basket.order.group_id;
			});
			if (the_group.address) {

				const address_submit = JSON.parse(JSON.stringify(the_group.address));
				address_submit.id = null;

				if (address_submit.line1 != undefined) {

					this.userService.basket.order.dist_location = { address: address_submit };
					this.userService.basket.order.dist_location_name = this.getAddressFormatted(the_group.address);
					this.userService.address = the_group.address;
					address_set = true;
				}

			}
		}

		if (!address_set && (!this.userService.basket.order.dist_location || this.userService.basket.order.dist_location == undefined) && this.userService.address) {

			const address_submit = JSON.parse(JSON.stringify(this.userService.address));
			address_submit.line1 += ' ' + address_submit.street_number;

			if (address_submit.line1 != undefined && address_submit.street_number != undefined) {
				this.userService.basket.order.dist_location = { address: address_submit };
				this.userService.basket.order.dist_location_name = this.getAddressFormatted(this.userService.address);
				this.userService.setValidDeliveryAddress(-1);
			} else {
				// to bypass group addresses which do not have our required format
				this.userService.address = undefined;
			}

		}

	}

	toggleFav(merchant, event) {
		merchant.favorite = !merchant.favorite;
		if (merchant.favorite) { this.userService.hasFavorites = true; }
		this.merchantService.favorite(merchant.favorite, merchant.id).subscribe(
			response => {
			},
			error => {
				console.log(error);
			}
		);
		event.stopPropagation();
	}

	updateCheckoutFields() {
		this.getCheckoutFieldsForOrder().subscribe(
			response => {
				this.prepareOrder();
			},
			error => {
				console.log(error);
			});
	}

	updateCheckoutFieldsV2(): any {

		const updateCheckout = new Observable(observer => {

			this.getCheckoutFieldsForOrder().subscribe(
				response => {
					this.prepareOrder();
					this.getCheckoutFieldsForOrder().subscribe(response => {
						observer.next(response);
					},
						error => {
							console.log(error);
							observer.next(response);
						});
				},
				error => {
					console.log(error);
					observer.error(error);
				});
		});

		return updateCheckout;

	}

	initSentryUser(user) {
		Sentry.configureScope(function (scope) {
			scope.setUser({ 'email': user.email });
		});
	}

	getCheckoutFieldsForOrder(): any {

		const initBasket = new Observable(observer => {

			if (!this.userService.basket) return;
			if (!this.userService.basket.order) return;
			if (!this.userService.basket.order.scenario) return;

			this.orderService.getCheckoutFields(this.userService.basket.order).subscribe(
				response => {

					if (this.userService.basket) {

						if (this.userService.basket && this.userService.basket.order) {
							if (response.order.dist_timing && (!this.userService.basket.order.dist_timing_target || this.userService.basket.order.dist_timing == 'asap')) {
								this.userService.basket.order.dist_timing_target = response.order.dist_timing_target;
								if (this.userService.basket.order.dist_timing == 'timed') {
									this.timeChosenEmitter.emit();
								}
							}

							if (!this.merchantService.pos) {
								this.userService.basket.order.price = response.order.price;
								this.userService.basket.order.price_before_modifiers = response.order.price_before_modifiers;
								this.calculateOrderTotalPrice();
							}
						}


						this.userService.setCheckoutFields(response.field_values);
						this.userService.basket.advisories = this.listAdvisories();

						// is asap and/or timed possible
						let orderingPossible = false;
						for (const dist_timing of response.field_values.dist_timing) {
							if (dist_timing.is_supported && (dist_timing.is_enabled || dist_timing.price_reason)) {
								orderingPossible = true;
							}
						}
						if (!this.userService.basket.orderingPossible) { this.userService.basket.orderingPossible = orderingPossible; }
					}

					observer.next(response);
				},
				error => {
					console.log(error);
					observer.error(error);
				});
		});

		return initBasket;
	}

	basketIsValid(order_type="personal"): boolean {
		const updated_at = new Date(this.userService.basket.order.updated_at);
		const now = new Date();
		const difference = (now.getTime() - updated_at.getTime()) / 1000 / 60;
		if (difference > 30 || (this.userService.basket.order.order_type !== order_type)  || (!this.merchantService.kiosk && this.userService.user && this.userService.user.virtual_user && this.userService.basket.order.scenario != "table")) {
			this.userService.destroyBasket();
			return false;
		} else { return true; }
	}

	initBasket(group_order_allowed = false, order_type = "normal"): any {

		let result: string;

		const initBasket = new Observable(observer => {

			// erase a user his/her basket after 30 minutes
			if (this.userService.basket && this.userService.basket.order) {
				this.basketIsValid();
			}

			if (this.userService.basket &&
				this.userService.basket.order &&
				this.userService.basket.order.merchant_id == this.merchantService.merchant.id &&
				(this.userService.scenario == 'all' || this.userService.scenario == this.userService.basket.order.scenario || this.userService.basket.order.group_order) &&
				(group_order_allowed || !this.userService.basket.order.group_order) &&
				(!this.userService.local_location || (this.userService.basket.order.order_location_id == this.userService.local_location.id))
			) {
				result = 'existing';
				this.setBasketOrder(this.userService.basket.order, this.merchantService.merchant);

				if (this.userService.basket.order.dist_location && this.userService.basket.order.dist_location_typecode == "table") {
					this.userService.local_location = this.userService.basket.order.dist_location;
					this.userService.local_location.typecode = this.userService.basket.order.dist_location_typecode;
				}

			} else if (this.userService.user) {

				this.userService.newBasket(this.merchantService.merchant, this.merchantService.kiosk, this.merchantService.pos);

				if (order_type === "refund") {
					this.selectOrderType("refund");

				// switch to a group order when user is a member of a group
				} else if (this.hasGroupFunctionality()) {

					if (this.userService.group) {

						this.userService.basket.order.scenario = null;
						this.userService.basket.order.dist_location_typecode = null;
						this.userService.removeDistLocation();
						this.setOrderGroupInformation(this.userService.group);
						this.selectOrderType('group');
					} else {

						const membered_groups = this.userService.user.membered_groups.filter(group => {
							return group.merchant_id == this.merchantService.merchant.id && group.approval_status == 'approved';
						});

						if (membered_groups.length > 0) {
							//why is the scenario null; it should follow the requested scenario
							this.userService.basket.order.scenario = null;
							if (this.userService.scenario && this.userService.scenario != 'all') { this.userService.basket.order.scenario = this.userService.scenario; }
							this.userService.basket.order.dist_location_typecode = null;
							this.userService.removeDistLocation();
							this.setOrderGroupInformation(membered_groups[0]);
							this.selectOrderType('group');
						}

					}

				}

				if (this.userService.order_line_pending && this.merchantService.merchant) {
	                let pending_product = this.merchantService.merchant.products.find(product => { return product.id === this.userService.order_line_pending.product_id });
	                let pending_taxon = this.merchantService.merchant.categories.find(taxon => { return taxon.id == this.userService.order_line_pending.taxon_id });
	                console.log(pending_product);
	                if (pending_product) { 
	                  console.log("WE WILL NOW ADD THIS ORDER LINE!");
	                  this.userService.addOrderLine(this.userService.order_line_pending, null, true, false);
	                  this.userService.order_line_pending = null;
	                };
              	};


				result = 'new';
			}

			if (this.userService.user && this.userService.basket) {

				if (!this.userService.basket.order.scenario) {

					this.orderService.getDefaultValues(this.userService.basket.order)
						.subscribe(
							response => {
								this.userService.setCheckoutFields(response.field_values);
								this.userService.basket.advisories = this.listAdvisories();
								this.userService.basket.order = response.order;
								if (this.userService.basket.order.scenario) { this.userService.scenario = this.userService.basket.order.scenario; }

								// POSSIBLE BUGFIX
								if (!this.userService.basket.order.scenario) { }

								if (this.userService.basket.order.dist_timing == 'timed') {
									this.timingChosen = true;
									this.timeChosenEmitter.emit();
								}
								this.prepareOrder();
								this.updateCheckoutFields();
								observer.next(result);
							},
							error => {
								this.userService.basket.orderingPossible = false;
								this.merchantService.filterMenuByConstraints();
								observer.error(error);
							});

				} else {
					this.getCheckoutFieldsForOrder().subscribe(
						respone => {
							this.prepareOrder();
							this.updateCheckoutFields();
							observer.next(result);
						},
						error => {
							console.log(error);
							observer.error(error);
						});
				}

			}

		});

		return initBasket;
	}

	public hasGroupFunctionality() {
		let toReturn = false;
		if (this.userService.user && this.merchantService.hasFeature(this.merchantService.merchant.id, 'f_group_functionality')) {
			if (this.userService.user.membered_groups.length > 0 || this.userService.group) { toReturn = true; }
		}
		return toReturn;
	}

	public setBasketOrder(order, merchant) {

		this.userService.basket.order = order;

		if (order.scenario == 'delivery' && order.dist_location_id && this.userService.useCurrentLocation) {
			this.userService.useCurrentLocation = false;
			if (order.dist_location) {
				this.userService.address = order.dist_location.address;
				this.userService.address.street_number = '';
			}
		}

		this.formatOrderLines(order, merchant);
		this.calculateOrderTotalPrice();
		this.userService.saveBasketInStorage();
		//this.timeChosenEmitter.emit();
	}

	public calculateOrderTotalPrice() {

		// calculate the price of the order
		this.userService.basket.order.price = +this.userService.basket.order.price_before_modifiers;
		// next line for safety
		this.userService.basket.order.price_after_coupons = this.userService.basket.order.price;

		//if (this.userService.basket.order.order_type == 'personal') {

		//price adaption because of modifiers
		if (this.userService.basket.order.order_order_price_modifiers) {
			for (const order_price_modifier of this.userService.basket.order.order_order_price_modifiers) {
				this.userService.basket.order.price += +order_price_modifier.price_adapt;
			}
		}

		this.userService.basket.order.price_after_coupons = this.userService.basket.order.price;

		//price adapation because of coupons
		for (const coupon of this.userService.basket.coupons) {
			if (this.userService.basket.order.price_after_coupons > +coupon.value_remaining) {
				this.userService.basket.order.price_after_coupons -= +coupon.value_remaining;
				coupon.new_value_remaining = 0;
			} else {
				coupon.new_value_remaining = +coupon.value_remaining - this.userService.basket.order.price_after_coupons;
				this.userService.basket.order.price_after_coupons = 0;
			}
		}
		//}
	}


	public formatOrderLines(order, merchant) {

		for (const order_line of order.order_lines) {

			order_line.price_shown = true;

			//which unit is selected?
			for (const order_option of order_line.order_options) {
				for (const order_option_value of order_option.order_option_values) {
					if (order_option_value.price_shown == false) {
						order_line.price_shown = order_option_value.price_shown;
					}
				}
			}
		}

		if (merchant && merchant.products) {

			for (const order_line of order.order_lines) {

				const category = merchant.categories.find(x => x.id == order_line.taxon_id);
				
				if (category) {
					order_line.taxon_lft = category.lft;
					order_line.taxon_name = category.name;

					const product = merchant.products.find(x => x.id == order_line.product_id);
					if (product) {
						const product_taxon = product.product_taxons.find(x => x.taxon_id == order_line.taxon_id);
						if (product_taxon) { order_line.ordering = product_taxon.ordering; };
					}
				}

				/*const product = merchant.products.find(x => x.id == order_line.product_id);
				if (product) {
	
					for (const product_taxon of product.product_taxons) {
	
						const category = merchant.categories.find(x => x.id == product_taxon.taxon_id);
	
						if (!order_line.taxon_lft || (category && order_line.taxon_lft > category.lft)) {
							if (category) {
								order_line.taxon_id = product_taxon.taxon_id;
								order_line.taxon_lft = category.lft;
								order_line.taxon_name = category.name;
								order_line.ordering = product_taxon.ordering;
							}
						}
					}
	
				}*/
			}

		}

		//only sort if necessary data is present
		if (order.order_lines.length > 0 && order.order_lines[0].taxon_lft) {
			order.order_lines = order.order_lines.sort(function (a, b) {
				if (+a.taxon_lft < +b.taxon_lft) { return -1; }
				else if (+a.taxon_lft > +b.taxon_lft) { return +1; }
				else if (+a.product_id < +b.product_id) { return -1; }
				else if (+a.product_id > +b.product_id) { return +1; }
				else { return +a.id - +b.id; }
			});

		}

		let prev_taxon = -1;
		let ordering = 0;
		for (const order_line of order.order_lines) {
			if (order_line.taxon_id != prev_taxon) {
				ordering = 0;
				prev_taxon = order_line.taxon_id;
			}
			order_line.ordering = ordering;
			if (order_line.amount !== 0) { ordering += 1; };
		}

		order.price_shown = true;

		//no price shown if one of the order lines is slices
		for (const order_line of order.order_lines) {

			//which unit is selected?
			for (const order_option of order_line.order_options) {
				for (const order_option_value of order_option.order_option_values) {

					if (order.price_shown == true && order_option_value.price_shown == false) {
						order.price_shown = order_option_value.price_shown;
					};

				}
			}
		}

	}

	loadMenu(taxons, products, products_with_price_modifiers) {

		const menu = [];
		const mainCategories = [];

		for (const taxon of taxons) {

			taxon.products = [];

			if (taxon.parent_id == null && taxon.is_in_menu) {
				taxon.is_enabled = true;
				taxon.subcategories = [];
				menu.push(taxon);
			} else if (taxon.parent_id != null) {
				for (const item of menu) {
					if (item.id == taxon.parent_id) {
						taxon.is_enabled = true;
						taxon.subcategories = [];
						item.subcategories.push(taxon);
					}
					for (const subitem of item.subcategories) {
						if (subitem.id == taxon.parent_id) {
							taxon.is_enabled = true;
							taxon.subcategories = [];
							subitem.subcategories.push(taxon);
						}
					}
				}
			}
		}

		this.updateProducts(products, products_with_price_modifiers);

		//need to add the products to the categories
		for (const product of products) {
			for (const product_taxon of product.product_taxons) {
				for (const taxon of taxons) {
					if (taxon.id == product_taxon.taxon_id) {
						taxon.products.push(product);
					}
				}
			}
		}

		// detect empty depth2 categories
		for (const depth0 of menu) {
			for (const depth1 of depth0.subcategories) {
				for (const depth2 of depth1.subcategories) {
					if (depth2.products.length == 0) { depth2.empty = true; } else { mainCategories.push(depth2); }
				}
			}
		}

		products.forEach(product => {


			if (this.merchantService.merchant.fol_uplift > 0 && !this.merchantService.merchant.tags.includes(environment.whitelabel + '_nouplift')) {
				
				product.price *= 1+(+this.merchantService.merchant.fol_uplift);
				product.price_with_modifiers *= 1+(+this.merchantService.merchant.fol_uplift);

				//round the product price down
				product.price -= (product.price + 0.001) % 0.05;
				product.price_with_modifiers -= (product.price_with_modifiers + 0.001) % 0.05;

			}

		});


		const returnValue = {};
		returnValue['menu'] = menu;
		returnValue['mainCategories'] = mainCategories;
		returnValue['categories'] = taxons;
		returnValue['products'] = products;

		return returnValue;

	}

	updateProducts(origProducts, updateProducts) {

		for (const updateProduct of updateProducts) {
			for (const origProduct of origProducts) {
				if (origProduct.id == updateProduct.product_id) {
					origProduct.price_with_modifiers = updateProduct.price_with_modifiers;
					if (parseFloat(origProduct.price) > parseFloat(origProduct.price_with_modifiers)) { 
						origProduct.in_promo = true; 
					}
				}
			}
		}
	}

	isMobile() {
		if (window.screen.width < 768) { return true; } else { false; }
	}

	getBootstrapBreakpoint() {
		let breakpoint = "sm";
		if (window.innerWidth >= 1200) breakpoint = "xl";
		else if (window.innerWidth >= 992) breakpoint = "lg";
		else if (window.innerWidth >= 768) breakpoint = "md";
		return breakpoint;
	}


	listAdvisories(): any {

		const info_advisories = [];
		const warning_advisories = [];

		if (!this.merchantService.merchant || !this.merchantService.merchant.order_constraints) { return; }

		// info part needs to be based on the order_constraints!
		for (const order_constraint of this.merchantService.merchant.order_constraints) {
			const reason = order_constraint.name;
			let advisory_type = 'none';

			if (order_constraint.scenarios.length == 0 || order_constraint.scenarios.indexOf(this.userService.basket.order.scenario) != -1) { advisory_type = 'info'; }
			if (order_constraint.dist_timings.length > 0 && order_constraint.dist_timings.indexOf(this.userService.basket.order.dist_timing) == -1) {
				advisory_type = 'none';
			}

			if (order_constraint.group_types.length > 0) {
				if (this.userService.basket.order.order_type == 'group') {
					if (order_constraint.group_types.indexOf('approved') == -1 &&
						order_constraint.group_types.indexOf('unapproved') == -1) {
						advisory_type = 'none';
					}
				} else {
					if (order_constraint.group_types.indexOf('individual') == -1) { advisory_type = 'none'; }
				}

			}

			if (order_constraint.category.length > 0) { advisory_type = 'none'; }
			if (!order_constraint.enabled || !order_constraint.to_be_shown) { advisory_type = 'none'; }


			if (advisory_type == 'info' && info_advisories.indexOf(reason) == -1 && warning_advisories.indexOf(reason) == -1) {
				info_advisories.push(reason);
			}
		}

		if (this.userService.basket.order.dist_timing == 'asap' && this.merchantService.merchant.is_open
			&& this.merchantService.hasFeature(this.merchantService.merchant.id, 'f_waiting_time')
			&& !this.merchantService.kiosk) {
			info_advisories.push('De geschatte wachttijd bedraagt momenteel ' + this.merchantService.merchant.waiting_time + ' minuten.');
		}

		for (const dist_timing of this.userService.basket.checkoutFields.dist_timing) {

			if (dist_timing.is_supported) {

				for (const reason of dist_timing.reasons) {
					let advisory_type = 'info';
					if (dist_timing.value == this.userService.basket.order.dist_timing) { advisory_type = 'warning'; }
					// if (advisory_type == 'info' && info_advisories.indexOf(reason) == -1 && warning_advisories.indexOf(reason) == -1) { info_advisories.push(reason); }
					if (advisory_type == 'warning' && warning_advisories.indexOf(reason) == -1) {
						warning_advisories.push(reason);
					}
					if (advisory_type == 'warning' && info_advisories.indexOf(reason) != -1) {
						info_advisories.splice(info_advisories.indexOf(reason), 1);
					}

				}

			}
		}

		if (this.userService.basket.checkoutFields.dist_timing_target) {

			for (const dist_timing_target of this.userService.basket.checkoutFields.dist_timing_target) {

				for (const slot of dist_timing_target.slots) {

					for (const reason of slot.reasons) {
						let advisory_type = 'info';
						if (dist_timing_target.value == this.userService.basket.order.dist_timing_target) { advisory_type = 'warning'; }
						// if (advisory_type == 'info' && info_advisories.indexOf(reason) == -1 && warning_advisories.indexOf(reason) == -1) { info_advisories.push(reason); }
						if (advisory_type == 'warning' && warning_advisories.indexOf(reason) == -1) {
							warning_advisories.push(reason);
						}
						if (advisory_type == 'warning' && info_advisories.indexOf(reason) != -1) { info_advisories.splice(info_advisories.indexOf(reason), 1); }
					}
				}

			}

		}

		for (const payment_method of this.userService.basket.checkoutFields.payment_method) {
			for (const reason of payment_method.reasons) {
				let advisory_type = 'info';
				if (payment_method.value == this.userService.basket.order.payment_method) { advisory_type = 'warning'; }
				// if (advisory_type == 'info' && info_advisories.indexOf(reason) == -1 && warning_advisories.indexOf(reason) == -1) { info_advisories.push(reason); }
				if (advisory_type == 'warning' && warning_advisories.indexOf(reason) == -1) {
					warning_advisories.push(reason);
				}
				if (advisory_type == 'warning' && info_advisories.indexOf(reason) != -1) { info_advisories.splice(info_advisories.indexOf(reason), 1); }
			}
		}

		for (const scenario of this.userService.basket.checkoutFields.scenario) {
			for (const reason of scenario.reasons) {
				let advisory_type = 'info';
				if (scenario.value == this.userService.basket.order.scenario) { advisory_type = 'warning'; }
				// if (advisory_type == 'info' && info_advisories.indexOf(reason) == -1 && warning_advisories.indexOf(reason) == -1) { info_advisories.push(reason); }
				if (advisory_type == 'warning' && warning_advisories.indexOf(reason) == -1) {
					warning_advisories.push(reason);
				}
				if (advisory_type == 'warning' && info_advisories.indexOf(reason) != -1) { info_advisories.splice(info_advisories.indexOf(reason), 1); }
			}
		}

		return { info: info_advisories, warning: warning_advisories };
	}

	getAddressFormatted(address, street_number = true) {
		let formatted = address.line1;
		if (address.street_number && street_number) { formatted += ' ' + address.street_number; }
		formatted += ',';
		formatted += address.zipcode + ' ';
		formatted += address.city + ', ';
		formatted += address.country;
		return formatted;
	}

	setOrderGroupInformation(group) {

		//Extra verification needed if user is already a member of the group
		if (this.userService.group) {
			this.userService.createGroupMember(group.id, { email: this.userService.user.email }).subscribe(
				groupMember => {
					console.log(groupMember);
					this.userService.user.membered_groups.push(group);
				},
				error => {
					console.log(error);
				}
			);
		}

		this.userService.basket.order.group = group;
		this.userService.basket.order.group_id = group.id;
		this.userService.basket.order.dist_timing = null;
		this.userService.basket.order.dist_timing_target = null;

		if (this.userService.basket.order.scenario == 'delivery') {
			this.userService.basket.order.dist_location_typecode = 'remote';
			if (group.address) {
				const address_submit = JSON.parse(JSON.stringify(group.address));
				address_submit.id = null;
				this.userService.address = address_submit;
				this.userService.basket.order.dist_location = { address: address_submit };
				this.userService.basket.order.dist_location_name = this.getAddressFormatted(group.address);
				this.userService.setValidDeliveryAddress(-1);
			} else {
				this.userService.removeDistLocation();
			}
		} else if (this.userService.basket.order.scenario == 'takeaway') {
			this.prepareTakeawayLocation();
		}
	}

	setVenue(domain, local_location = null, is_reservation = false) {

		let abbrev_domain = domain;
		if (domain.includes('.')) { abbrev_domain = domain.substring(0, domain.indexOf('.')); }

		this.merchantService.merchant = null;
		if (is_reservation && this.userService.user) {
			//window.location.href = "https://dev-one2three-appservice-reservationcustomerapp-we.azurewebsites.net?token=" + this.userService.access_token + "&merchantDomain=" + domain;
			window.location.href = "https://staging-one2three-appservice-reservationcustomerapp-we.azurewebsites.net?token=" + this.userService.access_token + "&merchantDomain=" + domain;
		} else if (is_reservation) {
			this.router.navigate(['/login'], { queryParams: { domain: domain, scenario: 'reservation' } });
		} else if (this.isMobile() && this.userService.user && !local_location) {
			this.router.navigate(['/' + abbrev_domain + '/order-details']);
		} else if (local_location) {
			this.router.navigate(['/' + abbrev_domain + '/menu'], { queryParams: { qr_scan: 1, qr_token: local_location.qr_token } });
		} else {
			//this.router.navigate(['/' + abbrev_domain + '/menu'], { queryParams: { forceOrderDetails: true }} );
			this.router.navigate(['/' + abbrev_domain + '/menu']);
		}
	}

	getBasketHeight(additional_base = 0) {
		let base = 350 + additional_base;
		if (this.userService.basket && this.userService.basket.order && this.userService.basket.order.price_before_modifiers != this.userService.basket.order.price) { base += 80; }
		return 'calc(100vh - ' + base + 'px)';
	}

	verifyBasketOrderState(domain) {

		if (this.userService.basket && this.userService.basket.order && this.userService.basket.order.id) {

			this.orderService.getOrderV1(this.userService.basket.order.id).subscribe(
				order => {
					if (order.state != 'composing' && order.state != 'canceled') {
						this.router.navigate(['/' + domain + '/order-status']);
					}
				},
				error => {
					console.log(error);
				});

		}
	}

	setPaymentMethodDescriptions(payment_methods) {

		for (let payment_method of payment_methods) {
			this.setPaymentMethodDescription(payment_method)
		}
		return payment_methods;
	}
	
	translatePaymentMethodDescriptions(payment_methods) {
		for (let payment_method of payment_methods) {
			payment_method.description = payment_method.value;
			if (payment_method.description == 'CASH') payment_method.description += "_" + this.userService.basket.order.scenario;
			payment_method.description = this.translate.instant("payment_method." + payment_method.value);

			if (this.userService.basket.order.scenario == 'takeaway' && payment_method.value == "CASH") {
				if (this.merchantService.merchant.cash_description_takeaway && this.merchantService.merchant.cash_description_takeaway.length > 3) {
					payment_method.description = this.merchantService.merchant.cash_description_takeaway;
				}
			} else if (this.userService.basket.order.scenario == 'delivery' && payment_method.value == "CASH") {
				if (this.merchantService.merchant.cash_description_delivery && this.merchantService.merchant.cash_description_delivery.length > 3) {
					payment_method.description = this.merchantService.merchant.cash_description_delivery;
				}
			} else if (this.userService.basket.order.scenario == 'eatin' && payment_method.value == "CASH") {
				if (this.merchantService.merchant.cash_description_eatin && this.merchantService.merchant.cash_description_eatin.length > 3) {
					payment_method.description = this.merchantService.merchant.cash_description_eatin;
				}
			} else if (this.userService.basket.order.scenario == 'table' && payment_method.value == "CASH") {
				if (this.merchantService.merchant.cash_description_table && this.merchantService.merchant.cash_description_table.length > 3) {
					payment_method.description = this.merchantService.merchant.cash_description_table;
				}
			}
		}
		return payment_methods;
	}

	setPaymentMethodDescription(payment_method) {

		payment_method.description = payment_method.value;
		if (payment_method.description == 'CASH') payment_method.description += "_" + this.userService.basket.order.scenario;

		payment_method.description = this.translate.instant("payment_method." + payment_method.description);

		//kiosk
		if (this.userService.basket.order.device_type == 'Kiosk') {
			if (payment_method.value == 'BCMCMOLLIE') payment_method.description += " (via QR-code)";
		}

		if (this.userService.basket.order.scenario == 'takeaway' && payment_method.value == "CASH") {
			if (this.merchantService.merchant.cash_description_takeaway && this.merchantService.merchant.cash_description_takeaway.length > 3) {
				payment_method.description = this.merchantService.merchant.cash_description_takeaway;
			}
		} else if (this.userService.basket.order.scenario == 'delivery' && payment_method.value == "CASH") {
			if (this.merchantService.merchant.cash_description_delivery && this.merchantService.merchant.cash_description_delivery.length > 3) {
				payment_method.description = this.merchantService.merchant.cash_description_delivery;
			}
		} else if (this.userService.basket.order.scenario == 'eatin' && payment_method.value == "CASH") {
			if (this.merchantService.merchant.cash_description_eatin && this.merchantService.merchant.cash_description_eatin.length > 3) {
				payment_method.description = this.merchantService.merchant.cash_description_eatin;
			}
		} else if (this.userService.basket.order.scenario == 'table' && payment_method.value == "CASH") {
			if (this.merchantService.merchant.cash_description_table && this.merchantService.merchant.cash_description_table.length > 3) {
				payment_method.description = this.merchantService.merchant.cash_description_table;
			}
		}

	}

	filterPaymentMethods(isKiosk, order, payment_methods, mode="normal") {

		payment_methods = payment_methods.filter(x => {

			if (x.value == 'APPLEPAYMOLLIE') {
				if (typeof device !== 'undefined' && device.platform == 'iOS') {
					return true;
				} else if (x.is_enabled && (window as any).ApplePaySession && (window as any).ApplePaySession.canMakePayments()) {
					return true;
				} else {
					return false;
				}
			} else {
				return x.is_enabled;
			}
		});

		if (order.order_type == 'group') {
			payment_methods = payment_methods.filter(x => {
				return order.group.payment_methods.includes(x.value);
			});
		}

		if (isKiosk) { payment_methods = this.filterPaymentMethodsForKiosk(payment_methods); }

		//payment_methods.push( { value: 'CASH', description: 'CASH_ADMIN', reasons: [], is_supported: true, is_enabled: true } );

		//merchant admins can always push through with CASH payment
		let merchant_admin_role = this.userService.user.roles.filter(role => { return role.typecode == 'merchant_admin' && role.merchant_id == this.userService.basket.order.merchant_id });
		if (merchant_admin_role.length > 0) {
			const has_cash = payment_methods.find(payment_method => { return payment_method.value == 'CASH' });
			if (!has_cash) {
				let admin_payment = { value: 'CASH', description: 'CASH_ADMIN', reasons: [], is_supported: true, is_enabled: true }
				this.setPaymentMethodDescription(admin_payment);
				payment_methods.push(admin_payment);
			}
		};

		//ALWAYS CASH FOR KIOSK
		if (this.merchantService.merchant.id !== 716 &&
			this.merchantService.merchant.id !== 743 &&
			this.merchantService.merchant.id !== 115 &&
			this.merchantService.merchant.id !== 949 &&
			this.merchantService.merchant.id !== 406 &&
			this.merchantService.merchant.id < 931 &&
			this.userService.basket.order.device_type == 'Kiosk' &&
			!payment_methods.find(x => { return x.value == 'CASH' })) {
			let kiosk_payment = { value: 'CASH', description: 'CASH_kiosk', reasons: [], is_supported: true, is_enabled: true };
			this.setPaymentMethodDescription(kiosk_payment);
			payment_methods.push(kiosk_payment);
		};

		//ALWAYS PAY LATER FOR POS
		if (this.merchantService.pos && mode === "normal") {
			let pay_later = { value: 'PAY_LATER', description: 'PAY_LATER', reasons: [], is_supported: true, is_enabled: true };
			this.setPaymentMethodDescription(pay_later);
			payment_methods.push(pay_later);
		} else if (this.merchantService.pos && mode === "refund") {
			payment_methods = payment_methods.filter(payment_method => { return payment_method.value == "CASH" } );
		};

		if (this.userService.basket.order.device_type == 'Kiosk') {
			payment_methods = payment_methods.sort((a, b) => {
				if (a.value === 'WORLDLINE' || a.value === 'CCV') {
					return -1;
				} else { return 1; };
			});
		};

		return payment_methods;

	}

	filterPaymentMethodsForKiosk(payment_methods) {

		return payment_methods.filter(x => {
			if (x.value == 'CASH') { return true; }
			else if (x.value == 'PAYCONIQ') { return true; }
			else if (x.value == 'BCMCMOLLIE') { return true; }
			else if (x.value == 'WORLDLINE') { return true; }
			else if (x.value == 'CCV') { return true; }
			else if (x.value == 'WALLET') { return true; }
			else { return false; }
		});
	}

	selectOrderType(order_type) {

		this.userService.basket.order.order_type = order_type;

		if (order_type == 'group') {

			if (!this.userService.basket.order.group_id) {
				let group_found = false;
				for (const membered_group of this.userService.user.membered_groups) {
					if (this.userService.user.preferred_group_type == 'group' && this.userService.user.preferred_group_id == membered_group.id) {
						this.setOrderGroupInformation(membered_group);
						group_found = true;
						break;
					}
				}

				if (!group_found) {
					const membered_groups = this.userService.user.membered_groups.filter(group => {
						return group.merchant_id == this.merchantService.merchant.id && group.approval_status == 'approved';
					});
					if (membered_groups.length > 0) this.setOrderGroupInformation(membered_groups[0]);
				}
			}

		} else {
			this.userService.basket.order.group_id = null;
			this.userService.basket.order.group = null;
			this.userService.removeDistLocation();
		}

	}

	calculateRealStock(product, in_basket) {
		product.real_stock = product.in_stock - in_basket;
		if (product.real_stock != undefined && product.minimum_stock != undefined && product.real_stock <= product.minimum_stock) product.is_available = false;
	}

	updateStockInfoProduct(products, product_id, order_line, in_basket) {

		products.map(product => {
			if (product.id == product_id) {
				product.in_stock = order_line.product_in_stock;
				product.minimum_stock = order_line.product_minimum_stock;
				product.is_available = order_line.product_is_available;
				this.calculateRealStock(product, in_basket);
			}
		});
	}

	loadListOfAllCountries(): Observable<any[]> {
		if (this.listOfAllCountries.length > 0) {
			return new Observable(observer => {
				observer.next(this.listOfAllCountries);
			});
		} else {
			return new Observable(observer => {
				this.merchantService.getListOfAllCountries().subscribe(
					(response: any) => {
						this.listOfAllCountries = response;
						observer.next(response);
					},
					(error) => {
						console.log(error);
					}
				);
			});
		}
	}

	loadListOfAllTimezones(): Observable<any[]> {
		if (this.listOfAllTimezones.length > 0) {
			return new Observable(observer => {
				observer.next(this.listOfAllTimezones);
			});
		} else {
			return new Observable(observer => {
				this.merchantService.getListOfAllTimezones().subscribe(
					(response: any) => {
						this.listOfAllTimezones = response;
						observer.next(response);
					},
					(error) => {
						console.log(error);
					}
				);
			});
		}
	}

	getAlterationAmount(unit, unit_conversion) {
		if (unit == 'g') { return 0.05; }
		else if (unit == 'kg') { return 0.1; }
		else if (unit == 'pieces') { return 1.00 / unit_conversion; }
		else if (unit == 'slices') { return 1.00 / unit_conversion; }
		else if (unit == 'persons') { return 1.00 / unit_conversion; }
		else return 1;
	}

	roundAmount(unit, amount) {
		if (unit == "pieces" || unit == "g") { return Math.ceil(amount - 0.0001); }
		else { return Math.round(amount * 100) / 100; };
	}

	doesFileExist(urlToFile) {
		var xhr = new XMLHttpRequest();
		xhr.open('HEAD', urlToFile, false);
		xhr.send();

		if (xhr.status == 404) {
			return false;
		} else {
			return true;
		}
	}

	isFol() {
	  if (environment.whitelabel.startsWith("fol")) return true;
      else return false;
 	}

}
