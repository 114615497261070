import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { AppHelper } from '../../helpers/app-helper';

import { OrderService } from '../../services/order.service';
import { UserService } from '../../services/user.service';
import { MerchantService } from '../../services/merchant.service';

import { TranslateService } from '@ngx-translate/core';
import { UtilityService } from '../../services/utility.service';

import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'data-request',
  templateUrl: './data-request.component.html',
  styleUrls: ['./data-request.component.scss'],
  animations: [
  trigger('fadeInOut', [
    state('void', style({
      opacity: 0
    })),
    transition('void <=> *', animate(350)),
    ]),
  trigger('slideUp', [
    state('void', style({
      transform: 'translateY(150%)'
    })),
    transition('void <=> *', animate('0.5s ease-in-out')),
    ]),
  ]
})
export class DataRequestComponent implements OnInit {

	@Input() show_basketbar = true;
  @Input() needsUserDetails = false;
	@Input() needsOrderDetails = false;
  @Output() updated = new EventEmitter<boolean>();
	@Output() completed = new EventEmitter<boolean>();

	public checkoutFields: any;
  public updating = false;

  public applyForApprovedGroup = 'no';
  public groupFunctionality = false;

  constructor(	private appHelper: AppHelper,
  				private orderService: OrderService,
  				public userService: UserService,
          public merchantService: MerchantService,
          private translate: TranslateService,
          private util: UtilityService) { }

  ngOnInit() {

    if (this.userService.user) {
      this.groupFunctionality = this.hasGroupFunctionality();
      this.applyForApprovedGroup = this.canApplyForApprovedGroup();

      let membered_groups = this.userService.user.membered_groups.filter(group => { 
        return group.merchant_id == this.merchantService.merchant.id && group.approval_status == 'approved'
      } );

      //no ordering posssible, and user is a member of an approved group?
      //we automatically switch to that group
      if ((this.userService.basket && !this.userService.basket.orderingPossible) 
          && this.groupFunctionality
          && this.userService.basket.order.scenario == 'delivery' 
          && membered_groups.length > 0) {
        this.appHelper.setOrderGroupInformation(membered_groups[0]);
        this.selectOrderType('group');
      }
      
    }

  	if (!this.needsUserDetails) { this.enableStep2(); }

  }

  selectOrderType(order_type) {
    this.appHelper.selectOrderType(order_type);
    console.log("#### UPDATE CHECKOUT FIELDS TRIGGERED data-request.component selectOrderType");
    this.appHelper.updateCheckoutFieldsV2().subscribe(response => {
      //we need to update the checkout fields, so that advisories are correctly listed,
      //but we do not take any further action
    });
  }

  enableStep2(event?) {

    let menu_only = this.merchantService.hasFeature(this.merchantService.merchant.id, 'f_menu_only');

    this.needsUserDetails = false;
    this.groupFunctionality = this.hasGroupFunctionality();
    
    if (!menu_only && !this.userService.basket) {

      this.appHelper.initBasket().subscribe(
        response => {
          console.log('INIT BASKET = SUCCESFUL');

          /*if (this.userService.order_line_pending) {
          let pending_product = this.merchantService.merchant.products.find(product => { return product.id === this.userService.order_line_pending.product_id });
          let pending_taxon = this.merchantService.merchant.categories.find(taxon => { return taxon.id == this.userService.order_line_pending.taxon_id });
          console.log(pending_product);
          if (pending_product) { 
            console.log("WE WILL NOW ADD THIS ORDER LINE!");
            this.userService.addOrderLine(this.userService.order_line_pending, null, true, false);
            this.userService.order_line_pending = null;
            this.updated.emit(true);
          };
          }*/

        },
        error => {
          console.log('THERE WAS AN ISSUE WITH INIT OF THE BASKET');
          console.log(error);
        });
     }

     if (menu_only) {
       this.userService.basket.orderingPossible = true;
       this.goToVenue();
     
     } else if (
       this.userService.basket &&
       this.userService.basket.order && 
       this.userService.basket.order.scenario != 'table') { 
     
       console.log("DO WE HAVE A PENDING ORDERLINE?");
      console.log(this.userService.order_line_pending);
      
       this.needsOrderDetails = true;
     
     } else if (this.userService.basket && this.userService.basket.order) { 
       this.userService.basket.orderingPossible = true;
       this.goToVenue(); 
     }
  }

  canApplyForApprovedGroup() {
    let toReturn = 'no';
    if (!this.userService.user.is_anonymous_user) {


      let merchant_group = this.userService.user.groups.find(group => { return group.merchant_id ==  this.merchantService.merchant.id} );
      let membered_group = this.userService.user.membered_groups.find(group => { return group.merchant_id ==  this.merchantService.merchant.id} );
      
      if (!merchant_group && !membered_group) toReturn = 'yes';
      else if (merchant_group && merchant_group.approval_status == 'pending') toReturn = 'pending'; 
    }
    return toReturn;
  }

  /*getMerchantGroup() {
    const merchant_id = this.merchantService.merchant.id;
    const merchant_groups = this.userService.user.groups.map(function(group) {
      if (merchant_id == group.merchant_id) { return group; }
    });
    return merchant_groups.filter(function (group) { return group != undefined; })[0];
  }*/

  hasGroupFunctionality() {
    let toReturn = false;
    if (this.userService.user && this.merchantService.hasFeature(this.merchantService.merchant.id, 'f_group_functionality')) {
        if (this.userService.user.membered_groups.length > 0) toReturn = true;
    }
    return toReturn;
  }

  continueDisabled() {

    if (this.userService.basket.order.scenario &&
        this.userService.basket.order.dist_timing &&
        (this.userService.basket.order.dist_timing == 'asap' || this.userService.basket.order.dist_timing_target)) {
       return false;
    } else { return true; }

  }

  goToVenue() {
    this.completed.emit(true);
  }

  getUpdate() {
    this.userService.saveBasketInStorage();
    this.updated.emit(true);
    this.userService.alterUpdateOngoing(true);
    this.appHelper.updateCheckoutFieldsV2().subscribe(
      respone => {
        this.userService.alterUpdateOngoing(false);
      },
      error => {
        this.userService.alterUpdateOngoing(false);
      });
  }

  updateNoOfPersons(event, change) {
    if (this.userService.basket.order.no_of_persons) { this.userService.basket.order.no_of_persons += change; }
    else { this.userService.basket.order.no_of_persons = 0 + change; }
    event.stopPropagation();
  }

}
