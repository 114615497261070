import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../services/api.service';
import { Address } from '../models/address';
import { Basket } from '../models/basket';
import { OrderLine } from '../models/order_line';
import { forkJoin, timer, BehaviorSubject } from 'rxjs'; // RxJS 6 syntax
import { SwPush } from '@angular/service-worker';

import { faAddressCard, faBurgerSoda, faChartLine, faHeart, faTruckContainer, faUser, faUsers, faCashRegister } from '@fortawesome/pro-regular-svg-icons';
import { environment } from '../../environments/environment';


declare var google;
declare var WonderPush: any;
declare var window: any;

@Injectable()
export class UserService {

  readonly VAPID_PUBLIC_KEY = "BA02_Pgv8SmGfVSBhnEHNuOq4FVTjMnLZOTGenScq-fFlEdbcjdgZQ8F-e7qDXEbiu1Df8l69-G_XUhtfGlK3Wc";

  public userExpected = false;
  public user: any;
  public pages: any;
  public access_token: any;
  public scenario = 'all';

  public basket: Basket;

  public orderUpdate = new EventEmitter();
  public stateUpdate = new EventEmitter();

  public userUpdate = new EventEmitter();
  public languageUpdate = new EventEmitter();
  public warningsUpdate = new EventEmitter();
  public basketPushed = new EventEmitter();
  public addressUpdate = new EventEmitter();
  public checkoutFieldsUpdate = new EventEmitter();
  public validDeliveryAddressEmitter = new EventEmitter();

  public address: Address;
  public geoAddress: Address;
  private locationApproval = true;

  public useCurrentLocation = true;
  public hasFavorites = false;

  public warnings = 0;
  public warningsGroupOrders = 0;
  public warningsInvitedGroups = 0;
  public groupOrders = [];

  public local_location;
  public group;
  public notifications = [];

  private gps_requested = true;

  public darkMode = false;
  public showImages = true;
  public showPrices = true;
  public showSecondScreen = false;
  public fontSize = 16;
  public fontSizeSecondScreen = 16;
  public editMode = false;
  public fontSizeSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  public fontSizeSecondScreenSubject: BehaviorSubject<any> = new BehaviorSubject(null);

  public paymentOngoingSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  public sortNormalSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  public sortNormal = true;
  public showPricesConsumerScreen: boolean = false;

  private DarkModeSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  private editModeSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  
  public posCounterId = null;
  public posCounter = null;

  private showPricesConsumerScreenSubject: BehaviorSubject<any> = new BehaviorSubject(null);

  public fdmUser = new BehaviorSubject(false);
  public pos_feature = null;

  public order_line_pending:OrderLine = null;

  constructor(
    private swPush: SwPush,
    private apiService: ApiService,
    public translate: TranslateService
    ) {
    this.init();
  }

  init() {

    if (typeof environment.gps_requested !== 'undefined') this.gps_requested = environment.gps_requested;

    const geoInterval = timer(0, 30000);
    geoInterval.subscribe(val => {
      if (this.gps_requested && this.locationApproval) this.getCurrentPosition();
      //do we have group orders in review?
      if (!environment.pos &&
        !environment.kitchen &&
        this.user &&
        this.user.groups) this.collectGroupOrders();
    }
  );

  }

  getLightOrDarkMode(): Observable<any> {
    return this.DarkModeSubject.asObservable();
  }

  toggleDarkMode(latestValue: boolean) {
    localStorage.setItem("darkMode",JSON.stringify({ 'darkMode': latestValue }));
    this.darkMode = latestValue;
    return this.DarkModeSubject.next(latestValue);
  }

  getShowPricesConsumerScreen(): Observable<any> {
    return this.showPricesConsumerScreenSubject.asObservable();
  }

  toggleShowPricesConsumerScreen(latestValue: boolean) {
    return this.showPricesConsumerScreenSubject.next(latestValue);
  }

  getFontSize(): Observable<any> {
    return this.fontSizeSubject.asObservable();
  }

  setFontSize(latestValue: number) {
    return this.fontSizeSubject.next(latestValue);
  }

  getFontSizeSecondScreen(): Observable<any> {
    return this.fontSizeSecondScreenSubject.asObservable();
  }

  setFontSizeSecondScreen(latestValue: number) {
    return this.fontSizeSecondScreenSubject.next(latestValue);
  }

  getEditMode(): Observable<any> {
    return this.editModeSubject.asObservable();
  }

  setEditMode(latestValue: number) {
    return this.editModeSubject.next(latestValue);
  }

  logoutUser() {
    if (sessionStorage) sessionStorage.removeItem('ngStorage-token');
    if (localStorage) localStorage.removeItem('ngStorage-token');
    this.destroyUser();
    this.access_token = null;
  }


  collectGroupOrders(): Promise<any> {

    return new Promise(resolve => {

      let merchant_groups = this.user.groups.filter(group => { return group.merchant_id != null });

      let promises = [];

      let merchant_ids = [];

      //group owner of a certain group
      merchant_ids = merchant_ids.concat(merchant_groups.map(merchant_group => merchant_group.merchant_id));

      //also all the merchants for which the user is a merchant admin
      merchant_ids = merchant_ids.concat(this.user.roles
        .filter(role => role.typecode == 'merchant_admin')
        .map(role => role.merchant.id));

      merchant_ids = merchant_ids.filter((n, i) => merchant_ids.indexOf(n) === i);

      merchant_ids.forEach(merchant_id => {
        promises.push(this.getGroupOrders(merchant_id));
      });

      let newGroupOrders = [];
      forkJoin(promises).subscribe((responseList: Array<any>) => {

        for (let the_groups of responseList) {
          for (let the_group of the_groups) {
            newGroupOrders.push(the_group);
          }
        }

        for (let cur_group_order of this.groupOrders) {
          cur_group_order.present = false;
        }

        for (let group_order of newGroupOrders) {
          let found = false;
          let index = 0;
          for (let cur_group_order of this.groupOrders) {
            let collapsed = cur_group_order.is_collapsed;
            if (cur_group_order.id == group_order.id) {
              this.groupOrders[index] = group_order;
              this.groupOrders[index].is_collapsed = collapsed;
              this.groupOrders[index].present = true;
              found = true;
              //break;
            }
            index++;
          }
          if (!found) {
            group_order.present = 'yes';
            group_order.is_collapsed = true;
            this.groupOrders.push(group_order);
          }
        }

        this.groupOrders = this.groupOrders.filter(order => { return order.present });
        this.countWarnings();

        resolve(this.groupOrders);

      });

    });

  }

  countWarnings() {
    this.warningsGroupOrders = this.groupOrders.length;
    if (this.user) {
      this.warningsInvitedGroups = this.user.open_invited_groups.length;
    } else { this.warningsInvitedGroups = 0; }
    this.warnings = this.warningsGroupOrders + this.warningsInvitedGroups;
    this.warningsUpdate.emit(this.warnings);
  }

  getCurrentPosition() {
    if (navigator && navigator.geolocation) navigator.geolocation.getCurrentPosition(this.onSuccess.bind(this), this.onError.bind(this), { maximumAge: 0, timeout: 30000, enableHighAccuracy: true });
  }

  getNotifications(query): Observable<any> {
    return this.apiService.get('api/v2/notifications?' + query, this.access_token);
  }

  onSuccess = function (position) {

    let dis = 9000;
    if (this.geoAddress) {
      let lat1 = this.geoAddress.latitude;
      let lng1 = this.geoAddress.longitude;
      let lat2 = position.coords.latitude;
      let lng2 = position.coords.longitude;
      const p = 0.017453292519943295;    // Math.PI / 180
      const c = Math.cos;
      const a = 0.5 - c((lat1 - lat2) * p) / 2 + c(lat2 * p) * c((lat1) * p) * (1 - c(((lng1 - lng2) * p))) / 2;
      dis = (12742 * Math.asin(Math.sqrt(a)));
    }

    if (dis > 1) {
      this.geoAddress = new Address();
      this.geoAddress.latitude = position.coords.latitude;
      this.geoAddress.longitude = position.coords.longitude;
      this.geoAddress.timestamp = position.timestamp;
      try {
        this.geocode();
      } catch (error) {
        console.warn(error);
      }
    }
  };

  onError = function (error) {
    if (error.code == 1) this.locationApproval = false;
    console.log('code: ' + error.code + '\n' +
      'message: ' + error.message + '\n');
  };

  getUser(user_id: string) {
    return this.apiService.get('api/v1/users/' + user_id, this.access_token);
  }

  getProfile(): Observable<any> {
    return this.apiService.get('api/v2/users/me', this.access_token);
  }

  getLoyaltyPoints(): Observable<any> {
    return this.apiService.get('api/v1/users/show_loyalty_points', this.access_token);
  }

  getMonizzeBalance(): Observable<any> {
    return this.apiService.get('api/v2/monizze/balance', this.access_token);
  }

  updateProfile(user): Observable<any> {
    return this.apiService.put('api/v2/users/' + user.id, user, this.access_token);
  }

  updateINSZ(user): Observable<any> {
    return this.apiService.put('api/v1/users/' + user.id + '/update_insz', user, this.access_token);
  }

  createGroup(group): Observable<any> {
    return this.apiService.post('api/v2/groups/', group, this.access_token);
  }

  connectLoyaltyCard(qr_code): Observable<any> {
    return this.apiService.post('api/v1/users/' + this.user.id + '/connect_loyalty_card', { qr_code: qr_code }, this.access_token);
  }

  updateGroup(group): Observable<any> {
    return this.apiService.put('api/v2/groups/' + group.id, { group: group }, this.access_token);
  }

  getGroup(group): Observable<any> {
    return this.apiService.get('api/v2/groups/', group);
  }

  deleteGroup(group_id) {
    return this.apiService.delete('api/v2/groups/' + group_id, this.access_token);
  }

  groupOrderCount() {
    let count = 0;
    for (const group of this.user.groups) {
      if (group.order_count > 0) { count++; }
    }
    return count;
  }

  getGroupOrders(merchant_id) {
    let locale = 'nl'
    if (this.user && this.user.locale) locale = this.user.locale;
    return this.apiService.get('api/v2/groups/in_review_order_groups?locale=' + locale + '&merchant_id=' + merchant_id, this.access_token);
  }

  getGroupMembers(group_id) {
    return this.apiService.get('api/v2/groups/' + group_id + '/group_members', this.access_token);
  }

  createGroupMember(group_id, groupMember) {
    return this.apiService.post('api/v2/groups/' + group_id + '/group_members', groupMember, this.access_token);
  }

  deleteGroupMember(group_id, groupMemberId) {
    return this.apiService.delete('api/v2/groups/' + group_id + '/group_members/' + groupMemberId, this.access_token);
  }

  onLoginFormSubmit(auth): Observable<any> {
    return this.apiService.post('oauth/token', auth, '');
  }

  anonymousUser(user): Observable<any> {
    return this.apiService.post('api/v1/users/anonymous', user, '');
  }

  anonymousUserToken(user): Observable<any> {
    return this.apiService.post('api/v1/users/anonymous_user_token', user, '');
  }

  registerUserV1(user): Observable<any> {
    return this.apiService.post('api/v1/users/', user, '');
  }

  passwordReset(user): Observable<any> {
    return this.apiService.post('api/v1/users/reset_password', user, '', 'text');
  }

  accountDeletion(): Observable<any> {
    return this.apiService.post('api/v1/users/account_deletion', {}, this.access_token);
  }

  registerUser(user): Observable<any> {
    return this.apiService.post('api/v2/users/register', user, '');
  }

  setPreferredGroup(groupId, userId) {
    return this.apiService.post('api/v2/groups/' + groupId + '/set_preferred_group/' + userId, { preferred_group_type: 'group' }, this.access_token);
  }

  leaveGroup(groupId) {
    return this.apiService.delete('api/v2/groups/' + groupId + '/leave', this.access_token);
  }

  acceptInvitation(groupId) {
    return this.apiService.put('api/v2/groups/' + groupId + '/accept', {}, this.access_token);
  }

  updateUserLanguage(user): Observable<any> {
    return this.apiService.put('api/v2/users/' + user.id, user, this.access_token);
  }

  updateGroupStatus(group) {
    return this.apiService.put('api/v2/groups/' + group.id + '/update_appoval_status', { group: group }, this.access_token);
  }

  hasRole(user, typecode, merchant_id = null) {
    if (user) {
      let roles = user.roles.filter(role => {
        if (!merchant_id) return role.typecode == typecode;
        else return role.typecode == typecode && role.merchant_id == merchant_id;
      });
      if (roles.length > 0) {
        return true;
      } else { return false; }
    } else {
      return false;
    }
  }

  setUser(user) {
    this.userExpected = true;
    this.user = user;
    if (this.user.email.startsWith("table-")) { this.user.virtual_user = true }
      else if (this.user.email.startsWith("kiosk")) { this.user.virtual_user = true }
        else { this.user.virtual_user = false }

    this.setFdmUser();

    //order the merchant groups for this user
      this.user.merchant_groups = this.user.merchant_groups.sort(function (a, b) { return a.name > b.name ? 1 : -1; });

    //let's see if this an employee (waiter)
      let employee_role = this.user.roles.filter(role => { return role.typecode == 'merchant_employee' });
      if (employee_role.length > 0) {
        this.user.is_waiter = true;
        this.user.waiter_role = employee_role[0];
      }

      this.setPages();
      this.subscribeToNotifications();

      try {
        if (window.hasOwnProperty('cordova')) {
          WonderPush.setUserId(user.email);
          WonderPush.subscribeToNotifications(true);      
         }
      } catch (error) {
        console.log("Can not subscribe to WonderPush");
      }

      this.userUpdate.emit(user);
  }

  setFdmUser() {
    if (this.user.insz && this.user.insz.length == 11) {       
      const seven = +this.user.insz.substr(0,9);
      const verifier = +this.user.insz.substr(9);
      this.fdmUser.next(97 - (seven % 97) == verifier); 
    } else {
      this.fdmUser.next(false);
    }
  }

  alterUpdateOngoing(updateOngoing) {
    if (this.basket) {
      this.basket.updateOngoing = updateOngoing;
      this.stateUpdate.emit(updateOngoing);
    }
  }

  setCheckoutFields(field_values) {
    if (this.basket) { this.basket.checkoutFields = field_values; };
    this.checkoutFieldsUpdate.emit(field_values);
  }

  setPaymentOngoing(ongoing) {
    if (this.basket) {
      this.basket.paymentOngoing = ongoing;
      this.paymentOngoingSubject.next(ongoing);
      this.saveBasketInStorage();
    }
  }

  setPages() {

      let fol = false;
      if (environment.whitelabel.startsWith("fol")) fol = true;

      this.pages = [];

      if (fol == false) {

        if (this.user && !this.user.is_anonymous_user) {
          this.pages.push(
          {
            title: this.translate.instant('navigation.profile'),
            description: 'Profile, Address, Payment details',
            url: '/profile',
            icon: faUser,
            warnings: 0
          },
          {
            title: this.translate.instant('navigation.my_orders'),
            description: 'Current and past orders',
            url: '/my-orders',
            icon: faBurgerSoda,
            warnings: this.warningsGroupOrders
          },
          {
            title: this.translate.instant('navigation.loyalty_points'),
            description: 'My loyalty points',
            url: '/my-loyalty-points',
            icon: faAddressCard,
            warnings: 0
          }
          )
        };

        if (this.hasFavorites) {
          this.pages.push(
          {
            title: this.translate.instant('navigation.my_favourite_merchants'),
            description: 'List of venues',
            url: '/venues/favorite',
            icon: faHeart,
            warnings: 0
          })
        };

        if (this.user && !this.user.is_anonymous_user) {
          this.pages.push({
            title: this.translate.instant('navigation.my_groups'),
            description: 'Profile, Address, Payment details',
            url: '/my-groups',
            icon: faUsers,
            warnings: this.warningsInvitedGroups
          });
        }

      /*
      {
        title: 'Vraag goedkeuring groep',
        description: 'Profile, Address, Payment details',
        url: '/apply',
        icon: 'person'
      },*/

      /*
      {
        title: 'Mijn waardebonnen',
        description: 'Tegoedbonnen, Joyn',
        url: '/orders',
        icon: 'card_giftcard'
      },
      {
        title: 'Dashboard',
        description: 'Latest metrics',
        url: 'dashboard',
        icon: 'dashboard'
      }*/

        if (this.user) {
          for (const role of this.user.roles) {
            if (role.typecode == 'super_admin') {
              this.pages.push(
              {
                title: 'Dashboard',
                description: 'Latest metrics',
                url: 'dashboard',
                icon: faChartLine,
                warnings: 0
              }
              );
              break;
            }
          }
        }

        if (this.user) {
          for (const role of this.user.roles) {
            if (role.typecode == 'merchant_admin') {
              this.pages.push(
              {
                title: `POS ${role.merchant.name}`,
                description: 'POS',
                url: `${role.merchant.domain}/pos`,
                icon: faCashRegister,
                warnings: 0
              }
              );
            }
          }
        }

        if (this.user) {
          for (const role of this.user.roles) {
            if (role.typecode == 'merchant_admin') {
              this.pages.push(
              {
                title: `KDS ${role.merchant.name}`,
                description: 'KDS',
                url: `${role.merchant.domain}/kds`,
                icon: faCashRegister,
                warnings: 0
              }
              );
            }
          }
        }

        if (this.user) {
          for (const role of this.user.roles) {
            if (role.typecode == 'distributor') {
              this.pages.push(
              {
                title: this.translate.instant('navigation.deliveries'),
                description: 'Delivery dashboard',
                url: 'admin/delivery-authorization',
                icon: faTruckContainer,
                warnings: 0
              }
              );
              break;
            }
          }
        }
      } else if(fol == true) {
        if (this.user && !this.user.is_anonymous_user) {
          this.pages.push(
          {
            title: this.translate.instant('navigation.profile'),
            description: 'Profile, Address, Payment details',
            url: '/profile',
            icon: faUser,
            warnings: 0
          },
          {
            title: this.translate.instant('navigation.my_orders'),
            description: 'Current and past orders',
            url: '/my-orders',
            icon: faBurgerSoda,
            warnings: this.warningsGroupOrders
          }
          )
        };

        if (this.hasFavorites) {
          this.pages.push(
          {
            title: this.translate.instant('navigation.my_favourite_merchants'),
            description: 'List of venues',
            url: '/venues/favorite',
            icon: faHeart,
            warnings: 0
          }
          )
        };

        if (this.user && !this.user.is_anonymous_user) {
          this.pages.push({
            title: this.translate.instant('navigation.my_groups'),
            description: 'Profile, Address, Payment details',
            url: '/my-groups',
            icon: faUsers,
            warnings: this.warningsInvitedGroups
          });
        }

    /*
    {
      title: 'Vraag goedkeuring groep',
      description: 'Profile, Address, Payment details',
      url: '/apply',
      icon: 'person'
    },*/

    /*
   {
     title: 'Mijn waardebonnen',
     description: 'Tegoedbonnen, Joyn',
     url: '/orders',
     icon: 'card_giftcard'
   },
   {
     title: 'Dashboard',
     description: 'Latest metrics',
     url: 'dashboard',
     icon: 'dashboard'
   }*/

        if (this.user) {
          for (const role of this.user.roles) {
            if (role.typecode == 'super_admin') {
              this.pages.push(
              {
                title: 'Dashboard',
                description: 'Latest metrics',
                url: 'dashboard',
                icon: faChartLine,
                warnings: 0
              }
              );
              break;
            }
          }
        }

        if (this.user) {
          for (const role of this.user.roles) {
            if (role.typecode == 'distributor') {
              this.pages.push(
              {
                title: 'Deliveries',
                description: 'Delivery dashboard',
                url: 'admin/delivery-authorization',
                icon: faTruckContainer,
                warnings: 0
              }
              );
              break;
            }
          }
        }

      }
    }

    public getBasket() {
      return this.basket;
    }

    public destroyUser() {
      this.user = null;
      this.fdmUser.next(false);
      this.pages = [];
      this.warningsInvitedGroups = 0;
      this.warningsGroupOrders = 0;
      this.warnings = 0;
      this.destroyBasket();
      this.userUpdate.emit(null);
    }

    public destroyBasket() {
      this.basket = null;
      this.saveBasketInStorage();
      this.basketPushed.emit(this.basket);

    }

  public setBasket(basket) {
    this.basket = basket;
    this.basketPushed.emit(basket);
    this.saveBasketInStorage();
  }

  public saveBasketInStorage() {
    if (this.basket) { localStorage.setItem('basket', JSON.stringify(this.basket)); }
    else { localStorage.removeItem('basket'); }
  }

    public newBasket(merchant, kiosk?, pos?) {

      let order_scenario = null;
      if (this.scenario && this.scenario != 'all') { order_scenario = this.scenario; }
      this.basket = new Basket(merchant.id, order_scenario);

      if (pos) {

        let counters = null;
        if (merchant.local_locations) counters = merchant.local_locations.filter(location => location.typecode == 'counter');
        let counter_id = null;
        let counter_found = false;
        if (this.posCounterId) { counter_id = this.posCounterId; }
        if (counter_id) {
          let counter = counters.find(location => location.id == counter_id);
          if (counter) {
            counter_found = true;
            if (counter.scenario) this.basket.order.scenario = counter.scenario;
            this.setOrderLocationToCounter(counter);
          }
        }

        if (!counter_found && counters && counters.length > 0) {
          this.setOrderLocationToCounter(counters[0]);
        }

        this.basket.order.device_type = 'POS';


      }

      if (kiosk) {

        let kiosk_terminals = null;
        if (merchant.local_locations) kiosk_terminals = merchant.local_locations.filter(location => location.typecode == 'terminal');

        this.basket.order.device_type = 'Kiosk';
        this.basket.order.scenario = 'takeaway';
        this.basket.order.dist_timing = 'asap';

        if (kiosk_terminals.length > 0) {
          this.basket.order.order_location_id = kiosk_terminals[0].id;
          this.basket.order.order_location_name = kiosk_terminals[0].name;
          this.basket.order.order_location_typecode = kiosk_terminals[0].typecode;
        }

        this.basket.order.kiosk_id = kiosk.id;

      } else if (this.local_location) {

        if (this.local_location.typecode == 'table') {
          this.basket.order.scenario = 'table'
          this.basket.order.order_location_typecode = this.local_location.typecode;
          this.basket.order.order_location_id = this.local_location.id;
          this.basket.order.order_location_name = this.local_location.name;
          this.basket.order.dist_timing = 'asap';

        //extra verification to see if scenario should be waiter
          let employee_role = this.user.roles.filter(role => { return role.typecode == 'merchant_employee' && role.merchant_id == this.basket.order.merchant_id });
        //if (employee_role.length > 0 &&  this.merchantService.hasFeature(merchant.id, "f_scenario_waiter")) {
          if (!environment.pos && employee_role.length > 0) {
            this.basket.order.scenario = 'waiter';
            this.basket.order.intaker = 'waiter';
          }

        //} else { 
        } else if (this.basket.order.merchant_id !== 517) {
          this.basket.order.scenario = 'takeaway';
        };

        this.basket.order.dist_location_typecode = this.local_location.typecode;
        this.basket.order.dist_location_id = this.local_location.id;
        this.basket.order.dist_location_name = this.local_location.name;

    }

    if (this.basket.order.scenario == 'takeaway' && this.basket.order.dist_location_id == undefined) {

      let counters = null;
      if (merchant.local_locations) counters = merchant.local_locations.filter(location => location.typecode == 'counter');

      if (counters && counters.length > 0) {
        if (kiosk && kiosk.default_local_location) {
          let local_location = counters.filter(location => location.id == kiosk.default_local_location)[0];
          this.basket.order.dist_location_typecode = local_location.typecode;
          this.basket.order.dist_location_id = local_location.id;
          this.basket.order.dist_location_name = local_location.name;
        } else {
          let local_location = counters[0];
          this.basket.order.dist_location_typecode = local_location.typecode;
          this.basket.order.dist_location_id = local_location.id;
          this.basket.order.dist_location_name = local_location.name;
        }
    }

  }

      this.saveBasketInStorage();
      this.basketPushed.emit(this.basket);

    }

    addProduct(product) {
      const orderLine = new OrderLine(product);
      orderLine.product_id = product.id;
      orderLine.product_name = product.name;
      this.addOrderLine(orderLine);
    }

    removeProduct(product) {
      const ols = this.basket.order.order_lines;
      for (const ol of ols) {
        if (ol.product_id == product.id) {
          ol.amount -= 1;
        }
      }

    }

    public addOrderLine(orderLine: any, unit_conversion = 1.00, emit_update = true, autogroup = false) {

      let fullyFound = -1;

      let index = 0;
      for (const basketOrderLine of this.basket.order.order_lines) {

      //same product
      if (basketOrderLine.product_id == orderLine.product_id && basketOrderLine.taxon_id == orderLine.taxon_id
        && basketOrderLine.unit_price == orderLine.unit_price) {

          fullyFound = index;

          if (basketOrderLine.order_part_removes.length != orderLine.order_part_removes.length) { fullyFound = -1; }
          else {
            basketOrderLine.order_part_removes.sort((a, b) => (a.product_id < b.product_id) ? 1 : -1);
            orderLine.order_part_removes.sort((a, b) => (a.product_id < b.product_id) ? 1 : -1);

            basketOrderLine.order_part_removes.forEach(function (order_part_remove, i) {
              if (basketOrderLine.order_part_removes[i].product_id != orderLine.order_part_removes[i].product_id) { fullyFound = -1; }
            });
          }

          for (const basketOrderOption of basketOrderLine.order_options) {
            for (const orderOption of orderLine.order_options) {
              if (basketOrderOption.option_id == orderOption.option_id) {
                if (basketOrderOption.order_option_values.length != orderOption.order_option_values.length) { fullyFound = -1; }
                else {

                //sort
                  basketOrderOption.order_option_values.sort((a, b) => (a.option_value_id < b.option_value_id) ? 1 : -1);
                  orderOption.order_option_values.sort((a, b) => (a.option_value_id < b.option_value_id) ? 1 : -1);

                  basketOrderOption.order_option_values.forEach(function (order_option_value, i) {
                    if (basketOrderOption.order_option_values[i].option_value_id != orderOption.order_option_values[i].option_value_id) { fullyFound = -1; }
                  });
                }
              }
            }
          }

          for (const basketOrderPartSet of basketOrderLine.order_part_sets) {
            for (const orderPartSet of orderLine.order_part_sets) {
              if (basketOrderPartSet.part_set_id == orderPartSet.part_set_id) {
                if (basketOrderPartSet.order_part_adds.length != orderPartSet.order_part_adds.length) { fullyFound = -1; }
                else {

                //sort
                  basketOrderPartSet.order_part_adds.sort((a, b) => (a.product_id < b.product_id) ? 1 : -1);
                  orderPartSet.order_part_adds.sort((a, b) => (a.product_id < b.product_id) ? 1 : -1);

                  basketOrderPartSet.order_part_adds.forEach(function (order_part_add, i) {
                    if (basketOrderPartSet.order_part_adds[i].product_id != orderPartSet.order_part_adds[i].product_id) { fullyFound = -1; }
                  });
                }
              }
            }
          }

          if (basketOrderLine.is_loyalty != orderLine.is_loyalty) { fullyFound = -1; }

        }

        if (fullyFound != -1) {
          break;
        } else index++;

      }

      if (this.basket.order.merchant_id == 544) { fullyFound = -1; };
    //if (autogroup === false) { fullyFound = -1; };

      if (fullyFound == -1) { this.basket.order.order_lines.push(JSON.parse(JSON.stringify(orderLine))); }
      else {

        let order_option = this.basket.order.order_lines[fullyFound].order_options.find(order_option => {
          let found = false;
          for (let oov of order_option.order_option_values) {
            found = oov.unit == this.basket.order.order_lines[fullyFound].order_unit;
            if (found) break;
          }
          return found;
        });

      let order_option_value = null;
      if (order_option) { order_option_value = order_option.order_option_values.find(oov => oov.unit == this.basket.order.order_lines[fullyFound].order_unit); }

      this.basket.order.order_lines[fullyFound].amount += orderLine.amount; 
      if (this.basket.order.order_type == 'refund') {
        this.basket.order.order_lines[fullyFound].minus -= orderLine.amount;
      } else {
        this.basket.order.order_lines[fullyFound].plus += orderLine.amount;
      }

      this.basket.order.order_lines[fullyFound].order_amount = this.basket.order.order_lines[fullyFound].amount * unit_conversion;
      this.basket.order.order_lines[fullyFound].order_amount = this.roundAmount(this.basket.order.order_lines[fullyFound].order_unit, this.basket.order.order_lines[fullyFound].order_amount);
      this.basket.order.order_lines[fullyFound].comment = orderLine.comment;

      if (order_option_value) { order_option_value.amount = this.basket.order.order_lines[fullyFound].order_amount; };

      this.basket.order.order_lines[fullyFound].total_price = this.basket.order.order_lines[fullyFound].amount * orderLine.unit_price;

      }

      console.log("THE ORDER LINE HAS BEEN ADDEDD YEEEEYYYY");
      console.log(this.basket.order);

      if (emit_update) this.orderUpdate.emit(false);

    }

    roundAmount(unit, amount) {
      if (unit == "pieces" || unit == "g") { return Math.ceil(amount - 0.0001); }
      else { return Math.round(amount * 100) / 100; };
    }

    setAddressFromAddressComponents(address, address_components) {

      let street_number;


      for (const address_component of address_components) {
        if (address_component.types.includes('locality')) {
          address.city = address_component.long_name;
        } else if (address_component.types.includes('route')) {
          address.line1 = address_component.long_name;
        } else if (address_component.types.includes('postal_code')) {
          address.zipcode = address_component.long_name;
        } else if (address_component.types.includes('country')) {
          address.country = address_component.long_name;
          address.country_alpha2 = address_component.short_name;
        } else if (address_component.types.includes('street_number')) {
          address.street_number = address_component.long_name;
        }
      }

      this.addressUpdate.emit(this.address);

    }

    geocode() {
      const geocoder = new google.maps.Geocoder();
      const latlng = new google.maps.LatLng(this.geoAddress.latitude, this.geoAddress.longitude);
      const request = {
        latLng: latlng
      };

      geocoder.geocode(request, (results, status) => {
        if (status == google.maps.GeocoderStatus.OK) {
          if (results[0] != null) {

            this.setAddressFromAddressComponents(this.geoAddress, results[0].address_components);
            if (this.useCurrentLocation) {
              this.address = new Address();
              this.setAddressFromAddressComponents(this.address, results[0].address_components);
            }
            this.addressUpdate.emit(this.address);
            console.log('adress found')

          } else {
            alert('No address available');
          }
        } else {
          console.log(results);
          console.log(status);
        }
      });
    }

    setAddress(address: Address) {
      this.address = address;
      this.addressUpdate.emit(this.address);
    }

    subscribeToNotifications() {
      this.swPush.requestSubscription({
        serverPublicKey: this.VAPID_PUBLIC_KEY
      })
      .then(sub => {
        let subObject = JSON.parse(JSON.stringify(sub));

        this.user.webpush_endpoint = subObject.endpoint;
        this.user.webpush_p256dh = subObject.keys.p256dh;
        this.user.webpush_auth = subObject.keys.auth;

        this.updateProfile(this.user).subscribe(
          user => {
            //console.log("USER INFO HAS BEEN UPDATED WITH WEBPUSH INFO..");
          },
          error => {
            console.log(error);
          }
          );

      }).catch(err => console.warn("Could not subscribe to notifications", err));
    }

    removeDistLocation() {
      this.basket.order.dist_location_id = null;
      this.basket.order.dist_location = null;
      this.basket.order.dist_location_name = null;
      this.basket.order.dist_location_typecode = null;
      this.setValidDeliveryAddress(-1);
    }

    setValidDeliveryAddress(state) {
      this.basket.validDeliveryAddress = state;
      this.validDeliveryAddressEmitter.emit(state);
    }

    changeUserLanguage(lang) {
      if (this.user) {
        const user = { id: this.user.id, locale: lang };
        this.updateUserLanguage(user)
        .subscribe(
          user => {
            this.user.locale = user.locale;
            this.languageUpdate.emit(this.warnings);
          },
          error => {
            console.log('There is an error on updating user language');
          }
          );
      }
    }

    setSortNormal(sortNormal) {
      this.sortNormal = sortNormal;
      this.sortNormalSubject.next(this.sortNormal);
    }

    setPosCounterId(counter) {
      if (this.basket && this.basket.order) {
        this.setOrderLocationToCounter(counter);
      }
    }

    setOrderLocationToCounter(counter) {
      this.basket.order.order_location_id = counter.id;
      this.basket.order.order_location_name = counter.name;
      this.basket.order.order_location_typecode = counter.typecode;
    }

    hasUserLoyaltyPoints() {
      return this.user && this.user.user_loyalty_points;
    }

    getUserLoyaltyCard(qr_code) {
      return this.apiService.get('api/v1/user_loyalty_points/' + qr_code, this.user.access_token)
    }

    changeUserLoyaltyPoints(qr_code, amount) {
      let params = { amount: amount };
      return this.apiService.patch('api/v1/user_loyalty_points/' + qr_code, params, this.user.access_token)
    }

    updateUserLoyaltyPoint(merchant_id, user_loyalty_point) {
      let index = this.user.user_loyalty_points.findIndex(loyalty => { return loyalty.merchant.id === merchant_id});
      this.user.user_loyalty_points[index].credit = user_loyalty_point.credit;
    }

    getUserLoyaltyPoint(merchant_id) {
      return this.user.user_loyalty_points.find(loyalty => { return loyalty.merchant.id === merchant_id}); 
    }

    getUserOfBadge(badge_number) {
      return this.apiService.get('api/v1/users/badge?badge_number=' + badge_number, this.user.access_token);
    }

    isEmployee(merchant_id) {

      let is_employee = false;

      this.user.roles.forEach(role => {
        if (role.typecode == 'merchant_employee' && role.merchant_id == merchant_id) { is_employee = true; }
        if (role.typecode == 'merchant_admin' && role.merchant_id == merchant_id) { is_employee = false; }
        if (role.typecode == 'super_admin') { is_employee = false; }
      });

      return is_employee;

    }
  }
