import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';

import { animate, group, state, style, transition, trigger } from '@angular/animations';

import { TranslateService } from '@ngx-translate/core';
import { MerchantService } from '../services/merchant.service';
import { OrderService } from '../services/order.service';
import { UserService } from '../services/user.service';
import { UtilityService } from '../services/utility.service';

import { Location } from '@angular/common';

import { AppHelper } from '../helpers/app-helper';
import { StylingHelper } from '../helpers/styling-helper';

import { Order } from '../models/order';

import { ActivatedRoute, Router } from '@angular/router';

import { NgxSpinnerService } from 'ngx-spinner';

import * as Sentry from '@sentry/browser';

import { environment } from '../../environments/environment';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PosCashDialogComponent } from '../pos/components/pos-cash-dialog/pos-cash-dialog.component';

declare var cordova: any;

export const SlideInOutAnimation = [
	trigger('slideInOut', [
		state('void', style({
			'height': '60px', 'opacity': '0', 'visibility': 'visible'
		})),
		state('in', style({
			'height': '{{height}}', 'opacity': '1', 'visibility': 'visible'
		}), { params: { height: '350px' } }),
		//state('small', style({
		//	'height': '60px', 'opacity': '1', 'visibility': 'visible'
		//})),
		transition('void => small', animate('500ms 300ms ease-in-out')),
		transition('in => small', [group([
			animate('400ms ease-in-out', style({
				'opacity': '1.0'
			})),
			animate('600ms ease-in-out', style({
				'height': '60px'
			}))
		]
		)]),
		transition('small => in', [group([
			animate('600ms ease-in-out', style({
				'height': '{{height}}'
			}),),
			animate('800ms ease-in-out', style({
				'opacity': '1'
			}))
		]
		)], { params: { height: '350px' } })
	]),
];

export const FadeInOut = [

	trigger('fadeInOut', [
		state('void', style({
			opacity: 0
		})),
		transition('void => *', animate('500ms 300ms ease-in-out')),
		transition('* => void', animate('500ms ease-in-out'))
	])

];

@Component({
	selector: 'basketbar',
	templateUrl: './basketbar.component.html',
	styleUrls: ['./basketbar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [FadeInOut, SlideInOutAnimation]
})
export class BasketbarComponent implements OnInit, OnDestroy {

	public template: string;

	public animationState = 'small';
	public editable = true;
	public comment_editable = false;
	public primary_color;

	private timer: any;

	public disabled_reason = '';

	public isWorking: boolean = false;

	private stateUpdateSubscription: any;
	private validDeliveryAddressSubscription: any;

	public number_of_products = 0;
	public button_icon: any;
	public button_text: any;
	public is_disabled: boolean = false;
	public basketbar_height = '350px';
	public languageUpdateSubscription: any;
	public show_prices_consumer_screen = false;

	public whitelabel = "guide";

	public fol = false;

	@Input() backButtonShown = true;
	@Input() feature = 'basket';
	@Input() position = 'fixed';
	@Input() showDetails = true;
	@Input() validData = true;
	@Input() monizze_balance;
	@Input() pos_cash_payment_cancelled = false;
	@Output() completed = new EventEmitter<boolean>();
	@Output() orderCompleted = new EventEmitter<boolean>();
	@Output() animationStateChanged = new EventEmitter<string>();
	@Output() posCashOrder = new EventEmitter<Order>();
	@Output() paymentStartedWithPosConsumerScreen = new EventEmitter<string>();
	@Output() paymentCompleted = new EventEmitter<boolean>();

	constructor(
		public userService: UserService,
		public orderService: OrderService,
		public merchantService: MerchantService,
		private dialog: MatDialog,
		private router: Router,
		private route: ActivatedRoute,
		private util: UtilityService,
		private location: Location,
		private translate: TranslateService,
		private spinner: NgxSpinnerService,
		private appHelper: AppHelper,
		private stylingHelper: StylingHelper,
		private cd: ChangeDetectorRef) {

		this.animationState = 'small';
		this.animationStateChanged.emit(this.animationState);
		this.template = this.stylingHelper.getTemplate(this.merchantService.merchant.settings["s_template"]);
		if (this.merchantService.kiosk) {
			this.template = 'template_kiosk';
			this.basketbar_height = '50%';
			//this.animationState = 'in';
		};

		this.fol = this.appHelper.isFol();

	}

	orderExists() {
		return this.userService.basket && this.userService.basket.order;
	}

	ngOnInit() {

		this.whitelabel = environment.whitelabel;

		if (this.orderExists() && this.feature == 'payment' && ['table', 'waiter', 'roomservice'].indexOf(this.userService.basket.order.scenario) == -1) { this.editable = false; }
		if (this.orderExists() && this.feature == 'final' && ['table', 'waiter', 'roomservice'].indexOf(this.userService.basket.order.scenario) == -1) { this.editable = false; }
		if (this.editable && this.merchantService.hasFeature(this.merchantService.merchant.id, "f_comment_on_orderline") && !this.merchantService.kiosk) { this.comment_editable = true; }
		if (this.feature == 'pos_consumer') { 
			this.subscribeToBasketInLocalStorage();
			this.checkWhetherPricesShouldBeShown();
		}
		this.primary_color = this.appHelper.getMerchantPrimaryColor();
		//this.verifyPaymentStatus();

		this.stateUpdateSubscription = this.userService.stateUpdate.subscribe((updateOngoing) => {
			//console.log("######## UPDATE ONGOING ########### " + updateOngoing);
			if (updateOngoing) { this.spinner.show('basketbar'); }
			else {
				this.spinner.hide('basketbar');
				this.setValues();
			}
			this.cd.detectChanges();
		});

		this.validDeliveryAddressSubscription = this.userService.validDeliveryAddressEmitter.subscribe((state) => {
			this.setValues();
		});

		this.languageUpdateSubscription = this.userService.languageUpdate.subscribe((event) => {
			this.onLanguageChanged();
    });

		this.spinner.hide('basketbar');
		this.setValues();
	}

	subscribeToBasketInLocalStorage() {
		this.userService.basket = JSON.parse(localStorage.getItem('basket'));
		
		if (window.addEventListener) {
      window.addEventListener("storage", this.updateBasket, false);
    }
	}

	updateBasket = () => {
    this.userService.basket = JSON.parse(localStorage.getItem('basket'));
		this.setValues();
  }

	ngOnDestroy() {

		if (this.stateUpdateSubscription) { this.stateUpdateSubscription.unsubscribe(); }
		if (this.validDeliveryAddressSubscription) { this.validDeliveryAddressSubscription.unsubscribe(); }
		if (this.languageUpdateSubscription) { this.languageUpdateSubscription.unsubscribe(); }
	}

	toggleShowDiv(event) {
		//if (window.innerWidth < 992) { 
		this.animationState = this.animationState === 'small' ? 'in' : 'small';
		//} else { this.animationState = 'small'; }
		this.animationStateChanged.emit(this.animationState);
		event.stopPropagation();

		/*setTimeout(() =>
			{
				console.log("AND THEN WE MAKE THE ORDERLINES VISIBLE!!!");
			},
			5000);*/
	}

	goToBasket(event) {
		this.spinner.show('basketbar');
		this.router.navigate(['../basket'], { relativeTo: this.route });
		event.stopPropagation();
		this.spinner.hide('basketbar');
	}

	goToCheckout(event) {
		this.spinner.show('basketbar');
		this.router.navigate(['../checkout'], { relativeTo: this.route });
		if (event) event.stopPropagation();
		this.spinner.hide('basketbar');
	}

	goToCheckoutKiosk(event) {
		this.spinner.show('basketbar');
		this.router.navigate(['../checkout-kiosk'], { relativeTo: this.route });
		event.stopPropagation();
		this.spinner.hide('basketbar');
	}

	goToPayment(event) {
		this.spinner.show('basketbar');


		this.orderService.updateOrder(this.userService.basket.order, false).subscribe(
			response => {
				this.appHelper.setBasketOrder(response, this.merchantService.merchant);
				this.router.navigate(['../payment'], { relativeTo: this.route });
				this.spinner.hide('basketbar');
			},
			response => {
				Sentry.captureException(JSON.stringify(response.error));
				if (response.error && response.error.errors && response.error.errors.base) { this.util.openSnackBar(response.error.errors.base[0], this.translate.instant('ok')); }
				this.spinner.hide('basketbar');
			}
		);

		event.stopPropagation();
	}



	goToVenue(event) {

		this.spinner.show('basketbar');

		this.orderService.updateOrder(this.userService.getBasket().order, false)
			.subscribe(
				(res: any) => {
					this.spinner.hide('basketbar');
					this.appHelper.setBasketOrder(res, this.merchantService.merchant);
					this.appHelper.updateCheckoutFieldsV2().subscribe(response => { });
					this.completed.emit(true);
				},
				err => {
					this.spinner.hide('basketbar');
				});

	}

	goNext(event) {


		if (this.isWorking) { return; }

		this.userService.saveBasketInStorage();

		if (this.feature == 'menu') {
			this.goToVenue(event);
		}
		else if (this.feature == 'checkout-kiosk') { this.goToCheckoutKiosk(event); }
		else if (this.feature == 'basket') { this.goToBasket(event); }
		else if (this.feature == 'checkout') { this.goToCheckout(event); }
		else if (this.feature == 'payment') { this.goToPayment(event); }
		else if (this.feature == 'final') { this.sendOrder(event); }
		else if (this.feature == 'pos') { this.sendOrder(event); }
	}

	verifyComment() {

		if (!this.merchantService.kiosk) {
			if (this.merchantService.merchant.settings.s_comment_enabled && this.merchantService.merchant.settings.s_comment_required && !this.userService.basket.order.comment) {
				this.is_disabled = true;
				this.disabled_reason += this.translate.instant('basketbar.comment_required_1') + this.merchantService.merchant.comment_title + this.translate.instant('basketbar.comment_required_2');
			}
		} else {
			if (this.merchantService.kiosk.comment_required && !this.userService.basket.order.comment) {
				this.is_disabled = true;
				this.disabled_reason += this.translate.instant('basketbar.comment_required_1') + this.merchantService.merchant.comment_title + this.translate.instant('basketbar.comment_required_2');
			}
		}

		if (this.userService.basket.order.comment) {
			let comment_characters = 50;
			if (this.merchantService.merchant.settings.s_comment_characters) { comment_characters = this.merchantService.merchant.settings.s_comment_characters; };

			if (this.userService.basket.order.comment.length > comment_characters) {
				this.is_disabled = true;
				this.disabled_reason += this.merchantService.merchant.comment_title + ' mag maximum ' + comment_characters + ' tekens lang zijn.';
			};
		}

	}

	isDisabled() {

		this.is_disabled = false;
		this.disabled_reason = '';

		if (!this.validData) {
			this.is_disabled = true;
		}
		if (this.merchantService.merchant && this.merchantService.merchant.level_of_activity > 1) {
			//this.is_disabled = true; 
		}

		if (!this.orderExists()) {
			this.is_disabled = true;

		} else if (this.feature == 'menu') {

			if (this.userService.basket.order.scenario == 'catering' && !this.userService.basket.order.location) {
				this.is_disabled = true;
				this.disabled_reason += this.translate.instant('basketbar.catering_location_missing')
			} else if (this.userService.basket.order.scenario == 'roomservice' && !this.userService.basket.order.dist_location_id) {
				this.is_disabled = true;
			} else if (['waiter', 'roomservice'].indexOf(this.userService.basket.order.scenario) > -1 && !this.userService.basket.order.dist_location_id) {
				this.is_disabled = true;
			}

		} else if (this.feature == 'payment') {

			if (this.userService.basket.order.scenario == 'roomservice' && !this.userService.basket.order.dist_location_id) {
				this.is_disabled = true;
			}

			if (this.userService.basket.order.scenario == 'delivery' && this.userService.basket.validDeliveryAddress < 1) {
				this.is_disabled = true;
			}
			if (this.userService.basket.order.dist_timing == 'asap') {

				if (this.merchantService.merchant && !this.merchantService.merchant.is_open) {
					this.is_disabled = true;
				}
				else if (this.userService.basket.checkoutFields && this.userService.basket.checkoutFields.dist_timing.find(x => x.value == 'asap' && !x.is_enabled)) {
					this.is_disabled = true;
				}

			}

			if (this.userService.basket.order.dist_timing == 'timed' && !this.userService.basket.order.dist_timing_target) {
				this.is_disabled = true;
			}

			this.verifyComment();

			if (this.merchantService.merchant.settings.s_reference_required && !this.userService.basket.order.reference) {
				this.is_disabled = true;
				this.disabled_reason += this.translate.instant('basketbar.name_missing');
			}
		} else if (this.feature == 'final') {

			this.verifyComment();

			//in case comment should be required for kiosk
			if (!this.merchantService.kiosk &&
				this.merchantService.merchant.settings.s_reference_required && !this.userService.basket.order.reference) {
				this.is_disabled = true;
				this.disabled_reason += this.translate.instant('basketbar.name_missing');
			}

			if (this.merchantService.kiosk && this.merchantService.kiosk.buzzer_required && !this.userService.basket.order.reference) {
				this.is_disabled = true;
				this.disabled_reason += this.translate.instant('basketbar.buzzer_number_missing');
			}

			if (this.merchantService.kiosk && this.merchantService.kiosk.reference_required && !this.userService.basket.order.reference) {
				this.is_disabled = true;
				this.disabled_reason += this.translate.instant('basketbar.name_missing');
			}

			if (this.userService.basket.order.scenario == 'roomservice' && !this.userService.basket.order.dist_location_id) {
				this.is_disabled = true;
			}

			if (this.userService.basket.advisories && this.userService.basket.advisories.warning && this.userService.basket.advisories.warning.length > 0) {
				this.is_disabled = true;
			}
			if (!this.userService.basket.order.payment_method && !(this.userService.basket.order.group_order && this.userService.basket.order.group_who_pays == 'user') && this.userService.basket.order.order_type == 'personal') {
				this.is_disabled = true;
			}
			if (this.userService.basket.order.payment_method && this.monizze_balance && this.userService.basket.order.payment_method == 'MONIZZEMEAL' && this.userService.basket.order.price > (this.monizze_balance / 100)) {
				this.is_disabled = true;
				this.disabled_reason = this.translate.instant('basketbar.monizze_balance_insufficient')

			}
			if (this.userService.basket.order.payment_method && this.monizze_balance && this.userService.basket.order.payment_method == 'MONIZZEMEAL' && this.userService.basket.order.price > (this.monizze_balance / 100)) {
				this.is_disabled = true;
				this.disabled_reason = this.translate.instant('basketbar.monizze_balance_insufficient')
			}
			if (this.userService.basket.order.payment_method && this.userService.basket.order.payment_method == 'WALLET') { //&& this.userService.basket.order.price > (this.monizze_balance/100)) { 

				//need to verify what is balance of the wallet
				let ulp = null;
				if (this.userService.hasUserLoyaltyPoints()) {
					ulp = this.userService.getUserLoyaltyPoint(this.merchantService.merchant.id);
					console.log(ulp);
				};

				if (!ulp || ulp.credit < this.userService.basket.order.price) {
					this.is_disabled = true;
					this.disabled_reason = this.translate.instant('basketbar.wallet_balance_insufficient')
						;
				}

			}
		}

		/*
		if (this.userService.basket && this.userService.basket.order && this.userService.basket.order.reservation_id) {
			this.is_disabled = false;
			this.disabled_reason = null;
		}*/

	}

	sendOrder(event) {

		this.spinner.show('basketbar');
		this.isWorking = true;

		//we build the coupn coupon_codes
		this.userService.basket.order.coupon_codes = this.orderService.buildCouponCodes();
		this.orderService.updateOrder(this.userService.basket.order, true).subscribe(
			response => {
				this.sendOrderStep2();
			},
			response => {
				if (response.error && response.error.errors && response.error.errors.base) { this.util.openSnackBar(response.error.errors.base[0], this.translate.instant('ok')); }
				Sentry.captureException(JSON.stringify(response.error));
				this.spinner.hide('basketbar');
				this.isWorking = false;
			}
		);

		event.stopPropagation();
	}

	sendOrderStep2() {

		this.appHelper.getCheckoutFieldsForOrder().subscribe(
			respone => {
				let scenario_verified = false;
				let timing_verified = false;

				let scenario_verify = this.userService.basket.checkoutFields.scenario.find(scenario => { return scenario.value == this.userService.basket.order.scenario; });
				if (scenario_verify && scenario_verify.is_supported && scenario_verify.is_enabled) { scenario_verified = true; }

				let timing_verify = this.userService.basket.checkoutFields.dist_timing.find(dist_timing => { return dist_timing.value == this.userService.basket.order.dist_timing; });
				if (timing_verify && timing_verify.is_supported && timing_verify.is_enabled) { timing_verified = true; }

				if (this.userService.basket.order.dist_timing == 'timed') {
					timing_verified = false;
					for (let dist_timing_target of this.userService.basket.checkoutFields.dist_timing_target) {
						let slot_verify = dist_timing_target.slots.find(slot => { return slot.value == this.userService.basket.order.dist_timing_target });
						if (slot_verify && slot_verify.is_enabled) { timing_verified = true; }
					}
				}

				if (!scenario_verified || !timing_verified) {
					//this.util.openSnackBar(this.translate.instant('back_to_checkout'), this.translate.instant('ok'), -1);
					this.appHelper.updateCheckoutFieldsV2().subscribe(response => { });
					this.goToCheckout(null);
				} else {
					this.sendOrderStep3();
				}


			}, error => {
				this.util.openSnackBar(this.translate.instant('back_to_checkout'), this.translate.instant('ok'), -1);
			});
	}

	updateLoyaltyCard(res) {
		this.userService.updateUserLoyaltyPoint(this.merchantService.merchant.id, res.user_loyalty_point);
	}

	doRoutingAfterOrdering() {
		this.orderCompleted.emit(true);
		if (this.merchantService.pos) {
			//nothing to be done	
		} else {
			this.router.navigate(['../order-status'], { relativeTo: this.route });
		}
	}

	sendOrderStep3() {

		//if nothing anymore to pay, then handle as a 'cash' order
		if (this.userService.basket.order.price_after_coupons == 0) { this.userService.basket.order.payment_method = 'CASH'; }

		if (this.userService.basket.order.payment_state == 'paid') {

			this.userService.basket.order.coupon_codes = this.orderService.buildCouponCodes();
			this.userService.basket.order.state = 'sent';

			this.orderService.updateOrder(this.userService.basket.order, true).subscribe(
				response => {
					this.userService.basket.order = response;
					this.doRoutingAfterOrdering();

				},
				response => {
					Sentry.captureException(JSON.stringify(response.error));
					if (response.error && response.error.errors && response.error.errors.base) { this.util.openSnackBar(response.error.errors.base[0], this.translate.instant('ok')); }
				});


		} else if (this.userService.basket.order.order_type == 'group' && (!this.userService.basket.order.group_who_pays || this.userService.basket.order.group_who_pays == 'owner')) {
			this.handleGroup();

		} else if (this.userService.basket.order.payment_method && this.userService.basket.order.payment_method.indexOf('MONIZZE') != -1) {

			if (this.merchantService.merchant.payment_credential_monizze == true) {

				if (!this.userService.user.monizze) {

					let monizze_url = 'https://auth.monizze.be/en/oauth/authorize?response_type=code&client_id=7&redirect_uri= https://prd.one2three.network/api/v2/monizze/oauth_redirect&state=' + this.userService.basket.order.id;
					const target = '_system';
					if (window.hasOwnProperty('cordova')) { cordova.InAppBrowser.open(monizze_url, target); }
					else { window.location.href = monizze_url; }

				} else {

					this.orderService
						.monizzePayment(this.userService.basket.order, null)
						.subscribe(
							(response: any) => {
								//this.userService.basket.order = response.order;
								if (response.status.message == 'Accepted') {
									this.doRoutingAfterOrdering();
								} else {
									this.spinner.hide('basketbar');
									this.isWorking = false;
									this.util.openSnackBar(this.translate.instant('monizze.' + response.status.errors), this.translate.instant('ok'));
								}
							},
							err => {
								this.spinner.hide('basketbar');
								this.isWorking = false;
								console.log(err);
								Sentry.captureException(JSON.stringify(err));
							}
						);

				}

			} else {
				this.orderService
					.initiatePayment("monizze")
					.subscribe(
						(res: any) => {
							const checkoutInfo = res;
							console.log(res);

							//no token
							let monizzeActionUrl = 'https://www.monizze.be/en/online/pay/';

							//if token
							//if ($rootScope.me && $rootScope.me.token && $scope.useToken) monizzeActionUrl = ENV.endpoint + 'api/v1/orders/monizze_token';

							(document as any).monizzeForm.action = monizzeActionUrl;
							(<HTMLInputElement>document.getElementById("SIGN")).value = res.checkout_id;
							(document.getElementById("monizzeForm") as any).submit();
						},
						err => {
							console.log(err);
							Sentry.captureException(JSON.stringify(err));
						}
					);
			}

		} else if (this.userService.basket.order.device_type == 'Kiosk' && this.userService.basket.order.payment_method.includes("BCMCMOLLIE")) {

			var payment_method = 'bancontact';

			this.orderService
				.initiatePayment("mollie", payment_method)
				.subscribe(
					(response: any) => {
						this.spinner.hide('basketbar');
						this.isWorking = false;
						this.userService.basket.order.bancontact = response;
						{ this.router.navigate(['../bancontact'], { relativeTo: this.route }); }
					},
					err => {
						this.spinner.hide('basketbar');
						this.isWorking = false;
						console.log(err);
						Sentry.captureException(JSON.stringify(err));
					}
				);

		} else if (this.userService.basket.order.payment_method && this.userService.basket.order.payment_method.indexOf('MOLLIE') != -1) {

			var payment_method = "mistercash";
			if (this.userService.basket.order.payment_method.includes("CCMOLLIE")) { payment_method = "creditcard" }
			else if (this.userService.basket.order.payment_method.includes("PAYPAL")) { payment_method = "paypal" }
			else if (this.userService.basket.order.payment_method.includes("IDEAL")) { payment_method = "ideal" }
			else if (this.userService.basket.order.payment_method.includes("APPLEPAY")) { payment_method = "applepay" }
			else if (this.userService.basket.order.payment_method.includes("TERMINAL")) { payment_method = "pointofsale" }
			
			this.orderService
				.initiatePayment("mollie", payment_method)
				.subscribe(
					(res: any) => {
						const checkoutInfo = res;
						const target = '_system';
						this.spinner.hide('basketbar');
						this.isWorking = false;
						this.userService.basket.order.mollie = res;

						if (!this.merchantService.pos) {

							let href: any;

							if (checkoutInfo.links) {
								href = checkoutInfo.links.paymentUrl;
							} else {
								href = checkoutInfo._links.checkout.href;
							}

							if (window.hasOwnProperty('cordova')) { cordova.InAppBrowser.open(href, target); }
							else { window.location.href = href; }

						} 
						// else if (localStorage && localStorage.getItem('posConsumerScreenOpen')) {

						// 	this.userService.setPaymentOngoing(true);
						// }

					},
					err => {
						this.spinner.hide('basketbar');
						this.isWorking = false;
						console.log(err);
						Sentry.captureException(JSON.stringify(err));
					}
				);

		} else if (this.userService.basket.order.payment_method && this.userService.basket.order.payment_method.indexOf('WORLDLINE') != -1) {


			this.orderService
				.initiatePayment("worldline", "WORLDLINE")
				.subscribe(
					(res: any) => {
						console.log(res);
						console.log(res.error);
						this.spinner.hide('basketbar');
						this.isWorking = false;
						this.userService.basket.order.worldline = res;
						this.userService.saveBasketInStorage();
						if (res.error == 0) {

							if (!this.merchantService.pos)
								this.router.navigate(['../worldline'], { relativeTo: this.route });
							else {
								this.userService.setPaymentOngoing(true);
							}

						} else {
							this.util.openSnackBar(this.translate.instant('basketbar.terminal_error') + ' ("' + res.errormsg + '")', this.translate.instant('ok'));
						}
					},
					err => {
						this.spinner.hide('basketbar');
						this.isWorking = false;
						this.util.openSnackBar(this.translate.instant('basketbar.terminal_error'), this.translate.instant('ok'));
						console.log(err);
						Sentry.captureException(JSON.stringify(err));
					}
				);

		} else if (this.userService.basket.order.payment_method && this.userService.basket.order.payment_method.indexOf('CCV') != -1) {


			this.orderService
				.initiatePayment("ccv", "CCV")
				.subscribe(
					(res: any) => {
						console.log(res);
						console.log(res.error);
						this.spinner.hide('basketbar');
						this.isWorking = false;
						this.userService.basket.order.ccv = res;
						this.userService.saveBasketInStorage();
						if (!res.failureCode) {
							if (!this.merchantService.pos)
								this.router.navigate(['../ccv'], { relativeTo: this.route });
							else {
								this.userService.setPaymentOngoing(true);
							}
						} else {
							this.util.openSnackBar(this.translate.instant('basketbar.terminal_error'), this.translate.instant('ok'));
						}
					},
					err => {
						this.spinner.hide('basketbar');
						this.isWorking = false;
						this.util.openSnackBar(this.translate.instant('basketbar.terminal_error'), this.translate.instant('ok'));
						console.log(err);
						Sentry.captureException(JSON.stringify(err));
					}
				);

		} else if (this.userService.basket.order.payment_method && this.userService.basket.order.payment_method.indexOf('PAYCONIQ') != -1) {
			if (window.hasOwnProperty('cordova')) {

				this.orderService
					.initiatePayment("payconiq_api")
					.subscribe(
						(res: any) => {
							this.spinner.hide('basketbar');
							this.isWorking = false;
							const checkoutInfo = res.transactionId;
							const url = 'https://payconiq.com/pay/2/' + checkoutInfo + '?returnUrl=one2threev2://';
							const target = '_system';
							cordova.InAppBrowser.open(url, target);
						});

			} else {

				this.orderService
					.initiatePayment("payconiq_api")
					.subscribe(
						(response: any) => {
							this.spinner.hide('basketbar');
							this.isWorking = false;
							this.userService.basket.order.payconiq = response;
							this.userService.saveBasketInStorage();
							// if (localStorage && localStorage.getItem('posConsumerScreenOpen') == "true") { 
								// this.userService.setPaymentOngoing(true);
							// } else {
								this.router.navigate(['../payconiq'], { relativeTo: this.route }); 
								if (this.feature == 'pos') {
									this.router.navigate(['../../payconiq'], { relativeTo: this.route }); 
								} else {
									this.router.navigate(['../payconiq'], { relativeTo: this.route });
								}
							// }
						},
						err => {
							console.log(err);
							Sentry.captureException(JSON.stringify(err));
						}
					);

			}

		} else if (this.userService.basket.order.payment_method && this.userService.basket.order.payment_method == "WALLET") {

			this.orderService
				.initiatePayment("wallet", "WALLET")
				.subscribe(
					(res: any) => {
						console.log(res);
						console.log(res.error);
						this.spinner.hide('basketbar');
						this.isWorking = false;
						this.updateLoyaltyCard(res)
						this.doRoutingAfterOrdering();
					},
					err => {
						this.spinner.hide('basketbar');
						this.isWorking = false;
						this.util.openSnackBar(this.translate.instant('basketbar.wallet_error'), this.translate.instant('ok'));
						console.log(err);
						Sentry.captureException(JSON.stringify(err));
					}
			);

		} else if (!this.userService.basket.order.payment_method ||

			this.userService.basket.order.payment_method == 'CASH' ||
			this.userService.basket.order.payment_method == 'INVOICE' ||
			this.userService.basket.order.payment_method == 'PAY_LATER') {

			this.userService.basket.order.coupon_codes = this.orderService.buildCouponCodes();

			if (this.feature == "pos") {
				this.posCashOrder.emit(this.userService.basket.order);
				return
			}

			//review or sent, depending the group details
			if (this.userService.basket.order.order_type == 'group') this.userService.basket.order.state = 'review';
			else this.userService.basket.order.state = 'sent';

			this.orderService.updateOrder(this.userService.basket.order, true).subscribe(
				response => {
					this.userService.basket.order = response;
					this.doRoutingAfterOrdering();
				},
				response => {
					console.log(response);
					Sentry.captureException(JSON.stringify(response.error));
					if (response.error && response.error.errors && response.error.errors.base) { this.util.openSnackBar(response.error.errors.base[0], this.translate.instant('ok')); }
				});

		}/* else if (this.orderService.isMolliePayment(this.data.order)) {

      this.orderService.updateOrder(this.data.order, true).subscribe(
        response => {
          this.data.order = response;
          console.log("WE HAVE CREATED THE ORDER => ");


          var paymentMethod = "mistercash";
          if (this.data.order.payment_method.includes("CCMOLLIE"))       paymentMethod = "creditcard";
          else if (this.data.order.payment_method.includes("BITCOINMOLLIE"))   paymentMethod = "bitcoin";
          else if (this.data.order.payment_method.includes("PAYPALMOLLIE"))   paymentMethod = "paypal";
          else if (this.data.order.payment_method.includes("IDEALMOLLIE"))   paymentMethod = "ideal";

          var payload:any = {};

          payload.pm        = "mollie";
          payload.method       = paymentMethod;
          payload.final_price   = this.data.order.price;

          console.log(payload);

          this.orderService.getCheckout(this.data.order, JSON.stringify(payload)).subscribe(
            response => {
              console.log(response);
              var payment_url = response.links.paymentUrl;
              window.location.href = payment_url;
            },
            error => {
              console.log(error);
            },
            () => {
              this.sending = false;
            });

        },
        error => {
          console.log(error);
        });

		}
		*/

	}

	handleGroup() {
		this.userService.basket.order.state = 'review';

		this.orderService.updateOrder(this.userService.basket.order, true).subscribe(
			response => {
				this.userService.basket.order = response;
				this.doRoutingAfterOrdering();
			},
			response => {
				console.log(response);
				Sentry.captureException(JSON.stringify(response.error));
				if (response.error && response.error.errors && response.error.errors.base) { this.util.openSnackBar(response.error.errors.base[0], this.translate.instant('ok')); }
			});

	}

	onClickedOutside(event) {
		this.animationState = 'small';
		this.animationStateChanged.emit(this.animationState);
	}

	setValues() {
		this.button_icon = this.getButtonIcon();
		this.button_text = this.getButtonText();
		this.isDisabled();
		this.number_of_products = this.numberOfProducts();
		this.cd.detectChanges();
	}

	onLanguageChanged() {
		this.button_text = this.getButtonText();
		this.cd.detectChanges();
	}

	numberOfProducts() {
		let amount = 0;
		if (this.userService.basket && this.userService.basket.order && this.userService.basket.order.order_lines) {
			for (const order_line of this.userService.basket.order.order_lines) {
				if (order_line.order_options.length == 0) {
					amount += order_line.amount;
				} else {
					//order_options with a unit?
					let options_with_unit = order_line.order_options.filter(order_option => { return order_option.base_unit != null });
					if (options_with_unit && options_with_unit.length > 0) {
						amount += 1;
					} else { amount += order_line.amount };
				}
			}
		}
		return amount;
	}

	checkWhetherPricesShouldBeShown() {
		this.userService.getShowPricesConsumerScreen().subscribe(
			(show_prices_consumer_screen) => {
				this.show_prices_consumer_screen = show_prices_consumer_screen;
				this.cd.detectChanges();
			}
		);
	}

	goBack(event) {
		this.location.back();
	}

	getButtonIcon() {
		if (this.feature == 'menu') return 'Bekijk menu';
		else if (this.feature == 'basket') return 'shopping_cart';
		else if (this.feature == 'checkout-kiosk') return 'done';
		else if (this.feature == 'checkout') return 'done';
		else if (this.feature == 'payment') return 'payment';
		else if (this.feature == 'final') return 'check';
		else if (this.feature == 'pos') return 'check';
	}

	getButtonText() {
		if (this.feature == 'menu') return this.translate.instant('basketbar.show_menu');
		else if (this.feature == 'basket') return this.translate.instant('basketbar.view_order');
		else if (this.feature == 'checkout-kiosk') return this.translate.instant('basketbar.complete_order');
		else if (this.feature == 'checkout') return this.translate.instant('basketbar.complete_order');
		else if (this.feature == 'payment') return this.translate.instant('basketbar.pay_order');
		else if (this.feature == 'final') return this.translate.instant('basketbar.confirm_order');
		else if (this.feature == 'pos') return this.translate.instant('basketbar.confirm_order');
	}

	ngOnChanges(changes: SimpleChanges) {
		if (this.pos_cash_payment_cancelled == true) {
			this.onCancelPosCashOrder()
		}
	}

	onCancelPosCashOrder() {
		this.spinner.hide('basketbar');
		this.isWorking = false;
	}

	onPosCashOrder(order) {

	    //this.cashPaymentCancelled = false;
	    const dialogConfig = new MatDialogConfig();

	    dialogConfig.data = {
	      order: order
	    };
	    dialogConfig.disableClose = true;

	    const dialogRef = this.dialog.open(PosCashDialogComponent, dialogConfig)

	    dialogRef.afterClosed().subscribe(
	      data => {
	        if (data && data.status == "completed") {
	          this.paymentCompleted.emit(true);
	        } else {
	       	  this.paymentCompleted.emit(false);
	        }
	      }
	    );
	 }

	doPayment() {

	    if (!this.userService.basket.order.payment_method ||
	      this.userService.basket.order.payment_method == 'CASH' ||
	      this.userService.basket.order.payment_method == 'INVOICE' ||
	      this.userService.basket.order.payment_method == 'PAY_LATER') {
			this.onPosCashOrder(this.userService.basket.order);
	    }
      
  	}

}
