// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Dosis:wght@700&family=Permanent+Marker&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "h1.title {\n  font-size: 45px;\n  line-height: 1em;\n  color: #f0513f;\n  margin-bottom: 30px;\n  font-family: \"Permanent Marker\";\n}\n\np {\n  font-family: \"Dosis\";\n}\n\na.btnPrimary {\n  text-decoration: none;\n  font-family: \"Permanent Marker\";\n  text-align: center;\n  border-radius: 50px;\n  padding: 9px 15px;\n  font-size: 20px;\n  width: 100%;\n  outline: 0;\n  border: 0;\n  background-color: #f0513f;\n  color: #fdd329;\n  transition: all 0.5s ease;\n  box-shadow: 0 0 0 0 transparent;\n}", "",{"version":3,"sources":["webpack://./src/app/about-fol/about-fol.component.scss"],"names":[],"mappings":"AAGA;EACE,eAAA;EACA,gBAAA;EACA,cAAA;EACA,mBAAA;EACA,+BAAA;AAAF;;AAGA;EACE,oBAAA;AAAF;;AAGA;EACE,qBAAA;EACA,+BAAA;EACA,kBAAA;EACA,mBAAA;EACA,iBAAA;EACA,eAAA;EACA,WAAA;EACA,UAAA;EACA,SAAA;EACA,yBAAA;EACA,cAAA;EAGA,yBAAA;EAEA,+BAAA;AAAF","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Dosis:wght@700&family=Permanent+Marker&display=swap\");\n@import url(\"https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap\");\nh1.title {\n  font-size: 45px;\n  line-height: 1em;\n  color: #f0513f;\n  margin-bottom: 30px;\n  font-family: \"Permanent Marker\";\n}\n\np {\n  font-family: \"Dosis\";\n}\n\na.btnPrimary {\n  text-decoration: none;\n  font-family: \"Permanent Marker\";\n  text-align: center;\n  border-radius: 50px;\n  padding: 9px 15px;\n  font-size: 20px;\n  width: 100%;\n  outline: 0;\n  border: 0;\n  background-color: #f0513f;\n  color: #fdd329;\n  -webkit-transition: all 0.5s ease;\n  -o-transition: all 0.5s ease;\n  transition: all 0.5s ease;\n  -webkit-box-shadow: 0 0 0 0 transparent;\n  box-shadow: 0 0 0 0 transparent;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
