import { MerchantService } from '../services/merchant.service';
import { UserService } from '../services/user.service';

import { Merchant } from '../models/merchant';

import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';

import { distinctUntilChanged, debounceTime, filter, map, tap, switchMap, catchError } from 'rxjs/operators';
import { fromEvent, Subject, Observable, of, observable } from 'rxjs';

import { AppHelper } from '../helpers/app-helper';
import { FeaturesHelper } from '../helpers/features-helper';

import { ScenarioSheetComponent } from '../sheets/scenario-sheet/scenario-sheet.component';
import { VenueFiltersComponent } from '../venue-filters/venue-filters.component';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';

import { NgxSpinnerService } from 'ngx-spinner';

import { trigger, state, style, animate, transition } from '@angular/animations';
import { environment } from '../../environments/environment';

import { faChevronDown, faCheck, faMap, faList, faFilter,faFrenchFries, faHeart } from '@fortawesome/pro-regular-svg-icons';
import { faHeart as faSolidHeart} from '@fortawesome/pro-solid-svg-icons';
import { faFacebookF, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

export interface valuesOfFilters {
  filter_on_loyalty_points: boolean;
  filter_on_order_and_reward: boolean;
  filter_on_open_today: boolean;
  filter_on_favourite_merchants: boolean;
  selected_kitchen_type: string;
  selected_dish_type: string;
  user_logged_in: boolean;
}

export interface FolMarkers {
  merchant: Merchant;
  marker: google.maps.marker.AdvancedMarkerView;
}

@Component({
  selector: 'app-venues',
  templateUrl: './venues.component.html',
  styleUrls: ['./venues.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition('void <=> *', animate(1000)),
      ])
    ]
})
export class VenuesComponent implements OnInit, AfterViewInit, OnDestroy {

  public faChevronDown = faChevronDown;
  public faCheck = faCheck;
  public faMap = faMap;
  public faList = faList;
  public faFilter = faFilter;
  public faFrenchFries = faFrenchFries;
  public faHeart = faHeart;
  public faSolidHeart = faSolidHeart;
  public faFacebook = faFacebookF;
  public faInstagram = faInstagram;
  public faTwitter = faTwitter;

  public kitchen_types = ['bakery', 'butcher', 'camping', 'cheese', 'chip_shop', 'cocktails', 'event', 'foodtruck', 'icecream', 'industrial', 'leisure', 'petrol', 'pizzeria', 'pub', 'restaurant', 'sandwich', 'supermarket', 'sushi']
  public dish_types = ["alcoholic_drink", "american_pizza", "sandwiches", "burgers", "curry", "desserts", "durum", "fries", "fresh_drinks", "soft_drinks", "gourmet_burgers", "halal", "home-made_burgers", "hotdogs", "ice_cream", "kapsalon", "chicken", "cold_dishes", "limousine_burgers", "machine_guns", "noodles", "breakfast", "panini", "pasta", "pita_kebab", "pizza", "poke_bowls", "ribs", "salads", "soups", "sushi", "pies", "taco", "thai", "vegan", "vegetarian", "meat", "wine", "wrap"]
  public selected_kitchen_type = null;
  public selected_dish_type = null;
  public filter_on_loyalty_points = false;
  public filter_on_order_and_reward = false;
  public filter_on_open_today = false;
  public filter_on_favourite_merchants = false;
  public filter_count = null;
  public filters = [];
  public tags = [];
  public user_logged_in = false;
  public values_of_filters = { 
    "filter_on_loyalty_points": this.filter_on_loyalty_points,
    "filter_on_order_and_reward": this.filter_on_order_and_reward,
    "filter_on_open_today": this.filter_on_open_today,
    "filter_on_favourite_merchants": this.filter_on_favourite_merchants,
    "selected_kitchen_type": this.selected_kitchen_type,
    "selected_dish_type": this.selected_dish_type
  };

  public merchants = [];
  public total_entries: any;
  public switch = 'list';
  public searchActive = false;
  public selectedMerchant: Merchant;
  public searchText: string;
  public whitelabel = environment.whitelabel;
  public whitelabel_for_scenarios = environment.whitelabel;
  public fol = false;
  
  private filter: string;

  public mapLatitude;
  public mapLongitude;
  public folMarkers: FolMarkers[] = [];

  private userUpdateSubscription;

  public community;

  public load_more = true;
  public load_page = 1;

  private observer: IntersectionObserver;

  private gps_requested = true;
  public will_redirect = true;

  public opening_times = null;

  public markers = []
  public map: any;

  @ViewChild('anchor') anchor: ElementRef<HTMLElement>;
  @ViewChild('merchantSearch') merchantSearchInput: ElementRef;
  @ViewChild('map', {read: ElementRef}) googleMap: ElementRef;
  @ViewChild('mapButton') mapButton: ElementRef;
  @ViewChild('divForOneMerchant') divForOneMerchant: ElementRef;


  constructor(
  	public merchantService: MerchantService,
  	public userService: UserService,
  	public router: Router,
    private route: ActivatedRoute,
    private bottomSheet: MatBottomSheet,
    private spinner: NgxSpinnerService,
    private appHelper: AppHelper,
    private featuresHelper: FeaturesHelper,
    public dialog: MatDialog,
    private elementRef: ElementRef,
    private translate: TranslateService) {

    featuresHelper.deactivateFeatures();

    this.fol = this.appHelper.isFol();

    this.route.queryParams
    .subscribe(params => {
      this.community = params.community;
      if (params.scenario) this.userService.scenario = params.scenario;
      if (params.domain && params.domain !== '/') {
        if (params.domain.startsWith('/')) { 
          this.setVenue(params.domain.substr(1)); 
        } else {
          this.setVenue(params.domain);
        };
      } else {
        this.will_redirect = false;
      }
    });
  }

  ngOnInit() {

    let file_exists = this.appHelper.doesFileExist(`assets/scenarios/${this.whitelabel}/takeaway.png`);
    if (!file_exists) { this.whitelabel_for_scenarios = "guide"; };

    if (this.fol) {
      this.getMerchantsLoopWithObservable().subscribe(
        response => this.initMap()
      );
    }

     //never show waiter scenario in the venue list
    if (this.userService.scenario == 'waiter') this.userService.scenario = 'all';
    if (typeof environment.gps_requested !== 'undefined') this.gps_requested = environment.gps_requested;

    this.userUpdateSubscription = this.userService.userUpdate.subscribe((user) => {
      this.load_page = 1;
      this.load_more = true;
      this.getMerchants(this.load_page).subscribe();
    });

    this.route.paramMap.subscribe((params : ParamMap)=> {  
      this.filter = params.get('filter');  
      if (this.filter && this.filter == 'favorite') {
        this.userService.scenario = 'all';
        this.filter_on_favourite_merchants = true;
        this.setValuesOfFilters();
        this.setFiltersArray();
        this.setTagsArray();
        this.setFilterAndTagsCount();
      }
    });

    this.getMerchants(this.load_page).subscribe();

    if (this.userService.address && this.userService.address.longitude && this.userService.address.latitude) {
      this.mapLatitude = this.userService.address.latitude;
      this.mapLongitude = this.userService.address.longitude;
    } else if (this.userService.geoAddress && this.userService.geoAddress.longitude && this.userService.geoAddress.latitude) {
      this.mapLatitude = this.userService.geoAddress.latitude;
      this.mapLongitude = this.userService.geoAddress.longitude;
    }  

    //verify if we are in a kiosk mode
    if (this.merchantService.kiosk) {
      this.appHelper.setVenue(this.merchantService.kiosk.merchant_slug);
    }

    if (this.userService.user && !this.userService.user.is_anonymous_user) {
      this.user_logged_in = true;
    }
  }
  
  setVenue(domain) {
    if (domain.indexOf("frietjesonline.be") === -1) {

      this.merchantService.getMerchantByDomain(domain).subscribe(
        merchant => {
          if (this.userService.scenario == 'reservation') {
            this.appHelper.setVenue(merchant.domain, null, true);
          } else {
            this.appHelper.setVenue(merchant.domain);
          }
        });

    } else {
      let is_delivery = 0;
      if (this.userService.scenario == 'delivery') is_delivery = 1;
      const frietjesonline = domain + "&dlvr=" + is_delivery;
      window.location.href = frietjesonline;
    }

  }

  searchAhead = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(1000),
    distinctUntilChanged(),
    map(term => {
      this.selectedMerchant = null;
      this.load_page = 1;
      this.load_more = true;
      this.getMerchants(this.load_page,this.searchText).subscribe();
      if (this.switch == 'map') {  this.getMerchantsLoop(); }

    })
    );

  search() {
    this.selectedMerchant = null;
    this.load_page = 1;
    this.load_more = true;
    this.getMerchants(this.load_page,this.searchText).subscribe();
    if (this.switch == 'map') {  this.getMerchantsLoop(); }
  }

  ngAfterViewInit() {

    this.observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting && this.load_page > 1 && this.load_more) {
        this.getMerchants(this.load_page,this.searchText).subscribe();
      }
    }, {} );

    if (!this.fol) this.observer.observe(this.anchor.nativeElement);

  }

  initMap() {

    if (this.userService.address && this.userService.address.longitude && this.userService.address.latitude) {
      this.mapLatitude = this.userService.address.latitude;
      this.mapLongitude = this.userService.address.longitude;
    } else if (this.userService.geoAddress && this.userService.geoAddress.longitude && this.userService.geoAddress.latitude) {
      this.mapLatitude = this.userService.geoAddress.latitude;
      this.mapLongitude = this.userService.geoAddress.longitude;
    } else if (!this.mapLatitude && this.merchants.length > 0) {
      for (let merchant of this.merchants) {
        if (merchant.address && merchant.address.latitude && merchant.address.longitude) {
          this.mapLatitude  = merchant.address.latitude;
          this.mapLongitude = merchant.address.longitude;
          break;
        }
      }
    }   

    this.map = new google.maps.Map(document.getElementById('map') as HTMLElement, {
      center: { lat: this.mapLatitude, lng: this.mapLongitude },
      zoom: 10,
      mapId: '7290b8f072c3121a'
    });

    this.createMarkerAndInfoWindowForEachMerchant(this.map);
  }

  showMerchantOnMap(merchant) {
    this.map.panTo({ lat: merchant.address.latitude, lng: merchant.address.longitude });
    this.map.setZoom(15);
    let marker = this.folMarkers.find(m => m.merchant.id == merchant.id)
    // commented out because it did not open the info window and showed an error because then it can not add the eventListener
    // this.createAndOpenInfoWindow(marker.marker, this.map, merchant);
  }

  ngOnDestroy() {
    this.userUpdateSubscription.unsubscribe();
    if (this.observer) this.observer.disconnect();
  }

  getMerchants(page: number, search?: string) {
    const updateCheckout = new Observable<any>(observer => {

      if (!this.load_more) return;
      if (page == 1) this.spinner.show();
      this.merchantService.getMerchants(page, this.community, search, this.tags, this.filters)
      .subscribe(
        response => {

          if (page == 1) this.merchants = [];

          let pagination =response.pagination;
          let page_max = pagination.per_page * pagination.current_page;
          this.total_entries = pagination.total_entries;

          for (const merchant of response.merchants) {

            if (merchant.venue_image && environment.venueImage == 'venue') {
              const url = merchant.venue_image.medium_url;
              merchant.logo = url.replace(/'/g, '%27');
            } else if (merchant.image) {
              const url = merchant.image.medium_url;
              merchant.logo = url.replace(/'/g, '%27');
            }

            this.merchantService.calculateActualState(merchant);
            if (this.userService.geoAddress && this.userService.geoAddress.latitude) { this.merchantService.calculateDistance(merchant); }

            if (!this.filter || merchant.favorite) {
              this.merchants.push(merchant);
            }
          }


          if (page_max < this.total_entries) { 
            this.load_page += 1;
            this.load_more = true; 
          } else {
            this.load_more = false;
          }

          observer.next(response);

        },
        error => {
          console.log(error);
          observer.error(error);
        },
        () => {
          this.spinner.hide();
        }


        );

    });

    return updateCheckout;
  }

  resetFilterOnFavourites() {
    let data = {
      filter_on_loyalty_points: this.filter_on_loyalty_points,
      filter_on_order_and_reward: this.filter_on_order_and_reward,
      filter_on_open_today: this.filter_on_open_today,
      filter_on_favourite_merchants: false,
      selected_kitchen_type: this.selected_kitchen_type,
      selected_dish_type: this.selected_dish_type
    }
    this.setFilters(data);
  }

  openVenueFiltersDialog() {

    this.selectedMerchant = null;

    const dialogRef = this.dialog.open(VenueFiltersComponent, {
      data: { 
        filter_on_loyalty_points: this.filter_on_loyalty_points,
        filter_on_order_and_reward: this.filter_on_order_and_reward,
        filter_on_open_today: this.filter_on_open_today,
        filter_on_favourite_merchants: this.filter_on_favourite_merchants,
        selected_kitchen_type: this.selected_kitchen_type,
        selected_dish_type: this.selected_dish_type,
        user_logged_in: this.user_logged_in
      },
      autoFocus: false,
      width: '100vw',
      maxWidth: '100vw',
      height: '100vh',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.setFilters(result);
      }
    });
  }

  setFilters(data) {
    this.filter_on_loyalty_points = data.filter_on_loyalty_points;
    this.filter_on_order_and_reward = data.filter_on_order_and_reward;
    this.filter_on_open_today = data.filter_on_open_today;
    this.filter_on_favourite_merchants = data.filter_on_favourite_merchants;
    this.selected_kitchen_type = data.selected_kitchen_type;
    this.selected_dish_type = data.selected_dish_type;
    this.filter = '';
    this.setValuesOfFilters();
    this.setFiltersArray();
    this.setTagsArray();
    this.setFilterAndTagsCount();
    this.refreshView();
  }

  setValuesOfFilters() {
    this.values_of_filters = { 
      "filter_on_loyalty_points": this.filter_on_loyalty_points,
      "filter_on_order_and_reward": this.filter_on_order_and_reward,
      "filter_on_open_today": this.filter_on_open_today,
      "filter_on_favourite_merchants": this.filter_on_favourite_merchants,
      "selected_kitchen_type": this.selected_kitchen_type,
      "selected_dish_type": this.selected_dish_type
    };
  }

  private setTagsArray() {
    this.tags = [];
    if (this.selected_kitchen_type) this.tags.push(this.selected_kitchen_type);
    if (this.selected_dish_type) this.tags.push(this.selected_dish_type);
  }

  private setFiltersArray() {
    this.filters = [];
    if (this.filter_on_loyalty_points) this.filters.push('loyalty_points');
    if (this.filter_on_order_and_reward) this.filters.push('order_and_reward');
    if (this.filter_on_open_today) this.filters.push('open_today');
    if (this.filter_on_favourite_merchants) this.filters.push('favourite_merchants');
  }

  private setFilterAndTagsCount() {
    this.filter_count = this.filters.length + this.tags.length;
    if (this.filter_count == 0) { this.filter_count = null; } 
  } 

  refreshView() {
    this.merchants = [];
    this.selectedMerchant = null;
    this.load_page = 1;
    this.load_more = true;
    this.getMerchants(this.load_page,this.searchText).subscribe();
    this.observer.observe(this.anchor.nativeElement);
  }

  toggleFav(merchant, event) {
    this.appHelper.toggleFav(merchant, event);
  }

  selectScenario(scenario) {
    this.userService.scenario = scenario;
    this.load_page = 1;
    this.load_more = true;
    this.getMerchants(this.load_page).subscribe();
    if (this.switch == 'map') {  this.getMerchantsLoop(); }
  }

  openScenarioSheet(): void {


    console.log("ENVIORNMENT => " + this.whitelabel);

    this.selectedMerchant = null;

    const scenarios = [];
    if (this.whitelabel != 'frietjesonline') { scenarios.push({ value: 'all' }); };
    for (let scenario of environment.scenarios) {
      scenarios.push({ value: scenario });
    }

    const sheetRef = this.bottomSheet.open(ScenarioSheetComponent, { data: { scenarios: scenarios, compareWith: this.userService.scenario }, });
    const scenarioSubscription = sheetRef.instance.scenarioSelected.subscribe((scenario) => {
      this.selectScenario(scenario.value);
      sheetRef.dismiss();
    });
    sheetRef.afterDismissed().subscribe(() => {
      scenarioSubscription.unsubscribe();
    });

  }
  
  getMerchantsLoopWithObservable() { 
    let merchants = new Observable (observer => {
      this.getMerchants(this.load_page,this.searchText).subscribe(
        response => {
          let per_page = response.pagination.per_page;
          let total_entries = response.pagination.total_entries;

          for (let i = 2 ; i <= Math.ceil(total_entries / per_page); i++) {
            this.getMerchants(i,this.searchText).subscribe(
              response => {
                if (i = Math.ceil(total_entries / per_page)) {
                  observer.next(response);
                }
              }, error => {
                console.log(error);
              }
              );
          }
        }, error => {
          console.log(error);
        });
    });

    return merchants;
  }

  getMerchantsLoop() {

    this.getMerchants(this.load_page,this.searchText).subscribe(
      response => {

        let per_page = response.pagination.per_page;
        this.total_entries = response.pagination.total_entries;

        for (let i = 2 ; i <= Math.ceil(this.total_entries / per_page); i++) {
          this.getMerchants(i,this.searchText).subscribe();
        }


      }, error => {
        console.log(error);
      });

  }

  toggle() {

    this.selectedMerchant = null;

    if (this.switch == 'list') { 

        //we are going to switch to map
        //load all remaining pages
      this.getMerchantsLoop();

      if (!this.mapLatitude && this.userService.address && this.userService.address.longitude && this.userService.address.latitude) {
        this.mapLatitude = this.userService.address.latitude;
        this.mapLongitude = this.userService.address.longitude;
      } else if (!this.mapLatitude && this.userService.geoAddress && this.userService.geoAddress.longitude && this.userService.geoAddress.latitude) {
        this.mapLatitude = this.userService.geoAddress.latitude;
        this.mapLongitude = this.userService.geoAddress.longitude;
      } else if (!this.mapLatitude && this.merchants.length > 0) {
        for (let merchant of this.merchants) {
          if (merchant.address && merchant.address.latitude && merchant.address.longitude) {
            this.mapLatitude  = merchant.address.latitude;
            this.mapLongitude = merchant.address.longitude;
            break;
          }
        }
      }    
      this.switch = 'map'; 
      this.observer.disconnect();
    } else { 
      this.merchants = [];
      this.switch = 'list'; 
      this.selectedMerchant = null;
      this.load_page = 1;
      this.load_more = true;
      this.getMerchants(this.load_page,this.searchText).subscribe();
      this.observer.observe(this.anchor.nativeElement);
    }
  }

  markerClicked(merchant_domain) {
    for (const merchant of this.merchants) {
      if (merchant_domain === merchant.domain) { this.selectedMerchant = merchant; }
    }

    console.log('merchant marked: ' + this.selectedMerchant.domain)

  }

  toggleSearch() {

    this.selectedMerchant = null;

    this.searchActive = !this.searchActive;
    if (this.searchActive) { this.merchantSearchInput.nativeElement.focus(); } else { this.merchantSearchInput.nativeElement.blur(); }
  }

  toggleMap() {
    this.googleMap.nativeElement.classList.toggle("d-none");
    this.googleMap.nativeElement.classList.toggle("d-block");


    this.mapButton.nativeElement.innerText = (this.googleMap.nativeElement.classList.contains("d-none") ?  "Bekijk hier de kaart" : "Verberg de kaart")
  }

  private createMarkerAndInfoWindowForEachMerchant(map) {
    for (let merchant of this.merchants) {
      let marker = this.createMarker(merchant, map);

      marker.addListener("click", () => {
        this.createAndOpenInfoWindow(marker, map, merchant);
      });
    }
  }

  private createMarker(merchant, map) {
    let glyphImg = document.createElement('img');
    glyphImg.src = '../../assets/frietjesonline/fol_svg.svg';
    glyphImg.style.width = '25px';
    glyphImg.style.height = '25px';

    let glyphSvgPinView = new google.maps.marker.PinView({
      glyph: glyphImg,
      scale: 1.3
    });

    let marker = new google.maps.marker.AdvancedMarkerView({
      map,
      position: { lat: merchant.address.latitude, lng: merchant.address.longitude },
      content: glyphSvgPinView.element,
    });

    this.folMarkers.push({merchant: merchant, marker: marker})

    return marker
  }
  
  private createAndOpenInfoWindow(marker, map, merchant) {
    let content = this.createContentInfoWindow(merchant);

    const infowindow = new google.maps.InfoWindow({
      content: content,
      maxWidth: 300 //px
    });    

    infowindow.open({
      anchor: marker,
      map,
    });

    setTimeout(() => {
      this.elementRef.nativeElement.querySelector(`#infoWindowOrderButton${merchant.id}`).addEventListener('click', () => {
        this.setVenue(merchant.domain);
      });
    }, 1000);
  }

  private createContentInfoWindow(merchant) {
    let content_tags = this.getContentTags(merchant)
    let content_status = this.getContentStatus(merchant)

    const content = document.createElement("div");
    content.innerHTML = `
    <div class="list-group-item" style="font-family:\'Dosis\';border:none;font-size:16px;">
    <div class="list-group-item-header d-flex align-items-baseline">
    <h3 style="font-size:20px;font-family:\'Permanent Marker\'"> ${merchant.name}
    </h3>
    </div>

    <div class="list-group-item-body row" style="margin-bottom:14px;margin-left:0;"> ${content_tags} </div>
    <div class="list-group-item-body">
    <address>
    <span> ${merchant.address.line1} </span>
    <br>
    <span> ${ merchant.address.zipcode }  ${merchant.address.city} </span>
    </address>
    </div>`
    + content_status +
    `<div class="list-group-item-link row mt-3">
    <a mat-flat-button style="background-color:#fdd329;border-radius:50px;padding: 9px 15px;margin-bottom:15px;line-height:1.5;font-family:\'Permanent Marker\';color:#f0513f;text-align:center" id=\'infoWindowOrderButton${merchant.id}\'>
    ${this.translate.instant('fol.place_order') }
    </a>
    </div>
    </div>`

    return content;
  }

  private getContentTags(merchant) {
    let content_tags = ''
    for (let t of merchant.tags) {
      let capitalized_tag = this.capitalizeFirstLetter(t)
      content_tags += '<div style="border-radius:15px;background-color:#e6e7e8;color:#d63f3d;margin-bottom:5px;margin-right:3px;padding:5px 15px;width:fit-content"><span>' + capitalized_tag + '</span></div>'
    }

    return content_tags
  }

  private getContentStatus(merchant) {
    return merchant.status_formatted ?  '<div class="list-group-item-footer mt-2">' +
    '<span class="frituur-status">' +  this.capitalizeFirstLetter(merchant.status_formatted) + '</span>' +
    '</div>' : ''
  }


  private capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}