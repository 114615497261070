import { Injectable, EventEmitter, NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { PubNubAngular } from 'pubnub-angular2';

import { Merchant } from '../models/merchant';
import { MerchantService } from '../services/merchant.service';
import { UserService } from '../services/user.service';

import { environment } from '../../environments/environment';

import { MatSnackBar } from '@angular/material/snack-bar';

declare var device: any;

@Injectable({ providedIn: 'root' })
export class PubnubService {

  ENVIRONMENT: string = environment.ENVIRONMENT;

  public orderPushed = new EventEmitter();
  public kitchenPushed = new EventEmitter();
  public merchantStatsPushed = new EventEmitter();
  public levelOfActivityPushed = new EventEmitter();
  public productPushed = new EventEmitter();

  private registrationId;

  constructor(
    private translate: TranslateService,
    private pubnub: PubNubAngular,
    private merchantService: MerchantService,
    private userService: UserService,
    private snackBar: MatSnackBar,
    private zone: NgZone
  ) {

    this.pubnub.init({
      publish_key: environment.publish_key,
      subscribe_key: environment.subscribe_key,
      ssl: 'true'
    });

    const that = this;
    this.pubnub.addListener({
      status: function(statusEvent) {
        if (
          ['PNNetworkIssuesCategory', 'PNNetworkDownCategory'].includes(
            statusEvent.category
          )
        ) {
        } else if (
          statusEvent.category == 'PNNetworkUpCategory'
        ) {
          //that.pubnubSubscribe();
        }
      }
    });

  }

  pubnubSubscribe() {

  	const channel_stats = this.ENVIRONMENT + '.stats';

    this.pubnub.subscribe({
      channels: [channel_stats],
      withPresence: false,
      triggerEvents: ['message', 'status']
    });

    console.log('Subscribed to channels');
  }

  pubnubSubscribeChannel(channel) {

    try {

      this.pubnub.subscribe({
        channels: [channel],
        withPresence: false,
        triggerEvents: ['message', 'status']
      });

      this.pubnub.getMessage(channel, msg => {

        if (msg.message.type && msg.message.type == 'kitchen') { 

          this.kitchenPushed.emit(msg.message); 

        } else if (msg.message.type && msg.message.type == 'level_of_activity') { 

          this.levelOfActivityPushed.emit(msg.message); 

        } else if (msg.message.type && msg.message.type == 'stats') {

          const stat = msg.message;
          const merchant = this.merchantService.getStoredMerchant(stat.merchant_id);
          merchant.waiting_time = stat.waiting_time;
          merchant.waiting = stat.waiting;
          merchant.daily_order_count = stat.daily_order_count;
          merchant.daily_order_count_week_ago = stat.daily_order_count_week_ago;
          merchant.daily_revenue = stat.daily_revenue;
          merchant.daily_revenue_week_ago = stat.daily_revenue_week_ago;
          this.merchantStatsPushed.emit(merchant);

        } else if (msg.channel.includes('stats')) {

          const stats = msg.message;

          for (const stat of stats) {
            const merchant = new Merchant();
            merchant.id = stats.merchant_id;
            if (this.merchantService.getStoredMerchant(stat.merchant_id)) {
              const merchant = this.merchantService.getStoredMerchant(stat.merchant_id);
              merchant.waiting_time = stat.waiting_time;
              merchant.waiting = stat.waiting;
              merchant.daily_order_count = stat.daily_order_count;
              merchant.daily_order_count_week_ago = stat.daily_order_count_week_ago;
              merchant.daily_revenue = stat.daily_revenue;
              this.merchantStatsPushed.emit(merchant);
            }
          }


        } 

      });

    } catch (error) {
      console.warn("Could not subscribe to channel");
      console.warn(channel);
      console.warn(error);
    }

  }

  unSubscribeChannel(channel) {

    try {
      this.pubnub.unsubscribe(
      {
        channels: [channel]
      });

    } catch (error) {
      console.warn("Could not unsubscribe from channel");
      console.warn(channel);
      console.warn(error);
    }

  }

  unSubscribeAll() {
    this.pubnub.unsubscribeAll();
  }

  publishMessage(publishPayload) {
        this.pubnub.publish(publishPayload, function(status, response) {
            console.log(status, response);
        });
   };

  playAudio() {
    const audio = new Audio();
    audio.src = '../../../assets/audio/alarm.wav';
    audio.load();
    audio.play();
   }
}
