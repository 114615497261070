import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { MerchantService } from '../services/merchant.service';
import { OrderService } from '../services/order.service';
import { ProductService } from '../services/product.service';
import { UserService } from '../services/user.service';

import { OrderLine } from '../models/order_line';
import { OrderOption } from '../models/order_option';
import { OrderPart } from '../models/order_part';
import { OrderPartSet } from '../models/order_part_set';
import { Product } from '../models/product';
import { Taxon } from '../models/taxon';

import { UtilityService } from '../services/utility.service';


import { AppHelper } from '../helpers/app-helper';

import { environment } from '../../environments/environment';

import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { animate, state, style, transition, trigger } from '@angular/animations';

import { faCoin, faMinus, faPlus, faMessagePlus, faXmark } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition('void <=> *', animate(1350)),
    ])
  ]
})
export class ProductComponent implements OnInit, AfterViewInit {

  @Input() product: Product;
  @Input() category: Taxon;
  @Input() mode = 'normal';
  @Output() canceled = new EventEmitter();
  @Output() dataRequested = new EventEmitter();
  @Input() dataNeeded: Boolean = false;

  public orderLine: OrderLine;
  public taxon: Taxon;

  public whitelabel = environment.whitelabel;
  public icon_data: SafeHtml;

  public menu_only = false;
  public loyalty_enabled = false;
  public loyalty_disabled = false;
  public add_disabled = false;
  public remove_disabled = false;
  public in_basket = 0;

  public loyalty_points: any;

  public needs_scrolling_pos_bottom: number;
  public needs_scrolling_pos_right: number;
  public needs_scrolling: boolean = false;

  public loyalty_points_earn: boolean = false;

  public faCoin = faCoin;
  public faPlus = faPlus;
  public faMinus = faMinus;
  public faMessagePlus = faMessagePlus;
  public faXmark = faXmark;

  public displayAmount: any;
  public alterationAmount = 1;

  public unit = "piece";
  public unit_name = "piece";
  public unit_order_option_value: any;
  public unit_option: any;

  public unit_conversion = 1;
  public price_shown = true;

  public template: string = "template_default";

  public comment_editable = false;
  public show_input_comment = false;
  public fol = false;

  @ViewChild('trackScrolling') track_scrolling: ElementRef;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setNeedsScrolling();
  }

  constructor(
    public userService: UserService,
    private orderService: OrderService,
    public merchantService: MerchantService,
    public productService: ProductService,
    private appHelper: AppHelper,
    private util: UtilityService,
    private translate: TranslateService,
    private ref: ChangeDetectorRef,
    private http: HttpClient,
    private sanitizer: DomSanitizer) {
      this.fol = this.appHelper.isFol();
  }

  setNeedsScrolling() {
    let rect = this.track_scrolling.nativeElement.getBoundingClientRect();
    this.needs_scrolling_pos_bottom = rect.bottom - 75;
    this.needs_scrolling_pos_right = rect.right - 50;
    this.ref.detectChanges();
  }

  ngAfterViewInit() {

    this.setNeedsScrolling();

    this.track_scrolling.nativeElement.addEventListener('scroll', e => {
      let rect = e.target.getBoundingClientRect();
      if (e.target.scrollHeight - e.target.scrollTop - 50 > e.target.offsetHeight) {
        this.needs_scrolling = true;
      } else { this.needs_scrolling = false; }
      this.ref.detectChanges();
    });
    
  }

  ngOnInit() {

    if (this.merchantService.pos) { this.template = "template_pos" };
    if (this.merchantService.kiosk) { this.template = "template_pos" };

    this.menu_only = this.merchantService.hasFeature(this.merchantService.merchant.id, 'f_menu_only');
    this.loyalty_enabled = this.merchantService.hasFeature(this.merchantService.merchant.id, 'f_loyalty_points');
    this.comment_editable = this.merchantService.hasFeature(this.merchantService.merchant.id, 'f_comment_on_orderline');

    let amount = 1;
    if (this.mode == "refund") { 
      amount = -1; 
    };

    this.orderLine = new OrderLine(this.product, this.category, amount);

    if (!this.category) this.initCategory();
    this.initProductParts();
    this.initOrderOptions();
    this.orderLineValid();
    this.calculateDisabled();

    //read the svg
    if (!this.merchantService.pos && this.category && this.category.icon) {
      this.http.get(this.category.icon.svg_url, { responseType: 'text' })
        .subscribe(data => {
          data = data.replace("width", "xidth");
          data = data.replace("height", "xeight");
          let start_index = data.indexOf("<svg");
          this.icon_data = this.sanitizer.bypassSecurityTrustHtml(data.substring(start_index));
          this.ref.detectChanges();
        });
    }

    //we need to know how many of these products are currently in the basket already
    if (this.userService.basket && this.userService.basket.order) {
      this.in_basket = this.userService.basket.order.order_lines.filter(order_line => {
        return order_line.product_id == this.product.id;
      }).reduce((sum, current) => sum + current.amount, 0);
    }

    if (this.in_basket > 0) {
      this.orderLine.comment = this.userService.basket.order.order_lines.find(order_line => {
        return order_line.product_id == this.product.id;
      }).comment;
    }

    this.calculateRealStock();

    if (this.merchantService.pos && this.orderLine.order_part_sets.length == 0 && this.orderLine.order_options.length == 0 && this.product.product_parts.every(part => part.is_removable == false)) {
      this.submitOrderLine();
    } else {
      this.getProduct(this.product.id);
      if (this.userService.user && this.merchantService.hasFeature(this.merchantService.merchant.id, "f_loyalty_points")) { this.getLoyaltyPoints(); };
      
      setTimeout(() => {
        if (this.track_scrolling.nativeElement.scrollHeight - this.track_scrolling.nativeElement.scrollTop > this.track_scrolling.nativeElement.offsetHeight) {
          this.needs_scrolling = true;
          this.ref.detectChanges();
        }
      }, 1000);
    }

  }

  submitOrderLine() {

    if (this.orderLine.amount > 0) this.orderLine.plus = this.orderLine.amount;
    if (this.orderLine.amount < 0) this.orderLine.minus = -1*this.orderLine.amount;

    this.addOrderLine();
    if (this.userService.basket && this.userService.basket.order) {
      this.appHelper.formatOrderLines(this.userService.getBasket().order, this.merchantService.merchant);
    }
  }

  getLoyaltyPoints() {

    this.userService.getLoyaltyPoints().subscribe(
      response => {
        if (this.userService.user) {
          this.userService.user.user_loyalty_points = response.user_loyalty_points;
          this.getLoyaltyPointsForMerchant();
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  getLoyaltyPointsForMerchant() {
    let user_loyalty_point = this.userService.user.user_loyalty_points.find(usp => {
      return usp.merchant.id == this.merchantService.merchant.id;
    });

    if (user_loyalty_point) {
      this.loyalty_points = user_loyalty_point.points;
      this.calculateDisabled();
      this.ref.detectChanges();
    }
  }

  calculateRealStock() {
    this.product.real_stock = this.product.in_stock - this.in_basket;
    if (this.product.real_stock != undefined && this.product.minimum_stock != undefined && this.product.real_stock <= this.product.minimum_stock) this.product.is_available = false;
    this.orderLineValid();
  }

  initCategory() {
    this.category = this.merchantService.merchant.categories.find(x => x.id == this.product.product_taxons[0].taxon_id);
  }

  initProductParts() {
    for (const product_part of this.product.product_parts) {
      const findProduct = this.merchantService.merchant.products.find(x => x.id == product_part.child_id);
      if (findProduct) {
        product_part.name = findProduct.name;
        product_part.price = findProduct.price;
        product_part.active = false;
      }
    }
  }

  addOrderLine() {

    const autogroup_products = this.merchantService.merchant.settings["s_autogroup_products"]

    let do_autogroup_products = false;
    if (autogroup_products && autogroup_products === true) do_autogroup_products = true;

    if (this.unit_order_option_value) this.unit_order_option_value.amount = this.displayAmount;

    //WE ARE HERE, SHOULD ONLY BE SET WHEN WE ARE WORKING THROUGH THE OPTIONS!!
    if (this.unit_option) {
      this.orderLine.order_amount = this.displayAmount;
      this.orderLine.order_unit = this.unit;
    }

    //remove disabled order_options
    this.orderLine.order_options = this.orderLine.order_options.filter(order_option => order_option.enabled);

    if (this.dataNeeded) {
      this.userService.order_line_pending = this.orderLine;
      this.dataRequested.emit();
    } else if (this.orderLine.valid) {
      if (this.product.is_available) {

        let order_option = this.orderLine.order_options.find(order_option => {
          let found = false;
          for (let oov of order_option.order_option_values) {
            found = oov.unit == this.orderLine.order_unit;
            if (found) break;
          }
          return found;
        });

        let unit_conversion = null;

        if (order_option) {
          let order_option_value = order_option.order_option_values.find(oov => oov.unit == this.orderLine.order_unit);
          let option = this.productService.getStoredOption(order_option.option_id);
          let option_value = option.option_values.find(option_value => option_value.unit == this.orderLine.order_unit);
          unit_conversion = 1.00 / option_value.unit_proportion;
        }

        this.userService.addOrderLine(this.orderLine, unit_conversion, true, do_autogroup_products);
        //this.updateOrder();

      } else {
        let error = 'error.unknown';
        if (!this.product.is_available) { error = 'error.product_unavailable'; }
        this.util.openSnackBar(error, this.translate.instant('ok'));
      }
      this.closeProduct();
    } else {

      let scrolled = false;
      for (const order_option of this.orderLine.order_options) {
        if (!order_option.valid) {
          document.getElementById('option_' + order_option.option_id).scrollIntoView();
          scrolled = true;
        }
      }

      for (const order_part_set of this.orderLine.order_part_sets) {
        if (!order_part_set.valid && !scrolled) {
          document.getElementById('part_set_' + order_part_set.part_set_id).scrollIntoView();
          scrolled = true;
        }
      }


    }

  }

  getProduct(product_id) {
    this.productService.getProduct(product_id).subscribe(
      response => {
        const product_update = response.products[0];
        this.product.is_available = product_update.is_available;
        this.product.in_stock = product_update.in_stock;
        this.calculateRealStock();

        //let's have a look if it's eligibel for loyalty_points
        if (this.userService.user && !this.userService.user.is_anonymous_user && this.product.loyalty_points_value > 0) {
          this.loyalty_points_earn = true;
        }

        this.ref.detectChanges();
      },
      error => {
        console.log(error);
      });
  }

  /*updateOrder() {

    this.userService.alterUpdateOngoing(true);
    
    this.orderService.updateOrder(this.userService.getBasket().order, false)
    .subscribe(
      (res: any) => {
        this.appHelper.setBasketOrder(res, this.merchantService.merchant);
        this.appHelper.updateCheckoutFields();


        for (let order_line of res.order_lines) {

              let product_id = order_line.product_id;

              //we need to know how many of these products are currently in the basket already
              let in_basket = res.order_lines.filter(order_line => { 
                return order_line.product_id == product_id;
              }).reduce((sum,current) => sum + current.amount, 0);

              for (let category of this.merchantService.merchant.menu) {
                this.appHelper.updateStockInfoProduct(category.products, product_id, order_line, in_basket);
                for (let subcategory of category.subcategories) {
                  this.appHelper.updateStockInfoProduct(subcategory.products, product_id, order_line, in_basket);
                  for (let level3 of subcategory.subcategories) {
                    this.appHelper.updateStockInfoProduct(level3.products, product_id, order_line, in_basket);
                  } 
               }
              }

            }

      },
      err => {
        console.log(err);
      },
      () => {
        this.userService.alterUpdateOngoing(false);
      }
      );

  }*/

  initOrderOptions() {

    this.orderLine.order_options = [];

    this.product.option_taxons = this.product.option_taxons.sort(function (a, b) { return b.option_id - a.option_id; });
    for (const option_taxon of this.product.option_taxons) {
      this.orderLine.order_options.push(new OrderOption(option_taxon.id, option_taxon.option_id));
    }
    //this.product.part_set_container_taxons = this.product.part_set_container_taxons.sort(function (a, b) { return b.part_set_id - a.part_set_id; })
    for (const part_set_container_taxon of this.product.part_set_container_taxons) {

      let part_set_category = this.merchantService.merchant.categories.find(x => x.id == part_set_container_taxon.taxon_id);

      //can we add condition that we will only add if it is a technical taxon, or the product is in the current taxon
      if ((this.merchantService.merchant.id !== 970) || (part_set_category.depth === 0 || part_set_container_taxon.taxon_id == this.category.id)) {
        this.orderLine.order_part_sets.push(new OrderPartSet(part_set_container_taxon.id, part_set_container_taxon.part_set_id));
      }

      if (this.product.is_bundle) {
        let part_set = this.productService.getStoredPartSet(part_set_container_taxon.part_set_id);
        for (let product of part_set.products) {
          product.option_taxons = product.option_taxons.sort(function (a, b) { return b.option_id - a.option_id; });
          for (const option_taxon of product.option_taxons) {
            this.orderLine.order_options.push(new OrderOption(option_taxon.id, option_taxon.option_id, false, part_set.id, product.id));
          }
        }
      }

    }

    for (const order_option of this.orderLine.order_options) {
      let option = this.productService.getStoredOption(order_option.option_id);

      if (option.base_unit) {
        for (const option_value of option.option_values) {
          this.unit_option = option;
          this.orderLine.amount = option.base_amount;
        }
        break;
      }
    }
  }

  closeProduct() {
    this.canceled.emit();
  }

  alterAmount(alteration) {

    this.orderLine.amount += alteration;

    this.displayAmount = this.orderLine.amount * this.unit_conversion;
    
    if (this.mode === "normal" && this.orderLine.amount < this.alterationAmount) { this.orderLine.amount = this.alterationAmount; }
    
    this.calculateRealStock();
    this.calculateDisabled();
    this.ref.detectChanges();
  }

  calculateDisabled() {

    this.add_disabled = false;
    this.remove_disabled = false;
    this.loyalty_disabled = false;

    if (this.product.in_stock && this.orderLine.amount >= (this.product.in_stock - this.product.minimum_stock - this.in_basket)) { this.add_disabled = true; }
    if (this.mode === "normal" && this.orderLine.amount <= this.alterationAmount) { this.remove_disabled = true; }
    if (this.mode === "refund" && this.orderLine.amount >= (-1*this.alterationAmount)) { this.add_disabled = true; }

    //how many loyaltyPoints for the order in total?
    let loyalty_points_for_this_order = 0;
    if (this.userService.basket) {
      for (let order_line of this.userService.basket.order.order_lines) {
        if (order_line.is_loyalty) loyalty_points_for_this_order += order_line.loyalty_points;
      }
      //we also add our current order_line
      if (this.orderLine.is_loyalty) loyalty_points_for_this_order += this.orderLine.amount * this.product.loyalty_points;
    }

    if (this.orderLine.is_loyalty == true && this.loyalty_points < (this.orderLine.amount * this.product.loyalty_points) + loyalty_points_for_this_order) { this.add_disabled = true; }
    if (!this.orderLine.is_loyalty && (!this.loyalty_points || this.loyalty_points < (this.orderLine.amount * this.product.loyalty_points) + loyalty_points_for_this_order)) { this.loyalty_disabled = true; }
  }

  toggleProductPartRemove(product_part) {

    if (product_part.is_removable) {

      const index = this.orderLine.order_part_removes.findIndex(x => x.product_id == product_part.child_id);
      if (index != -1) {
        this.orderLine.order_part_removes.splice(index, 1);
        product_part.active = false;
      } else {
        const type = 'remove';
        let removeProductPart = new OrderPart(product_part.child_id, type);
        if (product_part.is_price_affected) { removeProductPart.price_adapt = - product_part.price };
        this.orderLine.order_part_removes.push(removeProductPart);
        product_part.active = true;
      }

    }
  }

  manualInput() {
    this.orderLine.amount = this.displayAmount / this.unit_conversion;
    this.orderLineValid();
  }

  orderLineValid() {

    //which unit is selected?
    for (const order_option of this.orderLine.order_options) {
      for (const order_option_value of order_option.order_option_values) {

        if (order_option_value.unit && this.unit_option) {
          //this is the one
          let option_value = this.unit_option.option_values.find(option_value => option_value.id == order_option_value.option_value_id);
          this.unit_name = order_option_value.name;
          this.unit = order_option_value.unit;
          this.unit_conversion = 1.00 / option_value.unit_proportion;
          this.unit_order_option_value = order_option_value;
          this.price_shown = order_option_value.price_shown;
          break;
        }
      }
    }

    this.displayAmount = this.orderLine.amount * this.unit_conversion;
    this.alterationAmount = this.appHelper.getAlterationAmount(this.unit, this.unit_conversion);
    this.displayAmount = this.appHelper.roundAmount(this.unit, this.displayAmount);
    this.orderLine.amount = this.displayAmount / this.unit_conversion;

    for (let order_part_set of this.orderLine.order_part_sets) {
      //are there order options linked to this part set?
      let linked_order_options = this.orderLine.order_options.filter(order_option => order_option.part_set_id == order_part_set.part_set_id);
      for (let linked_order_option of linked_order_options) {
        if (order_part_set.order_part_adds.length > 0 && order_part_set.order_part_adds[0].product_id == linked_order_option.product_id) {
          linked_order_option.enabled = true;
        } else {
          linked_order_option.enabled = false;
        }
      }
    }

    let valid = true;
    if (!this.product.is_available) { valid = false; }
    if (this.product.minimum_stock != undefined && this.product.real_stock != undefined && this.orderLine.amount > this.product.real_stock) { valid = false; }
    for (const order_option of this.orderLine.order_options) { if (order_option.enabled && !order_option.valid) { valid = false; } }
    for (const order_part_set of this.orderLine.order_part_sets) { if (!order_part_set.valid) { valid = false; } }
    this.orderLine.valid = valid;

    this.calculateDisabled();

    this.ref.detectChanges();
  }

  getProductPrice() {

    let price = 0;
    if (!this.orderLine.is_loyalty) price = +this.product.price_with_modifiers;

    for (const order_part_remove of this.orderLine.order_part_removes) {
      if (order_part_remove.price_adapt != null && order_part_remove.price_adapt != 0) { price += +order_part_remove.price_adapt; }
    }

    for (const order_option of this.orderLine.order_options) {
      let option = this.productService.getStoredOption(order_option.option_id);
      if (option.price_adapt_level == 'forfait' && order_option.order_option_values.length > 0) {
        price += + option.price_adapt_abs;
      } else {
        for (const order_option_value of order_option.order_option_values) {
          if (order_option_value.price_adapt != null) { price += +order_option_value.price_adapt; }
        }
      }
    }

    for (const order_part_set of this.orderLine.order_part_sets) {
      let part_set = this.productService.getStoredPartSet(order_part_set.part_set_id);
      if (part_set.price_adapt_level == 'forfait' && order_part_set.order_part_adds.length > 0) {
        price += + part_set.price_adapt_abs;
      } else {
        for (const order_part_add of order_part_set.order_part_adds) {
          if (order_part_add.price_adapt != null) { price += +order_part_add.price_adapt; }
        }
      }
    }

    return price;
  }

  focusOnInput() {
    this.show_input_comment = true; 
    setTimeout(() => {
      document.getElementById(`comment_input`).focus();
    }, 50);
  }

  hideInput() {
    this.show_input_comment = false;
    this.orderLine.comment = '';
  }

  onEnter() {
    document.getElementById(`comment_input`).blur();
  }
}
